/*
 * Hardcoding for now - will parameterize later
 */
const backendHost = "localhost";
const backendPort = "8080";
const frontendHost = backendHost;
const frontendPort = "3000";

enum SentryDSN {
  Salesforce = "https://1c512394ec3743f58a1da0ae35f52a96@o340143.ingest.us.sentry.io/5436494",
  SelfServiceApp = "https://75f5611114876be9c39052b5ecf6f9a1@o340143.ingest.us.sentry.io/4507388654125056",
  ServiceNow = "https://ec41d0a608ab9228d63743eabfc4d090@o340143.ingest.us.sentry.io/4507392664403968",
  WebApp = "https://5d6bf8dc0d8a4248b8be7be367bc5124@o340143.ingest.us.sentry.io/1877865",
  Zendesk = "https://3011cd6f01404695899ef9a50748c9ae@o340143.ingest.us.sentry.io/5262229"
}

function isLocal(): boolean {
  return window.location.hostname === "localhost";
}

/**
 * Parking this in one place for later parameterization
 */

function getBackendUrl(): string {
  let port = "";
  if (isLocal()) {
    port = `:8080`;
  }
  return `${window.location.protocol}//${window.location.hostname}${port}`;
}

function getBackendApiUrl(): string {
  if (isLocal()) {
    return `http://${backendHost}:${backendPort}/api`;
  } else {
    return `https://${window.location.hostname}/api`;
  }
}

function resolveApi(path: string): string {
  return `${getBackendApiUrl()}/${path.replace(/^\//g, "")}`;
}

function getDomainName(): string {
  if (isLocal()) {
    return window.location.host;
  }
  return window.location.hostname;
}

function getSubdomain(): string {
  if (isLocal()) {
    return "local";
  }
  return window.location.hostname.split(".")[0];
}

function getAccount(): string {
  if (isLocal()) {
    return "aptedge";
  }
  return getSubdomain();
}

function getTagManagerConfig(): Record<string, unknown> {
  if (isLocal()) {
    return {
      gtmId: "GTM-P4N4TSG"
    };
  } else {
    return {
      gtmId: "GTM-KNQS24L"
    };
  }
}

export {
  backendHost,
  backendPort,
  frontendHost,
  frontendPort,
  SentryDSN,
  isLocal,
  getBackendUrl,
  getBackendApiUrl,
  getDomainName,
  getAccount,
  resolveApi,
  getTagManagerConfig
};
