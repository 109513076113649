/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SDKClient } from "@aptedge/lib-support-ui/src/clients/sdkClient";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import { FC, ReactNode } from "react";
import ReactMarkdown, { Components } from "react-markdown";
import RenderLink from "./RenderLink/RenderLink";
import styles from "./styles.module.scss";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const remarkGfm = require("remark-gfm").default;

interface Props {
  answer: string;
  resultsMap: Map<string, ICompositeResult>;
  totalResults: number;
  client?: SDKClient;
  aptEdgeBaseUrl?: string;
  disableLinkRendering?: boolean;
}

const MarkdownAnswer: FC<Props> = ({
  answer,
  resultsMap,
  totalResults,
  client,
  aptEdgeBaseUrl,
  disableLinkRendering
}) => {
  const componentHandlers: Components = {
    a: ({
      href,
      children,
      ...props
    }: {
      href?: string; // Ensure href is optional
      children: ReactNode;
    }) => (
      <RenderLink
        href={href || ""} // Provide a fallback for href
        content={
          children?.toString() || "" // Ensure children are stringified
        }
        resultsMap={resultsMap}
        totalResults={totalResults}
        client={client}
        aptEdgeBaseUrl={aptEdgeBaseUrl}
        {...props}
      />
    ),
    code: ({ children }: { children?: ReactNode }) => (
      <pre className={styles.codePreview}>
        <code>{children}</code>
      </pre>
    )
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={disableLinkRendering ? {} : componentHandlers}
    >
      {answer}
    </ReactMarkdown>
  );
};

export default MarkdownAnswer;
