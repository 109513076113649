import {
  CreateTicketData,
  CreateTicketResponse,
  MapSessionWithTicketResponse,
  SaveSessionResponse,
  SessionData
} from "@aptedge/lib-ui/src/types/sessionReplay";
import { inject, injectable } from "inversify";
import { SDKClient } from "../sdkClient";
import { SDKClientDirect } from "../sdkClientDirect";
import { patch, post, put } from "../utils";
import { Dashcam } from "./dashcam";

@injectable()
class DashcamProd extends Dashcam {
  constructor(@inject(SDKClient) private client: SDKClientDirect) {
    super();
  }

  async saveSession(sessionData: SessionData): Promise<SaveSessionResponse> {
    const url = "/api/dashcam/session";
    return this.client.fetch(url, post(sessionData));
  }

  async createTicket(
    ticketData: CreateTicketData
  ): Promise<CreateTicketResponse> {
    const url = "/api/dashcam/create-ticket";

    return this.client.fetch(url, post(ticketData));
  }

  async mapSessionWithTicket(
    sessionId: string,
    ticketId: string,
    recordingBlobUrl: string
  ): Promise<MapSessionWithTicketResponse> {
    const url = `/api/dashcam/session/${sessionId}`;
    const payload = patch(
      recordingBlobUrl ? { recordingBlobUrl, ticketId } : { ticketId }
    );
    return this.client.fetch(url, payload);
  }
  async uploadSessionUsingSingedUrl(
    signedUrl: string,
    sessionJson: Record<string, string>[]
  ): Promise<Response> {
    const payload = put(sessionJson);
    return this.client.rawFetch(signedUrl, payload);
  }
}

export { DashcamProd };
