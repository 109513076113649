import { TicketKBArticleResult } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { SDKClient } from "./sdkClient";
import { TicketKBArticle } from "./ticketKBArticle";
import { post } from "./utils";

@injectable()
class TicketKBArticleProd implements TicketKBArticle {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async search(ticketId: number | string): Promise<TicketKBArticleResult> {
    const url = `/api/insights/kb-draft`;
    return this.client.fetch(
      url,
      post({
        format: "qna--insight-kb-draft",
        template_params: { TICKET_ID: ticketId }
      })
    );
  }
}

export { TicketKBArticleProd };
