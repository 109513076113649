import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import IOSSwitch from "@aptedge/lib-ui/src/components/IOSSwitch/IOSSwitch";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import classNames from "classnames";
import { ChangeEvent, FC, FormEventHandler, useRef, useState } from "react";
import "rrweb-player/dist/style.css";
import { useHistory } from "react-router-dom";
import { useDashcam } from "../../clients/dashcam/useDashcam";
import { Emb_App_Routes } from "../EmbeddedApp/EmbeddedApp";
import SessionPreview from "./SessionPreview";
import styles from "./styles.module.scss";

const browsers = [
  { name: "Firefox", substr: "Firefox" },
  { name: "Samsung Internet", substr: "SamsungBrowser" },
  { name: "Opera", substr: ["Opera", "OPR"] },
  { name: "Internet Explorer", substr: "Trident" },
  { name: "Microsoft Edge", substr: "Edge" },
  { name: "Chrome", substr: "Chrome" },
  { name: "Safari", substr: "Safari" }
];
export function getBrowserDetails(): string {
  const userAgent = navigator.userAgent;

  for (const browser of browsers) {
    if (Array.isArray(browser.substr)) {
      if (browser.substr.some((sub) => userAgent.indexOf(sub) > -1)) {
        return browser.name;
      }
    } else if (userAgent.indexOf(browser.substr) > -1) {
      return browser.name;
    }
  }

  return "Unknown";
}

export const SessionReplay: FC = () => {
  const {
    saveDashcamSession,
    createTicket,
    mapSessionWithTicket,
    uploadSession
  } = useDashcam();
  const { user, cssConfig } = useAppSelector((state) => state.ssApp);
  const { sessionData } = useAppSelector((state) => state.embeddedApp);
  const [isSessionInfoShared, setIsSessionInfoShared] = useState(true);
  const [isFormSubmiting, setIsFormSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const { flags } = useFlags();

  const router = useHistory();
  const handleSumit: FormEventHandler<HTMLFormElement> = async (
    e
  ): Promise<void> => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formDataObject = Object.fromEntries((formData as any).entries());

    try {
      setIsFormSubmitting(true);
      setIsError(false);
      const {
        id: sessionId,
        upload_signed_url,
        gcs_uri
      } = await saveDashcamSession({
        userIdentity: {
          email: user.email,
          browser: getBrowserDetails()
        }
      });
      let uploadSessionRes: Response | undefined;
      if (isSessionInfoShared)
        uploadSessionRes = await uploadSession(
          upload_signed_url,
          sessionData || []
        );

      const { cooked_ticket_id } = await createTicket({
        subject: formDataObject.subject,
        description: formDataObject.description,
        requesterEmail: formDataObject.requesterEmail,
        ticketFields: { name: user.name, ...user.ticketFields }
      });

      if (sessionId && cooked_ticket_id) {
        const mappedSessionWithTicketRes = await mapSessionWithTicket(
          sessionId,
          cooked_ticket_id.toString(),
          uploadSessionRes?.ok ? gcs_uri : undefined
        );

        if (mappedSessionWithTicketRes) {
          setIsFormSubmitting(false);
          setIsFormSubmitted(true);
          formRef.current?.reset();
          router.push(Emb_App_Routes.HOME);
        }
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      setIsError(true);
      setIsFormSubmitting(false);
      formRef.current?.reset();
    }
  };

  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    e.target.style.height = "auto";

    e.target.style.height = `${Math.min(e.target.scrollHeight, 300)}px`;
  };

  const onSessionInfoSharedChange = (e: ChangeEvent<HTMLInputElement>): void =>
    setIsSessionInfoShared(e.target.checked);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>{cssConfig?.ticket_creation?.link_text}</h1>
      </div>

      <div
        className={classNames(styles.baseNotification, {
          [styles.errorContainer]: isError,
          [styles.successContainer]: !isError && isFormSubmitted
        })}
      >
        <div className={styles.left}>
          <AEIcon name="error" />
          <span>
            {isError
              ? "Could not submit ticket, please try again"
              : "Ticket submitted successfully!"}
          </span>
        </div>
        <AEIcon
          name="close"
          onClick={() => setIsError(false)}
          className={styles.closeBtn}
        />
      </div>

      <form onSubmit={handleSumit} ref={formRef}>
        <div className={styles.form}>
          <div className={styles.field}>
            <label htmlFor="">Email</label>
            <input
              type="email"
              defaultValue={user?.email}
              name="requesterEmail"
            />
          </div>
          <div className={styles.field}>
            <label htmlFor="">Subject</label>
            <input type="text" name="subject" />
          </div>

          <div className={styles.field}>
            <label htmlFor="">Details</label>
            <textarea
              name="description"
              rows={4}
              placeholder="Describe the issue you’re facing"
              onChange={onTextChange}
            />
          </div>

          <div className={styles.switch}>
            <IOSSwitch
              onChange={onSessionInfoSharedChange}
              checked={isSessionInfoShared}
            />
            <p>Share session information</p>
          </div>

          <button type="submit" disabled={isFormSubmiting}>
            {!isFormSubmiting ? "Submit" : "Submitting"}
          </button>
        </div>
      </form>
      <div className={styles.preview}>
        {isSessionInfoShared && flags.f1SessionReplayPreview && (
          <SessionPreview className={styles.sessionReplayPreviewContainer} />
        )}
      </div>
    </div>
  );
};
