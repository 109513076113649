import { UserSupport } from "@aptedge/lib-ui/src/types/entities";
import { injectable, inject } from "inversify";
import { SDKClient } from "./sdkClient";
import { UserIdentifier } from "./userIdentifier";

@injectable()
class UserIdentifierProd implements UserIdentifier {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async identify(): Promise<UserSupport> {
    return this.client.fetch("/api/whoami/");
  }
}

export { UserIdentifierProd };
