import {
  PlatformSettings,
  PlatformTicket,
  PlatformUser,
  ZAFClient,
  ZAFRequestOptions
} from "@aptedge/lib-support-sdk";
import { logger } from "@aptedge/lib-ui/src/utils/logger";
import { injectable } from "inversify";
import { SDKClient } from "./sdkClient";

declare global {
  interface Window {
    ZAFClient?: {
      init: () => ZAFClient;
    };
    APTEDGE_TOKEN?: string;
  }
}

@injectable()
class SDKClientZendesk extends SDKClient {
  private _zaf: ZAFClient | undefined;

  async getClient(): Promise<ZAFClient> {
    if (!this._zaf) {
      if (!window.ZAFClient) {
        return Promise.reject("ZAF client not found.");
      }

      this._zaf = window.ZAFClient.init();
    }

    return Promise.resolve(this._zaf);
  }

  async getCurrentUser(): Promise<PlatformUser> {
    return this.getClient().then((client) =>
      client.get("currentUser").then((data) => data.currentUser)
    );
  }

  async getCurrentTicket(): Promise<PlatformTicket> {
    return this.getClient().then((client) =>
      client.get("ticket").then((data) => data.ticket)
    );
  }

  async getAppSettings(): Promise<PlatformSettings> {
    return this.getClient().then((client) =>
      client.metadata().then((data) => ({
        domain: data.settings.aptedge_base_url,
        appName: data.settings.title
      }))
    );
  }

  async navigateTo(...params: Array<string | number>): Promise<void> {
    return this.getClient()
      .then((client) => client.invoke("routeTo", ...params))
      .catch((e) => logger.error("error calling routeTo", e));
  }

  async resize(size: { height: string }): Promise<void> {
    return this.getClient()
      .then((client) => client.invoke("resize", size))
      .catch((e) => logger.error("error calling resize", e));
  }

  async fetch<Data>(apiPath: string, init?: RequestInit): Promise<Data> {
    try {
      const currentUser = await this.getCurrentUser();
      const settings = await this.getAppSettings();
      // This is sent through the Zendesk client with {{}} and interpolated
      // with the correct URL from their app settings.
      const url = `https://{{setting.aptedge_base_url}}/${apiPath.replace(
        /^\//g,
        ""
      )}`;

      const requestType = (init?.method as ZAFRequestOptions["type"]) || "GET";

      const options: ZAFRequestOptions = {
        url,
        type: requestType,
        secure: true,
        headers: {
          [SDKClient.AUTH_HEADER]: "Bearer {{setting.token}}",
          [SDKClient.USER_EMAIL_HEADER]: currentUser.email,
          [SDKClient.CLIENT_APP_TYPE_HEADER]: "zendesk",
          [SDKClient.CLIENT_APP_NAME]: String(settings.appName),
          ...init?.headers
        },
        data: init?.body || undefined
      };

      // TODO - remove these log lines once the apps are more stable.
      logger.log("request - options", options);
      const response = await this.getClient().then((client) =>
        client.request<Data>(options)
      );
      logger.log("request - data", response);

      return response;
    } catch (e) {
      // ZAF might throw a non-Error object (because of iframe limitations).
      // In that case, just stringify the thrown object.
      const message = e instanceof Error ? e.message : JSON.stringify(e);
      logger.error("request - error", apiPath, message);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return e as any;
    }
  }
}

export { SDKClientZendesk };
