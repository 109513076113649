import { TicketFields } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchTicketFields(): Promise<TicketFields[]> {
  const url = `${getBackendApiUrl()}/tickets/ticket-fields`;
  return request(url);
}

export { fetchTicketFields };
