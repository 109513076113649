import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { useModalRouter } from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import { Toggle } from "@aptedge/lib-ui/src/components/Toggle/Toggle";
import { IQueryArgs } from "@aptedge/lib-ui/src/utils/query";
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { ToastType } from "react-toastify";
import { convertQueryStateToClientStatus } from "../../../clients/utils/convertQueryStateToClientStatus";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { CustomerFilter } from "../../../components/Filters/CustomerFilter";
import {
  TEXT_FILTER,
  SUBJECT_FILTER,
  EXTERNAL_TICKET_ID_FILTER,
  DESCRIPTION_FILTER,
  STATUS_FILTER,
  PRIORITY_FILTER,
  ASSIGNEE_FILTER,
  CUSTOMER_FILTER,
  PRODUCT_FILTER,
  FEATURE_FILTER,
  THEME_FILTER,
  JIRA_FILTER,
  EDGE_FILTER
} from "../../../components/Filters/data";
import { EdgeFilter } from "../../../components/Filters/EdgeFilter";
import { FeatureFilter } from "../../../components/Filters/FeatureFilter";
import { Filters } from "../../../components/Filters/Filters";
import { JiraFilter } from "../../../components/Filters/JiraFilter";
import { PriorityFilter } from "../../../components/Filters/PriorityFilter";
import { ProductFilter } from "../../../components/Filters/ProductFIlter";
import {
  TextFilter,
  SubjectTextFilter,
  DescriptionTextFilter,
  ExternalTicketIdFilter
} from "../../../components/Filters/TextFilter";
import { ThemeFilter } from "../../../components/Filters/ThemeFilter";
import { TicketStatusFilter } from "../../../components/Filters/TicketStatusFilter";
import { TicketAssigneeFilter } from "../../../components/Filters/TicketUserFilter";
import { Toast } from "../../../components/Toast/Toast";
import { AutoLinkContext } from "../../../context/AutoLinkContext";
import { ReplyTextArea } from "../ReplyTextArea";
import { Page } from "./AutoLinkRoutes";

import "./AutoLinkPreview.scss";
import "./AutoLinkConditions.scss";
import "./AutoReplyPage.scss";

const FILTERS = [
  TEXT_FILTER,
  SUBJECT_FILTER,
  DESCRIPTION_FILTER,
  EXTERNAL_TICKET_ID_FILTER,
  STATUS_FILTER,
  PRIORITY_FILTER,
  ASSIGNEE_FILTER,
  CUSTOMER_FILTER,
  PRODUCT_FILTER,
  FEATURE_FILTER,
  THEME_FILTER,
  JIRA_FILTER,
  EDGE_FILTER
];

const filtersMap = {
  text: TextFilter,
  subject: SubjectTextFilter,
  description: DescriptionTextFilter,
  externalTicket: ExternalTicketIdFilter,
  products: ProductFilter,
  features: FeatureFilter,
  themes: ThemeFilter,
  customers: CustomerFilter,
  jiras: JiraFilter,
  priority: PriorityFilter,
  status: TicketStatusFilter,
  assignee: TicketAssigneeFilter,
  edges: EdgeFilter
};

interface Props {
  params: IQueryArgs;
  edgeId: number;
  onCancel: () => void;
}

const AutoLinkPreview: React.FC<Props> = ({
  children,
  params,
  onCancel,
  edgeId
}) => {
  const {
    isEditing,
    setIsEditing,
    reply,
    isInternal,
    setIsSaveModalOpen,
    isSaveModalOpen,
    updateQuery,
    createQuery,
    handleSubmit,
    deleteQuery,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    currentSavedQuery,
    isDisabled,
    setIsDisabled
  } = useContext(AutoLinkContext);

  const router = useModalRouter();

  useEffect(() => {
    if (isEditing) {
      setIsEditing(false);
    }
  }, [isEditing, setIsEditing]);

  const renderReplyTitle = isInternal ? "Internal Reply" : "Reply";
  return (
    <div>
      <div className="auto-preview">
        <div className="conditions-wrapper">
          <div className="create-filter">
            <h5>Condition</h5>
            <div
              className="auto-secondary-button text-decoration-none"
              onClick={() => router.push(Page.CONDITIONS)}
            >
              Edit
            </div>
          </div>
          <div className="filter-row">
            <Filters
              spec={FILTERS}
              filters={params.filters}
              filtersMap={filtersMap}
            />
          </div>
        </div>
        {!!reply && (
          <>
            <Icons.Link />
            <div
              className={classNames("auto-reply-wrapper", {
                disabled: isDisabled
              })}
            >
              <div className="auto-reply-text-header">
                <h5 className={classNames({ disabled: isDisabled })}>
                  {renderReplyTitle}
                </h5>
                <div className="auto-reply-toggle-wrapper">
                  <span className="auto-reply-toggle-label">
                    Disable auto reply
                  </span>
                  <Toggle
                    checked={isDisabled}
                    onChange={() => setIsDisabled(!isDisabled)}
                  />
                </div>
              </div>
              <ReplyTextArea
                disabled
                value={reply}
                internal={isInternal}
                isDisabled={isDisabled}
              />
            </div>
          </>
        )}
        <div className="d-flex justify-content-between">
          <Button
            className="mr-3"
            data-testid="cancel-auto"
            color="secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          {!!currentSavedQuery && (
            <div
              className="auto-secondary-button delete-saved"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Delete
            </div>
          )}
          <div>
            <Button
              className="mr-3"
              color="secondary"
              onClick={() => router.push(Page.CONDITIONS)}
            >
              Back
            </Button>
            <Button type="submit" onClick={() => setIsSaveModalOpen(true)}>
              Save
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Save the new Auto Link Conditions?"
        isOpen={isSaveModalOpen}
        loadingTitle="Saving Auto Link conditions..."
        status={
          !!currentSavedQuery
            ? convertQueryStateToClientStatus(updateQuery)
            : convertQueryStateToClientStatus(createQuery)
        }
        onClose={() => setIsSaveModalOpen(false)}
      >
        <div>Are you sure you want to save the new auto link conditions?</div>
        <div className="modal-submit-group">
          <Button
            className="mr-3"
            color="secondary"
            onClick={() => setIsSaveModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => handleSubmit(params, edgeId)}
            data-testid="confirm-save"
          >
            Save
          </Button>
        </div>
      </ConfirmationModal>
      {!!currentSavedQuery && (
        <ConfirmationModal
          title="Delete the Auto Link Conditions?"
          isOpen={isDeleteModalOpen}
          loadingTitle="Deleting Auto Link conditions..."
          status={convertQueryStateToClientStatus(deleteQuery)}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <div>Are you sure you want to delete the auto link conditions?</div>
          <div className="modal-submit-group">
            <Button
              className="mr-3"
              color="secondary"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              color="danger"
              onClick={() => {
                !!deleteQuery && deleteQuery.mutate(currentSavedQuery.id);
                onCancel();
              }}
              data-testid="confirm-delete"
            >
              Delete
            </Button>
          </div>
        </ConfirmationModal>
      )}
      {(createQuery?.isSuccess || updateQuery?.isSuccess) && (
        <Toast type={ToastType.SUCCESS} autoClose={2000}>
          Auto link conditions saved!
        </Toast>
      )}
    </div>
  );
};

export { AutoLinkPreview };
