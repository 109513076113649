import { Checkbox } from "@aptedge/lib-ui/src/components/Checkbox/Checkbox";
import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { TicketFields } from "@aptedge/lib-ui/src/types/entities";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Input, InputAdornment } from "@mui/material";
import { ChangeEventHandler, FC } from "react";
import { Manager, Popper, Reference } from "react-popper";
import { useTicketFields } from "../../../../src/hooks/useTicketFields";
import styles from "./TicketFieldsSettings.module.scss";

const TicketFieldsSettings: FC = () => {
  const {
    isLoading,
    containerRef,
    ticketFieldsRef,
    showConfirmationModal,
    popupOpen,
    ticketFields,
    textInput,
    isMaxTicketFieldSelected,
    selectedTicketFieldsCount,
    handleClear,
    handleInputChange,
    handleTicketFieldSelection,
    togglePopup,
    handleSave,
    handleClose,
    handleUnsavedCancel
  } = useTicketFields();

  return (
    <div className={styles.container} ref={ticketFieldsRef}>
      {isLoading ? (
        <div className="d-flex justify-content-center m-4">
          <Spinner />
        </div>
      ) : (
        <>
          <div className={styles.header}>Custom ticket fields</div>
          <span className={styles.description}>
            Choose up to 25 custom fields from your ticket system to use with
            search and filters.
          </span>
          <div className={styles.actionsContainer}>
            <div ref={containerRef}>
              <Manager>
                <Reference>
                  {({ ref }) => (
                    <div
                      ref={ref}
                      onClick={togglePopup}
                      className={styles.dropdownContainer}
                    >
                      <span
                        className={styles.dropdownPlaceholder}
                        data-testid="ticket-fields-menu-count"
                      >
                        {selectedTicketFieldsCount} / 25 fields selected
                      </span>
                      <AeIcons.ChevronDown />
                    </div>
                  )}
                </Reference>
                {popupOpen && (
                  <TicketFieldsPopup
                    ticketFields={ticketFields}
                    textInput={textInput}
                    handleClear={handleClear}
                    handleInputChange={handleInputChange}
                    handleChangeActiveSourceType={handleTicketFieldSelection}
                    isMaxTicketFieldSelected={isMaxTicketFieldSelected}
                  />
                )}
              </Manager>
            </div>
            <button
              className={styles.saveButton}
              onClick={handleSave}
              disabled={!Boolean(selectedTicketFieldsCount)}
            >
              Save
            </button>
          </div>
          {showConfirmationModal && (
            <>
              <div className={styles.backdrop}></div>
              <div
                className={styles.confirmationContainer}
                data-testid="confirm-modal"
              >
                <span className={styles.confirmationHeader}>Save Changes</span>
                <span className={styles.confirmationBody}>
                  You have unsaved changes that may be lost if you leave.
                  <br />
                  Would you like to save them?
                </span>
                <div className={styles.confirmationActions}>
                  <button className={styles.confirm} onClick={handleClose}>
                    Save
                  </button>
                  <button
                    className={styles.cancel}
                    onClick={handleUnsavedCancel}
                  >
                    Discard
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export { TicketFieldsSettings };

type TicketFieldsPopupProps = {
  ticketFields?: TicketFields[];
  textInput: string;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleClear: () => void;
  handleChangeActiveSourceType: (label: string) => void;
  isMaxTicketFieldSelected: boolean;
};

const TicketFieldsPopup: FC<TicketFieldsPopupProps> = ({
  ticketFields,
  textInput,
  handleInputChange,
  handleClear,
  handleChangeActiveSourceType,
  isMaxTicketFieldSelected
}) => (
  <Popper
    placement="bottom-start"
    modifiers={[
      {
        name: "offset",
        options: {
          offset: [0, 5]
        }
      }
    ]}
  >
    {({ ref, style, placement }) => (
      <div className="popper">
        <div
          className={styles.selectContent}
          ref={ref}
          style={style}
          data-placement={placement}
          data-testid="ticket-fields-menu-list"
        >
          <div className={styles.searchUser}>
            <TicketFieldsInput
              textInput={textInput}
              handleClear={handleClear}
              handleInputChange={handleInputChange}
            />
          </div>
          {isMaxTicketFieldSelected && (
            <div
              className={styles.fieldMessage}
              data-testid="ticket-field-selection-limit-text"
            >
              You can select upto 25 fields only
            </div>
          )}
          {ticketFields?.map(({ externalId, name, isFilterable }) => (
            <div className={styles.dropdownItem} key={externalId}>
              <div className={styles.itemLabel}>
                <AEIcon name="page_info" className={styles.pageInfoIcon} />
                <span
                  className={styles.filterLabel}
                  data-testid="ticket-field-name"
                >
                  {name}
                </span>
              </div>
              <div>
                <Checkbox
                  checked={isFilterable}
                  onChange={() => handleChangeActiveSourceType(externalId)}
                  disabled={isMaxTicketFieldSelected}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
  </Popper>
);

type TicketFieldsInputProps = {
  textInput: string;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleClear: () => void;
};

const TicketFieldsInput: FC<TicketFieldsInputProps> = ({
  textInput,
  handleInputChange,
  handleClear
}) => (
  <Input
    inputProps={{
      className: styles.userInput,
      "data-testid": "ticket-fields-search"
    }}
    placeholder="Search Fields"
    fullWidth={true}
    autoFocus={false}
    disableUnderline={true}
    value={textInput}
    onChange={handleInputChange}
    startAdornment={
      <InputAdornment position="start">
        <SearchOutlinedIcon className={styles.searchIcon} />
      </InputAdornment>
    }
    endAdornment={
      <>
        {textInput && (
          <InputAdornment position="end" onClick={handleClear}>
            <ClearOutlinedIcon
              className={styles.clearIcon}
              data-testid="ticket-fields-search-clear"
            />
          </InputAdornment>
        )}
      </>
    }
  />
);
