import { PlatformSettings } from "../types/platform";

class ServiceNowSDK {
  private settings: PlatformSettings;

  constructor() {
    const params = new URLSearchParams(window.location.search);
    this.settings = { domain: `${params.get("instance")}.aptedge.io` };
  }

  async metadata(): Promise<PlatformSettings> {
    return Promise.resolve(this.settings as PlatformSettings);
  }
}

export { ServiceNowSDK };
