import {
  PlatformSettings,
  PlatformTicket,
  PlatformUser
} from "../types/platform";
import { EventCreator } from "./types";

/**
 * Creates an Event Definition with typed Payload and Reply.
 */
function createEventDefinition<Payload = null, Reply = null>() {
  return <T extends Event>(type: T): EventCreator<T, Payload, Reply> => {
    function eventCreator(payload: Payload): { type: T; payload: Payload } {
      return {
        type,
        payload
      };
    }
    eventCreator.eventName = type;
    eventCreator.createReply = (reply: Reply) => reply;
    eventCreator.toString = () => type; // allow String coercion to deliver the eventName

    return eventCreator;
  };
}

type Event =
  | "platform.ready"
  | "iframe.ready"
  | "iframe.get.ticket"
  | "iframe.get.user"
  | "iframe.invoke.resize"
  | "iframe.invoke.routeTo";

// From platform
const platformReady = createEventDefinition<{ settings: PlatformSettings }>()(
  "platform.ready"
);
type FrameEventReady = typeof platformReady;

// From iframe.
const iframeReady = createEventDefinition<
  Record<string, unknown>,
  { settings: PlatformSettings }
>()("iframe.ready");
type FrameEventIframeReady = typeof iframeReady;

const getTicket = createEventDefinition<
  Record<string, unknown>,
  { ticket: PlatformTicket }
>()("iframe.get.ticket");
type FrameEventGetTicket = typeof getTicket;

const getUser = createEventDefinition<
  Record<string, unknown>,
  { user: PlatformUser }
>()("iframe.get.user");
type FrameEventGetUser = typeof getUser;

const invokeResize = createEventDefinition<
  { height: string },
  { ok: boolean }
>()("iframe.invoke.resize");
type FrameEventResize = typeof invokeResize;

const invokeRouteTo = createEventDefinition<
  { paths: Array<string | number> },
  { ok: boolean }
>()("iframe.invoke.routeTo");
type FrameEventRouteTo = typeof invokeRouteTo;

export {
  iframeReady,
  getUser,
  getTicket,
  platformReady,
  invokeResize,
  invokeRouteTo
};
export type {
  Event,
  FrameEventIframeReady,
  FrameEventGetTicket,
  FrameEventGetUser,
  FrameEventReady,
  FrameEventResize,
  FrameEventRouteTo
};
