import classNames from "classnames";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import AEIcon from "../../components/GMIcon/Icon";
import { useAppSelector } from "../../redux/hook/hook";
import { dataLayerPush, GTM_EVENTS } from "../../utils/event";
import Card from "../EmbeddedSearchV2/components/Card/Card";
import styles from "./styles.module.scss";

type Props = {
  isVisible: boolean;
  updateVisibility: (visibility: boolean) => void;
  title: ReactNode;
};

export const Feedback: FC<Props> = ({ isVisible, updateVisibility, title }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [selectedRating, setSelectedRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const feedbackRef = useRef<HTMLDivElement | null>(null);
  const { user } = useAppSelector((state) => state.ssApp);

  const handleHover = (state: boolean): void => {
    setIsHovered(state);
  };

  const handleSelect = (index: number): void => {
    setSelectedRating(index);
    if (index > 4) handleSubmit();
  };

  const handleSubmit = (): void => {
    setSubmitted(true);
    dataLayerPush({
      event: GTM_EVENTS.GTM_FEEDBACK_RATING,
      data: {
        rating: selectedRating || 5,
        feedback,
        email: user?.email,
        user
      }
    });
    const timer = setTimeout(() => {
      updateVisibility(false);
      clearTimeout(timer);
    }, 3000);
  };

  useEffect(() => {
    if (selectedRating < 5 && selectedRating > 0 && feedbackRef.current) {
      feedbackRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }, [selectedRating]);

  if (!isVisible) return null;
  if (submitted)
    return (
      <Card className={styles.ratingContainer}>
        <div className={styles.finalMessage}>
          <AEIcon name="chat_paste_go" filled className={styles.icon} />
          Thank you!
        </div>
      </Card>
    );
  return (
    <Card className={styles.ratingContainer}>
      <div
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        <div className={styles.rating}>
          <div className={styles.ratingHeader}>{title}</div>
          <div className={styles.ratingScale}>
            {[1, 2, 3, 4, 5].map((number, index) => (
              <button
                key={number}
                className={classNames(styles.ratingButton, {
                  [styles.selected]:
                    selectedRating >= index + 1 || hoveredIndex >= index + 1
                })}
                onClick={() => handleSelect(index + 1)}
                onMouseEnter={() => setHoveredIndex(index + 1)}
                onMouseLeave={() => setHoveredIndex(0)}
              >
                {isHovered || selectedRating > 0 ? (
                  number
                ) : (
                  <div className={styles.dotMask}>
                    <AEIcon name="fiber_manual_record" filled />
                  </div>
                )}
              </button>
            ))}
          </div>
        </div>
        {Number(selectedRating) < 5 && selectedRating > 0 && (
          <div className={styles.feedbackForm} ref={feedbackRef}>
            <textarea
              placeholder="What can we improve?"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className={styles.feedbackInput}
            />
            <button
              className={styles.submitButton}
              onClick={handleSubmit}
              disabled={!feedback.trim()}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </Card>
  );
};
