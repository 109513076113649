import { useArrowDown } from "@aptedge/lib-ui/src/hooks/useArrowDown";
import { useOutsideAlerter } from "@aptedge/lib-ui/src/hooks/useOutsideClick";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateAnswerId,
  updateSearchCardVisibility,
  updateSearchQuery,
  updateShowSuggestion
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { Keys } from "@aptedge/lib-ui/src/styles/keys";
import { QUERY_PARAMS } from "@aptedge/lib-ui/src/types/entities";
import { getTrimmedString } from "@aptedge/lib-ui/src/utils/utils";
import { RefObject, useCallback, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

interface IUseAutoComplete {
  listBoxRef: RefObject<HTMLDivElement>;
  autoCompleteContainerRef: RefObject<HTMLDivElement>;
  highlighted: number;
  handleSearchQueryParamsOnSuggestions: (query: string) => void;
}

const useAutoComplete = (
  handleActiveRefForSearchList: (val: boolean) => void
): IUseAutoComplete => {
  const listBoxRef = useRef<HTMLDivElement>(null);
  const autoCompleteContainerRef = useRef<HTMLDivElement>(null);

  const { history, queryParams } = useQueryParams();

  const dispatch = useAppDispatch();

  const { searchQuery, searchSuggestions: list } = useAppSelector(
    (state) => state.search
  );

  const handleOnKeyDown = (idx: number, key: Keys): void => {
    const newSearchSuggestionList = [searchQuery, ...list];
    if (key === Keys.ENTER && !!newSearchSuggestionList[idx]) {
      handleCompositeSearchApiCall(newSearchSuggestionList[idx]);
    }
  };

  const handleOutsideClick = (): void => {
    dispatch(updateShowSuggestion(false));
  };

  useOutsideAlerter(listBoxRef, handleOutsideClick);
  const { highlighted } = useArrowDown({
    ref: autoCompleteContainerRef,
    listBoxRef,
    items: [searchQuery, ...list],
    onKeyDown: handleOnKeyDown
  });

  const handleCompositeSearchApiCall = (searchQuery: string): void => {
    dispatch(updateSearchCardVisibility(false));
    const trimmedStr = getTrimmedString(searchQuery);
    handleSearchQueryParamsOnSuggestions(trimmedStr);
  };

  const handleSearchQueryParamsOnSuggestions = (query: string): void => {
    queryParams.set(QUERY_PARAMS.QUERY, query);
    const answerId = uuidv4();
    queryParams.set(QUERY_PARAMS.ANSWER_ID, answerId);
    dispatch(updateAnswerId(answerId));
    dispatch(updateSearchQuery(query));
    history.push(`?${queryParams.toString()}`);
  };

  const handleActiveRef = useCallback(
    (val: boolean) => {
      handleActiveRefForSearchList(val);
    },
    [handleActiveRefForSearchList]
  );

  useEffect(() => {
    if (list.length > 0) handleActiveRef(false);
    else handleActiveRef(true);
  }, [list, handleActiveRef]);

  return {
    listBoxRef,
    autoCompleteContainerRef,
    highlighted,
    handleSearchQueryParamsOnSuggestions
  };
};

export default useAutoComplete;
