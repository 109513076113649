import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import React from "react";
import { ToastType } from "react-toastify";
import { Toast } from "../../../components/Toast/Toast";
import {
  useBusinessDataSource,
  useTicketDataSource
} from "../../../hooks/clients/useDataSource";
import { ArticleSourceConfig } from "./ArticleSourceConfig";
import { AuthenticationSourceConfig } from "./AuthenticationSourceConfig";
import { BusinessSourceConfig } from "./BusinessSourceConfig";
import { FederatedSearchSettings } from "./FederatedSearchSettings";
import { IssueSourceConfig } from "./IssueSourceConfig";
import { SocialSourceConfig } from "./SocialSourceConfig";
import { TicketSourceConfig } from "./TicketSourceConfig";

const IntegrationsSettings: React.FC = () => {
  const { flags } = useFlags();

  const businessResource = useBusinessDataSource();
  const ticketResource = useTicketDataSource();
  const error = ticketResource.error || businessResource.error;
  return (
    <>
      <BusinessSourceConfig resource={businessResource} />
      <TicketSourceConfig
        ticketResource={ticketResource}
        businessResource={businessResource}
      />
      <IssueSourceConfig />
      <SocialSourceConfig />
      <ArticleSourceConfig businessResource={businessResource} />
      <AuthenticationSourceConfig />
      {flags.federatedSearch && <FederatedSearchSettings />}
      {error && <Toast type={ToastType.ERROR}>Something went wrong.</Toast>}
    </>
  );
};

export { IntegrationsSettings };
