import TiptapEditor from "@aptedge/lib-ui/src/components/Editor/TiptapEditor";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import {
  ArticleStatus,
  GeneratedKnowledgeResult,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import { TextField, TextareaAutosize } from "@mui/material";
import classNames from "classnames";
import { FC } from "react";
import { useArticleItem } from "../../hooks/useArticleItem";
import { ArticleActions } from "../ArticlesPage/ArticleActions";
import { ArticleDetails } from "../ArticlesPage/ArticleDetails";
import { DiscardSource } from "../ArticlesPage/DiscardModal";
import styles from "./ArticleItemV2.module.scss";

interface ArticleItemProps {
  idx: number;
  article: GeneratedKnowledgeResult;
  articlesLength: number;
}

const ArticleItemV2: FC<ArticleItemProps> = ({
  idx,
  article,
  articlesLength
}) => {
  const {
    title,
    body,
    currentUser,
    currentUserRole,
    isExpanded,
    exportRef,
    handleTitleChange,
    handleBodyChange,
    handleExport,
    handleCopyHtml,
    handleCopyMarkdown
  } = useArticleItem(article);

  return (
    <>
      <p className={styles.articleCount}>{`Article ${
        idx + 1
      } of ${articlesLength}`}</p>
      <div
        className={classNames(styles.container, {
          [styles.containerForAgent]: article.status === ArticleStatus.IN_REVIEW
        })}
        data-testid="article-item-v2"
      >
        <div className={styles.articleSubHeading}>
          <span>
            {article.status !== ArticleStatus.DRAFT
              ? "Review article"
              : "Save article"}
          </span>
          <div
            onClick={handleExport}
            className={styles.exportContainer}
            data-testid="export-article"
          >
            <span>Export</span>
            <Icons.ChevronDown />
          </div>
          {isExpanded && (
            <div className={styles.exportDropdown} ref={exportRef}>
              <span
                onClick={handleCopyHtml}
                className={styles.exportItem}
                data-testid="copy-article-html"
              >
                Copy HTML
              </span>
              <span
                onClick={handleCopyMarkdown}
                className={styles.exportItem}
                data-testid="copy-article-markdown"
              >
                Copy Markdown
              </span>
            </div>
          )}
        </div>
        <div className={styles.articleContainer}>
          <div className={styles.articleCard}>
            <div className={styles.articleContent}>
              {article && title && body && (
                <>
                  <div className={styles.headerContainer}>
                    <div
                      className={styles.titleInputContainer}
                      data-testid="edit-title"
                    >
                      <TextField
                        value={title}
                        onChange={handleTitleChange}
                        fullWidth={true}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          inputComponent: TextareaAutosize,
                          inputProps: {
                            minRows: 1,
                            className: styles.textfield
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={styles.bodyTextareaContainer}
                    data-testid="edit-body"
                  >
                    <TiptapEditor
                      initialContent={body}
                      onChange={handleBodyChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <ArticleDetails
            article={article}
            currentUser={currentUser}
            currentUserRole={currentUserRole}
            discardSource={DiscardSource.ARTICLE_REVIEW_PAGE}
          />
        </div>
        {(article.status === ArticleStatus.DRAFT ||
          currentUserRole !== UserRole.AGENT) && (
          <ArticleActions
            article={article}
            title={title}
            body={body}
            discardSource={DiscardSource.ARTICLE_REVIEW_PAGE}
          />
        )}
      </div>
    </>
  );
};

export { ArticleItemV2 };
