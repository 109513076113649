import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateArticleCreatedUser,
  updateArticleReviewedUser,
  updateFilterArticleStatus,
  updatePage,
  updateResetFilters,
  updateTabArticleStatus
} from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  ArticleStatus,
  IUserListing,
  QUERY_PARAMS,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { FC } from "react";
import styles from "../ArticleSearch/ArticleSearch.module.scss";

const ArticleSearch: FC = () => {
  const { removeQueryParams } = useQueryParams();
  const {
    tabArticleStatus,
    draftArticleCount,
    reviewArticleCount
  } = useAppSelector((state) => state.knowledgeBase);

  const { currentUserRole } = useAppSelector((state) => state.app);

  const dispatch = useAppDispatch();

  const handlePageParam = (): void => {
    removeQueryParams(QUERY_PARAMS.PAGE);
    dispatch(updatePage(1));
  };

  const handleTabChange = (tabState: ArticleStatus): void => {
    if (tabState === ArticleStatus.ALL) {
      dispatch(updateArticleCreatedUser({} as IUserListing));
      dispatch(updateArticleReviewedUser({} as IUserListing));
      dispatch(updateResetFilters(true));
    }
    handlePageParam();
    dispatch(updateFilterArticleStatus([]));
    dispatch(updateTabArticleStatus(tabState));
  };

  return (
    <div className={styles.articleTabsContainer}>
      <span
        className={classNames(styles.articleTab, {
          [styles.articleTabSelected]: tabArticleStatus === ArticleStatus.ALL
        })}
        onClick={() => handleTabChange(ArticleStatus.ALL)}
        data-testid="all-articles-tab"
      >
        All articles
      </span>
      <div
        className={classNames(styles.articleTab, {
          [styles.articleTabSelected]: tabArticleStatus === ArticleStatus.DRAFT
        })}
        onClick={() => handleTabChange(ArticleStatus.DRAFT)}
        data-testid="my-drafts-tab"
      >
        <span>My drafts</span>
        {Boolean(draftArticleCount) && (
          <div
            className={styles.countBubbleContainer}
            data-testid="draft-count-indicator"
          >
            <span className={styles.countBubbleLabel}>{draftArticleCount}</span>
          </div>
        )}
      </div>
      {currentUserRole !== UserRole.AGENT && (
        <div
          className={classNames(styles.articleTab, {
            [styles.articleTabSelected]:
              tabArticleStatus === ArticleStatus.IN_REVIEW
          })}
          onClick={() => handleTabChange(ArticleStatus.IN_REVIEW)}
          data-testid="my-requests-tab"
        >
          <span>My requests</span>
          {Boolean(reviewArticleCount) && (
            <div
              className={styles.countBubbleContainer}
              data-testid="review-count-indicator"
            >
              <span className={styles.countBubbleLabel}>
                {reviewArticleCount}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { ArticleSearch };
