import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ITimeDimensionFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { InfoButton } from "@aptedge/lib-ui/src/components/InfoButton/InfoButton";
import { useModalRouter } from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import {
  IQueryArgs,
  removeEmptyFilters
} from "@aptedge/lib-ui/src/utils/query";
import React, { useContext, useEffect } from "react";
import { CustomerFilter } from "../../../components/Filters/CustomerFilter";
import {
  ASSIGNEE_FILTER,
  CUSTOMER_FILTER,
  DESCRIPTION_FILTER,
  EDGE_FILTER,
  EXTERNAL_TICKET_ID_FILTER,
  FEATURE_FILTER,
  JIRA_FILTER,
  PRIORITY_FILTER,
  PRODUCT_FILTER,
  STATUS_FILTER,
  SUBJECT_FILTER,
  TEXT_FILTER,
  THEME_FILTER
} from "../../../components/Filters/data";
import { EdgeFilter } from "../../../components/Filters/EdgeFilter";
import { FeatureFilter } from "../../../components/Filters/FeatureFilter";
import { Filters } from "../../../components/Filters/Filters";
import { FilterSelect } from "../../../components/Filters/FilterSelect";
import { JiraFilter } from "../../../components/Filters/JiraFilter";
import { PriorityFilter } from "../../../components/Filters/PriorityFilter";
import { ProductFilter } from "../../../components/Filters/ProductFIlter";
import {
  DescriptionTextFilter,
  SubjectTextFilter,
  ExternalTicketIdFilter,
  TextFilter
} from "../../../components/Filters/TextFilter";
import { TextFilterAutoLinkInfo } from "../../../components/Filters/TextFilterAutoLinkInfo";
import { ThemeFilter } from "../../../components/Filters/ThemeFilter";
import { TicketStatusFilter } from "../../../components/Filters/TicketStatusFilter";
import { TicketAssigneeFilter } from "../../../components/Filters/TicketUserFilter";
import { AutoLinkContext } from "../../../context/AutoLinkContext";
import { IPaginatedRequest } from "../../../types/clients";
import "./AutoLinkConditions.scss";
import { Page } from "./AutoLinkRoutes";
import CreatedUpdatedSwitch from "./CreatedUpdatedSwitch/CreatedUpdatedSwitch";

const FILTERS = [
  TEXT_FILTER,
  SUBJECT_FILTER,
  DESCRIPTION_FILTER,
  EXTERNAL_TICKET_ID_FILTER,
  STATUS_FILTER,
  PRIORITY_FILTER,
  ASSIGNEE_FILTER,
  CUSTOMER_FILTER,
  PRODUCT_FILTER,
  FEATURE_FILTER,
  THEME_FILTER,
  JIRA_FILTER,
  EDGE_FILTER
];

const filtersMap = {
  text: TextFilter,
  subject: SubjectTextFilter,
  description: DescriptionTextFilter,
  externalTicket: ExternalTicketIdFilter,
  products: ProductFilter,
  features: FeatureFilter,
  themes: ThemeFilter,
  customers: CustomerFilter,
  jiras: JiraFilter,
  priority: PriorityFilter,
  status: TicketStatusFilter,
  assignee: TicketAssigneeFilter,
  edges: EdgeFilter
};

interface Props {
  params: IQueryArgs;
  onChange: (updates: Partial<IPaginatedRequest<IQueryArgs>>) => void;
  onCancel: () => void;
  selectedTimeDimension?: ITimeDimensionFilter;
}
const AutoLinkConditions: React.FC<Props> = ({
  params,
  onChange,
  onCancel,
  selectedTimeDimension
}) => {
  const router = useModalRouter();

  const { isEditing, setIsEditing } = useContext(AutoLinkContext);

  useEffect(() => {
    if (!isEditing) {
      setIsEditing(true);
    }
  }, [isEditing, setIsEditing]);

  return (
    <div className="autolink-conditions">
      <div className="conditions-wrapper">
        <div className="create-filter">
          <h5>Conditions</h5>
          <InfoButton info={<TextFilterAutoLinkInfo />} />
        </div>
        <div>
          <CreatedUpdatedSwitch
            selectedTimeDimension={selectedTimeDimension}
            filters={params.filters}
          />
        </div>
        <div className="filter-row">
          <Filters
            spec={FILTERS}
            filters={params.filters}
            pinned={[TEXT_FILTER.id]}
            filtersMap={filtersMap}
            onChange={(filters) => onChange({ filters })}
          />
          <FilterSelect specs={FILTERS} query={params} onChange={onChange} />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Button className="mr-3" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!removeEmptyFilters(params).filters?.length}
          onClick={() => router.push(Page.PREVIEW)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export { AutoLinkConditions };
