import { InsightCaseLogResponse } from "@aptedge/lib-ui/src/types/entities";
import { injectable } from "inversify";

@injectable()
abstract class InsightCaseLog {
  abstract search(
    ticketId: string,
    format?: string
  ): Promise<InsightCaseLogResponse>;
}

export { InsightCaseLog };
