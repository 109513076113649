import { GQADocSearchResult } from "@aptedge/lib-ui/src/types/entities";
import { ConfigResponse } from "@aptedge/lib-ui/src/types/selfService";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import {
  makeAnswerAPICallArgs,
  UseAiAnswerQueryReturnType,
  useAiAnswerQueryWithResults
} from "./useAiAnswerQuery";

export const useAiAnswerQueryQuestion = (
  enabled: boolean,
  makeAnswerAPICall: (
    args: makeAnswerAPICallArgs
  ) => Promise<TimedResponse<GQADocSearchResult>>,
  cssConfig?: ConfigResponse,
  isProactiveAnswer = false,
  highlightOffsets = true
): UseAiAnswerQueryReturnType => {
  return useAiAnswerQueryWithResults(
    enabled,
    makeAnswerAPICall,
    "question",
    cssConfig,
    isProactiveAnswer,
    highlightOffsets
  );
};
