import {
  PlatformSettings,
  PlatformTicket,
  PlatformUser
} from "@aptedge/lib-support-sdk";
import { injectable } from "inversify";
import { MOCK_DATA } from "./mocks";
import { SDKClient } from "./sdkClient";

@injectable()
class SDKClientMock extends SDKClient {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private mockFn = (...args: any[]) => Promise.resolve({}),
    private mocks = MOCK_DATA
  ) {
    super();
  }

  async getCurrentUser(): Promise<PlatformUser> {
    return Promise.resolve(this.mocks.currentUser);
  }

  async getCurrentTicket(): Promise<PlatformTicket> {
    return Promise.resolve(this.mocks.ticket);
  }

  async getAppSettings(): Promise<PlatformSettings> {
    return Promise.resolve({ domain: "localhost:3000" });
  }

  async fetch<Response>(url: string, init?: RequestInit): Promise<Response> {
    return this.mockFn(url, init) as Promise<Response>;
  }

  async navigateTo(...params: Array<string | number>): Promise<void> {
    this.mockFn(...params);
  }

  async resize(size: { height: string }): Promise<void> {
    this.mockFn(size);
  }
}

export { SDKClientMock };
