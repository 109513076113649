import { SearchFilters } from "@aptedge/lib-ui/src/services/search/searchFilterService";
import { injectable } from "inversify";

@injectable()
abstract class DynamicFilters {
  abstract fetchFilters(): Promise<{
    groups: SearchFilters[];
  }>;
}

export { DynamicFilters };
