import { ConfigResponse } from "@aptedge/lib-ui/src/types/selfService";
import { inject, injectable } from "inversify";
import { SDKClient } from "../../sdkClient";
import { Config } from "./config";

@injectable()
class ConfigProd implements Config {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async getConfig(): Promise<ConfigResponse> {
    const url = "/api/self-service/config/";

    return this.client.fetch(url);
  }
}

export { ConfigProd };
