import { Emb_App_Routes } from "@aptedge/lib-support-ui/src/features/EmbeddedApp/EmbeddedApp";
import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateSelectedSearchResultsQuestion,
  updateSelectedSearchResultsQuestionText
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Feedback } from "../../Feedback/Feedback";
import Card from "../components/Card/Card";
import styles from "./styles.module.scss";

interface Props {
  questionIndex: number;
  questionText: string;
  showFeedback: boolean;
}
const QuestionFooter: FC<Props> = ({
  questionIndex,
  questionText,
  showFeedback
}) => {
  const { searchResultsByQuestion } = useAppSelector((state) => state.search);
  const dispatch = useAppDispatch();
  const [visibility, setVisibility] = useState(true);

  const router = useHistory();

  return (
    <>
      <Card
        className={styles.relatedArticlesBtn}
        onClick={() => {
          dispatch(updateSelectedSearchResultsQuestion(questionIndex));
          dispatch(updateSelectedSearchResultsQuestionText(questionText));
          router.push(Emb_App_Routes.ARTICLES);
        }}
      >
        <div className={styles.leftSection}>
          <AEIcon
            name="auto_stories"
            color="#2A767E"
            filled
            size={"1.2rem"}
            className={styles.icon}
          />
          <span className={styles.label}>Related articles</span>
        </div>
        <div className={styles.rightSection}>
          <span className={styles.label}>
            {searchResultsByQuestion[questionIndex]?.length}
          </span>
          <AEIcon name="chevron_forward" color="#94A2B7" size={"1.2rem"} />
        </div>
      </Card>
      {showFeedback && (
        <Feedback
          isVisible={visibility}
          updateVisibility={setVisibility}
          title={
            <div className={styles.feedbackTitle}>
              <AEIcon
                name="chat_paste_go"
                color="#2A767E"
                className={styles.chatIcon}
                size={"1.2rem"}
                filled
              />
              <span>Is this answer useful?</span>
            </div>
          }
        />
      )}
    </>
  );
};

export default QuestionFooter;
