import { SDKClient } from "@aptedge/lib-support-ui/src/clients/sdkClient";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateShowMoreButtonClicked } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { FC } from "react";
import MarkdownAnswer from "../MarkdownAnswer";
import "./Body.scss";

export const ANSWER_CONTENT_ID = "answer-content-id";

const thresholdHeight = 155 / 16;

interface BodyProps {
  answer: string;
  resultsMap: Map<string, ICompositeResult>;
  totalResults: number;
  truncateLongAnswer?: boolean;
  client?: SDKClient;
  aptEdgeBaseUrl?: string;
  answerBodyClass?: string;
}
const Body: FC<BodyProps> = ({
  answer,
  resultsMap,
  totalResults,
  truncateLongAnswer,
  client,
  aptEdgeBaseUrl,
  answerBodyClass
}) => {
  const { showMoreButtonClicked } = useAppSelector((state) => state.answerGPT);
  const dispatch = useAppDispatch();

  const handleShowMore = (): void => {
    dispatch(updateShowMoreButtonClicked(true));
  };

  const element = document.getElementById(ANSWER_CONTENT_ID);
  let isOverThreshold = false;
  if (answer && element && element.scrollHeight) {
    const containerHeight = element.scrollHeight / 16;
    isOverThreshold = containerHeight > thresholdHeight;
  }
  const showFullAnswer =
    !isOverThreshold || showMoreButtonClicked || !truncateLongAnswer;

  const containerClassNames = classNames(
    "suggested-answer-body",
    answerBodyClass,
    {
      "answer-content-full": showFullAnswer,
      "answer-content-truncated": !showFullAnswer
    }
  );

  return (
    <div className={containerClassNames} id={ANSWER_CONTENT_ID}>
      <MarkdownAnswer
        resultsMap={resultsMap}
        totalResults={totalResults}
        client={client}
        answer={answer}
        aptEdgeBaseUrl={aptEdgeBaseUrl}
      />
      {!showFullAnswer && (
        <div className="blur-overlay">
          <button onClick={() => handleShowMore()} className="show-more">
            Show more
            <Icons.ChevronDown />
          </button>
        </div>
      )}
    </div>
  );
};

export default Body;
