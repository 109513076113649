import { useOutsideAlerter } from "@aptedge/lib-ui/src/hooks/useOutsideClick";
import {
  useAppSelector,
  useAppDispatch
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  ArticleUser,
  updateArticleCreatedUser,
  updateArticleReviewedUser,
  updateFilterArticleStatus,
  updateResetFilters
} from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  ArticleStatus,
  ArticleStatusBtn,
  IUserListing,
  QUERY_PARAMS,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ARTICLE_STATUS_BUTTON } from "../components/ArticleFilter/ArticleFilter";
import { Routes } from "../routes/routes";

type ArticleFilterProps = {
  isOpen: boolean;
  dropdownRef: RefObject<HTMLDivElement>;
  tabArticleStatus: string;
  statusBtn: ArticleStatusBtn[];
  articleCreatedUser: IUserListing;
  articleReviewedUser: IUserListing;
  shouldReviewAllBtnVisible: boolean;
  isArticleFilterApplied: boolean;
  handleReviewAll: () => void;
  handleFilter: () => void;
  handleClearFilter: () => void;
  handleArticleStatus: (index: number) => void;
  handleClosePopup: () => void;
  handleUserSearchClear: (articleUser: string) => void;
};

function useArticleFilter(): ArticleFilterProps {
  const history = useHistory();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [statusBtn, setStatusBtn] = useState(ARTICLE_STATUS_BUTTON);

  const {
    articleCreatedUser,
    articleReviewedUser,
    tabArticleStatus,
    resetFilters,
    totalArticles,
    filterArticleStatus
  } = useAppSelector((state) => state.knowledgeBase);

  const { currentUserRole } = useAppSelector((state) => state.app);

  const dispatch = useAppDispatch();

  const handleFilter = (): void => {
    setIsOpen(!isOpen);
  };

  const handleFilterArticleStatus = useCallback(
    (statusBtn: ArticleStatusBtn[]): void => {
      const selectedStatusBtn = statusBtn
        .filter((btn) => btn.selected)
        .map((btn) => btn.status);
      dispatch(updateFilterArticleStatus(selectedStatusBtn));
    },
    [dispatch]
  );

  const handleArticleStatus = useCallback(
    (index: number): void => {
      const newStatusBtn = [...statusBtn];
      if (index === -1) {
        newStatusBtn.map((btn) => (btn.selected = false));
      } else {
        newStatusBtn[index].selected = !newStatusBtn[index].selected;
      }
      setStatusBtn(newStatusBtn);
      handleFilterArticleStatus(newStatusBtn);
      handleClosePopup();
    },
    [statusBtn, handleFilterArticleStatus]
  );

  const handleClearFilter = useCallback((): void => {
    handleArticleStatus(-1);
    dispatch(updateArticleCreatedUser({} as IUserListing));
    dispatch(updateArticleReviewedUser({} as IUserListing));
    dispatch(updateFilterArticleStatus([]));
    dispatch(updateResetFilters(false));
  }, [dispatch, handleArticleStatus]);

  const handleClosePopup = (): void => {
    setIsOpen(false);
  };

  const handleReviewAll = (): void => {
    const type =
      tabArticleStatus === ArticleStatus.IN_REVIEW &&
      currentUserRole !== UserRole.AGENT
        ? ArticleStatus.IN_REVIEW
        : ArticleStatus.DRAFT;
    history.push({
      pathname: Routes.ARTICLES_REVIEW,
      search: `${QUERY_PARAMS.ARTICLE_STATUS}=${type}`
    });
  };

  const handleUserSearchClear = (articleUser: string): void => {
    if (articleUser === ArticleUser.CREATE) {
      dispatch(updateArticleCreatedUser({} as IUserListing));
    } else {
      dispatch(updateArticleReviewedUser({} as IUserListing));
    }
  };

  useOutsideAlerter(dropdownRef, handleClosePopup);

  const shouldReviewAllBtnVisible =
    tabArticleStatus !== ArticleStatus.ALL &&
    currentUserRole !== UserRole.AGENT &&
    totalArticles > 0;

  const isArticleFilterApplied = Boolean(
    Object.values(articleCreatedUser).length > 0 ||
      Object.values(articleReviewedUser).length > 0 ||
      filterArticleStatus.length > 0
  );

  useEffect(() => {
    if (resetFilters) {
      handleClearFilter();
    }
  }, [resetFilters, handleClearFilter]);

  return {
    isOpen,
    dropdownRef,
    tabArticleStatus,
    statusBtn,
    articleCreatedUser,
    articleReviewedUser,
    shouldReviewAllBtnVisible,
    isArticleFilterApplied,
    handleReviewAll,
    handleFilter,
    handleClearFilter,
    handleArticleStatus,
    handleClosePopup,
    handleUserSearchClear
  };
}

export { useArticleFilter };
