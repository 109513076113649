import { FilterBar } from "@aptedge/lib-ui/src/components/Filter/FilterBar";
import {
  IQueryFilterDefinition,
  ITimeDimensionFilterDefinition
} from "@aptedge/lib-ui/src/components/Filter/types";
import { SavedQueryType } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import { CustomerFilter } from "../../components/Filters/CustomerFilter";
import { CustomQueryButton } from "../../components/Filters/CustomQueryButton";
import {
  ARCHIVED_FILTER,
  ASSIGNEE_FILTER,
  CREATED_BY_FILTER,
  CREATED_ON_FILTER,
  SPACE_FILTER,
  EDGE_TEAM_FILTER,
  FEATURE_FILTER,
  PRODUCT_FILTER,
  STATUS_FILTER,
  THEME_FILTER,
  PATTERN_FILTER
} from "../../components/Filters/data";
import { DateRangeFilter } from "../../components/Filters/DateRangeFilter";
import { EdgeArchiveFilter } from "../../components/Filters/EdgeArchiveFilter";
import { EdgeStatusFilter } from "../../components/Filters/EdgeStatusFilter";
import { EdgeTeamFilter } from "../../components/Filters/EdgeTeamFilter";
import { FeatureFilter } from "../../components/Filters/FeatureFilter";
import { Filters } from "../../components/Filters/Filters";
import { FilterSelect } from "../../components/Filters/FilterSelect";
import { ProductFilter } from "../../components/Filters/ProductFIlter";
import { SpaceFilter } from "../../components/Filters/SpaceFilter";
import { PatternFilter } from "../../components/Filters/TextFilter";
import { ThemeFilter } from "../../components/Filters/ThemeFilter";
import { TimeDimensions } from "../../components/Filters/TimeDimensions";
import { UpdateSavedFilter } from "../../components/Filters/UpdateSavedFilter";
import {
  AssigneeFilter,
  CreatedByFilter
} from "../../components/Filters/UserFilter";
import { SavedQueryWrapper } from "../../components/SavedQueryWrapper/SavedQueryWrapper";
import {
  SavedQueriesProvider,
  SavedQueriesContext
} from "../../context/SavedQueriesContext";
import { useIsSpaceEnabled } from "../../hooks/useIsSpaceEnabled";
import { EdgeListingQueryParams } from "./useEdgeListingParams";

const FILTERS = [
  PATTERN_FILTER,
  CREATED_ON_FILTER,
  // UPDATED_ON_FILTER,
  STATUS_FILTER,
  ASSIGNEE_FILTER,
  CREATED_BY_FILTER,
  EDGE_TEAM_FILTER,
  ARCHIVED_FILTER,
  PRODUCT_FILTER,
  FEATURE_FILTER,
  THEME_FILTER
];

const QUERY_FILTERS = FILTERS.filter(
  (f): f is IQueryFilterDefinition => !!(f as IQueryFilterDefinition).operators
);
const TIME_FILTERS = FILTERS.filter(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (f): f is ITimeDimensionFilterDefinition => !(f as any).operators
);

const edgeFiltersMap = {
  text: PatternFilter,
  products: ProductFilter,
  features: FeatureFilter,
  themes: ThemeFilter,
  customers: CustomerFilter,
  status: EdgeStatusFilter,
  archived: EdgeArchiveFilter,
  assignee: AssigneeFilter,
  createdBy: CreatedByFilter,
  teams: EdgeTeamFilter
};

const dimensionsMap = {
  createdOn: DateRangeFilter,
  updatedOn: DateRangeFilter
};

interface Props {
  params: EdgeListingQueryParams;
  onChange: (partial: Partial<EdgeListingQueryParams>) => void;
}

function EdgesFilterBar(props: Props): React.ReactElement {
  const { params, onChange } = props;
  const handleFilterChange = (
    value?: Partial<EdgeListingQueryParams>
  ): void => {
    onChange({ ...value, page: 1 });
  };

  const isSpaceEnabled = useIsSpaceEnabled();
  const filters = isSpaceEnabled ? [...FILTERS, SPACE_FILTER] : FILTERS;

  const filtersMap = isSpaceEnabled
    ? Object.assign(edgeFiltersMap, { spaces: SpaceFilter })
    : edgeFiltersMap;

  const queryFilters = isSpaceEnabled
    ? [...QUERY_FILTERS, SPACE_FILTER]
    : QUERY_FILTERS;

  return (
    <SavedQueriesProvider
      type={[SavedQueryType.EDGE_LISTING]}
      onChange={onChange}
    >
      <SavedQueriesContext.Consumer>
        {({ editing }) => (
          <FilterBar className={classNames({ editing })}>
            <UpdateSavedFilter type={SavedQueryType.EDGE_LISTING} />
            <div className="filter-row">
              <SavedQueryWrapper
                onClose={() =>
                  handleFilterChange({
                    filters: [],
                    timeDimensions: []
                  })
                }
              >
                <Filters
                  spec={queryFilters}
                  filters={params.filters}
                  filtersMap={filtersMap}
                  onChange={(filters) => handleFilterChange({ filters })}
                />
                {(params.timeDimensions ?? [])?.length > 0 && (
                  <TimeDimensions
                    specs={TIME_FILTERS}
                    dimensions={params.timeDimensions}
                    dimensionsMap={dimensionsMap}
                    onChange={(timeDimension) =>
                      handleFilterChange({ timeDimensions: [timeDimension] })
                    }
                  />
                )}
              </SavedQueryWrapper>
              <FilterSelect
                specs={filters}
                query={params}
                onChange={handleFilterChange}
              />
              <CustomQueryButton />
            </div>
          </FilterBar>
        )}
      </SavedQueriesContext.Consumer>
    </SavedQueriesProvider>
  );
}

export { EdgesFilterBar };
