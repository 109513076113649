import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import { useHighlightByOffset } from "@aptedge/lib-ui/src/hooks/useHighlightByOffset";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateAnswer,
  updateAnswerAttempt,
  updateAnswerLoadingState,
  updateAnswerLoadingStartTimeMillis,
  updateAnswerCardVisibility,
  updateIsAnswerLoaded,
  updateSuggestedFollowupQuestions,
  AnswerAction,
  AnswerQuestion
} from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { AnswerStage } from "@aptedge/lib-ui/src/redux/reduxSlice/embeddedAppSlice";
import {
  setIsSearchLoading,
  updateSearchCardVisibility,
  updateSearchResultOffsets,
  addSearchResults,
  updateSearchResultsExpanded,
  updateSearchResultsInfo,
  updateTotalSearchResults
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  GQADocSearchResult,
  ICompositeResult,
  QUERY_PARAMS,
  SearchFilterSubType,
  ISearchResultSortingModes,
  ISearchFilter,
  ApiAction,
  ApiAnswer,
  ApiQuestion,
  ApiChatStatus
} from "@aptedge/lib-ui/src/types/entities";
import { ConfigResponse } from "@aptedge/lib-ui/src/types/selfService";
import { dataLayerPush, GTM_EVENTS } from "@aptedge/lib-ui/src/utils/event";
import { generateTopSearchResults } from "@aptedge/lib-ui/src/utils/generator";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import { isEmpty } from "lodash";
import { UseInfiniteQueryResult } from "react-query";
import { useAiAnswerQueryCall } from "./useAiAnswerQueryCall";

export interface UseAiAnswerQueryReturnType {
  gptAnswer: UseInfiniteQueryResult<TimedResponse<GQADocSearchResult>>;
  refetchAnswer: () => void;
}

type makeAnswerAPICallArgs = {
  q?: string;
  page?: number;
  perPage: number;
  limit: number;
  sort?: ISearchResultSortingModes;
  filter?: ISearchFilter;
  filterSubType?: SearchFilterSubType;
  format: string;
  additionalInstructions: string;
  answerId?: string;
  dropExternalId?: string;
  quickFilters?: string;
  productFilters?: string;
  // the backend detects whether this field is set to true and samples all search requests in
  // Sentry
  firstRequest: boolean;
  pageHtml?: string;
  previousPageHtml?: string;
  nextStep?: Record<string, unknown>;
  plan: string | null;
  answerSteps?: Record<string, unknown>[];
  previews?: boolean;
  answerMode: string;
};

function triggerSearchEvent(
  response: TimedResponse<GQADocSearchResult>,
  searchQuery: string,
  activeSourceType: SearchFilterSubType,
  page: number,
  timers: Record<string, unknown>,
  autoSearchActive: boolean,
  cssConfig?: ConfigResponse,
  isProactiveAnswer = false,
  user?: { email: string; name: string }
): void {
  const { data, startTimeMillis, endTimeMillis } = response;
  const { items, total } = data;
  const event = autoSearchActive
    ? GTM_EVENTS.GTM_SUPPORT_AUTO_SEARCH
    : GTM_EVENTS.GTM_SEARCH;

  const loadingTimeSeconds = (endTimeMillis - startTimeMillis) / 1000;
  dataLayerPush({
    event,
    data: {
      search_query: searchQuery,
      search_context: activeSourceType,
      latency_seconds: loadingTimeSeconds,
      page: page,
      total_search_results: total,
      top_search_results: generateTopSearchResults(items),
      timers,
      is_proactive_answer: isProactiveAnswer,
      is_self_service_search:
        !isEmpty(cssConfig) ||
        (!!cssConfig && "proactive_answers" in cssConfig),

      user,
      email: user?.email
    }
  });
}

const triggerQuestionAskedEvent = (
  searchQuery: string,
  answerQuestions: AnswerQuestion[],
  searchResults: ICompositeResult[],
  totalSearchResults: number,
  startTimeMillis: number | null,
  timers?: Record<string, unknown>,
  user?: { email: string; name: string },
  cssConfig?: ConfigResponse,
  isProactiveAnswer = false
): void => {
  const lastQuestion = answerQuestions[answerQuestions.length - 1];
  const lastAction = lastQuestion.actions[lastQuestion.actions.length - 1];
  if (!lastAction) {
    return;
  }

  const answer = lastAction.answer;
  const sourcesIndex = answer.toLowerCase().indexOf("sources:");
  const loadingTimeSeconds = startTimeMillis
    ? (Date.now() - startTimeMillis.valueOf()) / 1000
    : null;

  dataLayerPush({
    event: GTM_EVENTS.GTM_QUESTION_ASKED,
    data: {
      answer_generated: answer,
      latency_seconds: loadingTimeSeconds,
      question: searchQuery,
      total_search_results: totalSearchResults,
      top_search_results: generateTopSearchResults(searchResults),
      sources:
        sourcesIndex !== -1
          ? answer.slice(sourcesIndex + "sources:".length).trim()
          : [],
      timers,
      user,
      email: user?.email,
      is_proactive_answer: isProactiveAnswer,
      is_self_service_search:
        !isEmpty(cssConfig) || (!!cssConfig && "proactive_answers" in cssConfig)
    }
  });
};

export const loadingIndicator = "⎢";

// WARNING: This function can only be safely invoked once per answer component. (All of our apps
// currently have one answer component, so this is fine.) Otherwise, multiple onSuccess() callbacks
// are registered to the same underlying query, which causes duplicate PostHog events to be
// triggered. The "right" way to do this *was* to register a global onSuccess() callback in
// QueryClient, but doing so doesn't invoke the callback for this query for some reason. Also,
// react-query has deprecated that global callback
// (https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose). So I think the way to fix it is
// to call useAiAnswerQuery() high enough in the component stack that we can simply pass its return
// values anywhere they're needed. That's basically what we have as of this writing, but future
// users should be warned that it is not safe to consider this a singleton.
export const useAiAnswerQueryWithResults = (
  enabled: boolean,
  makeAnswerAPICall: (
    args: makeAnswerAPICallArgs
  ) => Promise<TimedResponse<GQADocSearchResult>>,
  answerMode: string,
  cssConfig?: ConfigResponse,
  isProactiveAnswer = false,
  highlightOffsets = true
): UseAiAnswerQueryReturnType => {
  const dispatch = useAppDispatch();
  const { removeQueryParams } = useQueryParams();
  const { user } = useAppSelector((state) => state.ssApp);
  const {
    aiModel,
    answerLoadingStartTimeMillis,
    answerAttempt
  } = useAppSelector((state) => state.answerGPT);

  const {
    searchQuery,
    searchFilter,
    searchFilterSubType,
    page,
    pageHtml,
    sortMode,
    searchResults,
    totalSearchResults,
    answerId,
    autoSearchActive,
    productFilters,
    quickFilters,
    initialSearchQuery
  } = useAppSelector((state) => state.search);

  const perPage = 30;

  const productFiltersSerialized = productFilters?.length
    ? productFilters.map((pf) => pf.name).join(",")
    : "";
  const quickFiltersSerialized = quickFilters?.length
    ? quickFilters.map((qf) => qf.name).join(",")
    : "";

  const { getHighlightedContentByOffset } = useHighlightByOffset();
  const createCacheKey = (): string[] => {
    // TODO: useMemo() can technically expire its own cache, which would cause a duplicate query
    return [
      CACHE_KEY.COMPOSITE_SEARCH_AI,
      searchQuery,
      searchFilter,
      searchFilterSubType,
      sortMode,
      aiModel,
      answerAttempt.toString(),
      page.toString(),
      answerId,
      answerMode,
      productFiltersSerialized,
      quickFiltersSerialized
    ];
  };

  const createSearchParams = (
    pageParam: number,
    answerRequested: boolean
  ): makeAnswerAPICallArgs => {
    const q = !pageParam ? searchQuery : undefined;

    return {
      q,
      page: page,
      limit: 500,
      filter: searchFilter,
      filterSubType: searchFilterSubType,
      perPage: perPage,
      sort: sortMode,
      format: aiModel,
      plan: null,
      answerId: answerRequested ? answerId : undefined,
      additionalInstructions: "",
      firstRequest: !pageParam && initialSearchQuery,
      previews: true,
      pageHtml:
        !pageParam && answerMode !== AnswerStage.question
          ? pageHtml
          : undefined,
      answerMode: "",
      productFilters: productFiltersSerialized,
      quickFilters: quickFiltersSerialized
    };
  };

  const onSearchStarted = (answerRequested: boolean): void => {
    dispatch(setIsSearchLoading(true));
    dispatch(updateAnswerLoadingState(answerRequested));
    dispatch(updateIsAnswerLoaded(!answerRequested));
    dispatch(updateSuggestedFollowupQuestions([]));
  };

  const onAnswerStarted = (): void => {
    dispatch(updateAnswerLoadingStartTimeMillis(Date.now()));
    dispatch(updateAnswerCardVisibility(true));
    dispatch(updateSearchCardVisibility(false));
    removeQueryParams(QUERY_PARAMS.RESULT_ID);
  };

  const handleSearchResultsWithOffset = (items: ICompositeResult[]): void => {
    const searchResultOffsets = items.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result: any) => result.offset
    );
    dispatch(updateSearchResultOffsets(searchResultOffsets));
    const newItems = items.map((item: ICompositeResult) =>
      highlightOffsets ? getHighlightedContentByOffset(item) : item
    );
    dispatch(updateSearchResultsExpanded(newItems));
    dispatch(addSearchResults(newItems));
  };

  const onSearchResultsReceived = (
    lastPage: TimedResponse<GQADocSearchResult>
  ): void => {
    const {
      data: { items, total, timers }
    } = lastPage;

    dispatch(setIsSearchLoading(false));
    dispatch(updateSearchResultsInfo(lastPage.data));
    handleSearchResultsWithOffset(items);
    dispatch(updateTotalSearchResults(total));
    triggerSearchEvent(
      lastPage,
      searchQuery,
      searchFilterSubType,
      page,
      timers,
      autoSearchActive,
      cssConfig,
      isProactiveAnswer,
      user
    );
  };

  const actionToString = (action: ApiAction): string => {
    const { content, status } = action;
    const done = status === "SUCCESS" || status === "ERROR";
    let displayAnswer = "";
    if (typeof content === "undefined") {
    } else if (typeof content === "string") {
      displayAnswer = content + (done ? "" : loadingIndicator);
    } else {
      if (content.text) {
        displayAnswer += content.text;
        displayAnswer += !done ? loadingIndicator : "";
      } else {
        if (content.summary) {
          displayAnswer += `### Summary\n\n${content.summary}`;
          displayAnswer +=
            !done && !content.details ? loadingIndicator : "\n\n";
        }
        if (content.details) {
          displayAnswer += `### Details\n\n${content.details}`;
          displayAnswer +=
            !done && !content.sources ? loadingIndicator : "\n\n";
        }
        if (content.sources && content.sources.length > 0) {
          displayAnswer += `### Sources\n\n`;
          if (done) {
            for (let i = 0; i < content.sources.length; i++) {
              const source = content.sources[i];
              const pages = source.pages ?? [];
              const pagesStr = pages
                .filter((p: number | string | undefined) => p)
                .map((p: number | string) => p.toString())
                .join(", ");
              const pluralSuffix = pages.length > 1 ? "s" : "";
              const title = pagesStr
                ? `${source.title} (page${pluralSuffix} ${pagesStr})`
                : source.title;
              displayAnswer += `${i + 1}. [${title}](${source.url})\n`;
            }
          } else {
            displayAnswer += "*Loading sources...*\n";
          }
        }
      }
    }
    return displayAnswer;
  };

  const responseToDisplayAnswer = (answer: ApiAnswer): AnswerQuestion[] => {
    const answerQuestions: AnswerQuestion[] = [];
    if (answer) {
      const { questions } = answer;

      questions.forEach((question: ApiQuestion) => {
        if (question.status === ApiChatStatus.ERROR) {
          answerQuestions.push({
            query: "",
            actions: [
              {
                query: "",
                answer: "**<Error.>**",
                newSearches: []
              }
            ]
          });
        } else {
          const { actions } = question;
          const answerActions: AnswerAction[] = [];

          actions.forEach((action: ApiAction) => {
            const answerText = actionToString(action);

            answerActions.push({
              query: action.query,
              answer: answerText,
              newSearches: action.content.new_searches ?? []
            });
          });

          answerQuestions.push({
            query: question.query,
            actions: answerActions
          });
        }
      });
    }

    return answerQuestions;
  };

  const getIsMessageLoading = (answer: ApiAnswer): boolean => {
    let isMessageLoading = true;
    if (answer) {
      const { questions } = answer;

      questions.forEach((question: ApiQuestion) => {
        const { actions } = question;
        isMessageLoading = actions.length === 0;

        for (let i = 0; i < actions.length; i++) {
          const action = actions[i];
          const answerText = actionToString(action);

          const done = action.status === "SUCCESS" || action.status === "ERROR";
          if (!done && i === actions.length - 1) {
            isMessageLoading = isMessageLoading || !answerText;
          }
        }
      });
    }

    return isMessageLoading;
  };

  const onAnswerUpdated = (answer: ApiAnswer): void => {
    const answerQuestions = responseToDisplayAnswer(answer);
    const isMessageLoading = getIsMessageLoading(answer);

    dispatch(updateAnswer(answerQuestions));
    dispatch(updateAnswerLoadingState(isMessageLoading));
  };

  const onAnswerFinished = (answer: ApiAnswer): void => {
    const answerQuestions = responseToDisplayAnswer(answer);

    triggerQuestionAskedEvent(
      searchQuery,
      answerQuestions,
      searchResults,
      totalSearchResults,
      answerLoadingStartTimeMillis,
      answer.timers,
      user,
      cssConfig,
      isProactiveAnswer
    );
    dispatch(updateIsAnswerLoaded(true));

    let followupQuestions: string[] = [];
    if (answer) {
      const { questions } = answer;
      questions.forEach((question: ApiQuestion) => {
        const { actions } = question;
        actions.forEach((action: ApiAction) => {
          followupQuestions = action.content.followups ?? [];
        });
      });
    }

    if (followupQuestions) {
      dispatch(updateSuggestedFollowupQuestions(followupQuestions));
    }
  };

  const refetchAnswer = (): void => {
    dispatch(updateAnswerAttempt(answerAttempt + 1));
  };

  const gptAnswer = useAiAnswerQueryCall(
    enabled,
    answerId,
    true,
    makeAnswerAPICall,
    createCacheKey,
    createSearchParams,
    onSearchStarted,
    onAnswerStarted,
    onSearchResultsReceived,
    onAnswerUpdated,
    onAnswerFinished
  );

  return { gptAnswer, refetchAnswer };
};

export type { makeAnswerAPICallArgs };
