import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import Helmet from "react-helmet";
import { Route } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Routes } from "../../routes/routes";
import { AdminDashboard } from "./AdminDashboard/AdminDashboard";
import { AppsSettings } from "./Apps/AppsSettings";
import CSSConfiguration from "./CSSConfiguration/CSSConfiguration";
import { Filters } from "./Filters/Filters";
import { IntegrationsSettings } from "./Integrations/IntegrationsSettings";
import { MLSettings } from "./MachineLearning/MLSettings";
import { MyProducts } from "./MyProducts/MyProducts";
import { SettingsNav } from "./Nav/SettingsNav";
import { SettingsContent } from "./SettingsContent";
import { WorkflowSettings } from "./Workflow/WorkflowSettings";

const SettingsPage: React.FC = () => {
  const { user } = useAuth();
  const { flags } = useFlags();
  const adminsOnly = user?.admin || false;

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <SettingsContent>
        <SettingsNav />
        <div className="container">
          {/* Routes */}
          {adminsOnly && (
            <Route exact path={Routes.SETTINGS}>
              <IntegrationsSettings />
            </Route>
          )}
          <Route path={Routes.SETTINGS_FILTERS}>
            <Filters />
          </Route>
          <Route path={Routes.SETTINGS_MY_PRODUCTS}>
            <MyProducts />
          </Route>
          <Route exact path={Routes.SETTINGS_MACHINE_LEARNING}>
            <MLSettings />
          </Route>
          <Route exact path={Routes.SETTINGS_WORKFLOW}>
            <WorkflowSettings />
          </Route>
          {adminsOnly && (
            <Route exact path={Routes.SETTINGS_APPS}>
              <AppsSettings />
            </Route>
          )}
          {adminsOnly && (
            <Route exact path={Routes.ADMIN_DASHBOARD}>
              <AdminDashboard />
            </Route>
          )}

          {adminsOnly && flags.cssConfigPage && (
            <Route exact path={Routes.CSS_CONFIG}>
              <CSSConfiguration />
            </Route>
          )}
        </div>
      </SettingsContent>
    </>
  );
};

export { SettingsPage };
