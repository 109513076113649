import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { ArticleUser } from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  ArticleReviewStatus,
  ArticleReviewer,
  ArticleStatus,
  ArticleStatusBtn,
  ArticleStatusLabel,
  GeneratedKnowledgeResult,
  IUser,
  ReviewerApprovalStatus,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import AddIcon from "@mui/icons-material/Add";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import Checkbox from "@mui/material/Checkbox";
import classNames from "classnames";
import truncate from "lodash/truncate";
import { FC } from "react";
import { Link } from "react-router-dom";
import { ToastType } from "react-toastify";
import { ArticleSearchUser } from "../../components/ArticleSearchUser/ArticleSearchUser";
import { Toast } from "../../components/Toast/Toast";
import { useArticleDetails } from "../../hooks/useArticleDetails";
import { UserSearchSource } from "../../hooks/useArticleSearchUser";
import { displayAge } from "../../utils/time";
import styles from "./ArticleDetails.module.scss";
import { DiscardSource } from "./DiscardModal";

type ArticleDetailsProps = {
  article: GeneratedKnowledgeResult;
  currentUser?: IUser;
  currentUserRole: UserRole;
  discardSource?: DiscardSource;
};

export const ARTICLE_STATUS_BUTTON = [
  {
    icon: <AEIcon name="edit_document" size="1.125rem" />,
    label: ArticleStatusLabel.DRAFT,
    selected: false,
    status: ArticleStatus.DRAFT
  },
  {
    icon: <PlagiarismOutlinedIcon className={styles.articleStatusIcon} />,
    label: ArticleStatusLabel.IN_REVIEW,
    selected: false,
    status: ArticleStatus.IN_REVIEW
  },
  {
    icon: <TaskOutlinedIcon className={styles.articleStatusIcon} />,
    label: ArticleStatusLabel.PUBLISHED,
    selected: false,
    status: ArticleStatus.PUBLISHED
  },
  {
    icon: <AEIcon name="scan_delete" size="1.125rem" />,
    label: ArticleStatusLabel.DEPRECATED,
    selected: false,
    status: ArticleStatus.DEPRECATED
  }
];

const ArticleDetails: FC<ArticleDetailsProps> = ({
  article,
  currentUser,
  currentUserRole,
  discardSource
}) => {
  const {
    isOpen,
    isMarkedInternal,
    ticketId,
    externalId,
    containerRef,
    statusBtn,
    selectedArticleStatus,
    articleReviewers,
    selectedReviewer,
    isReviewerExist,
    hasReviewerApproved,
    showReviewersList,
    handleShowArticleStatus,
    handleArticleStatus,
    handleCheckbox,
    handleReviewStatus,
    handleRemoveReviewer,
    handleUserSearchClear,
    handleAddReviewer,
    handleLink,
    onToastMsgClose
  } = useArticleDetails(article, currentUserRole, currentUser);

  return (
    <div className={styles.articleSection} data-testid="article-details">
      <div
        className={styles.articleSectionHeading}
        data-testid="article-publishing"
      >
        <span className={styles.headingTitle}>PUBLISHING</span>
        <span className={styles.publishDivider} />
      </div>
      <div ref={containerRef}>
        <div
          onClick={handleShowArticleStatus}
          className={classNames(styles.statusContent, {
            [styles.draftArticle]:
              selectedArticleStatus?.status === ArticleStatus.DRAFT,
            [styles.publishedArticle]:
              selectedArticleStatus?.status === ArticleStatus.PUBLISHED,
            [styles.inReviewArticle]:
              selectedArticleStatus?.status === ArticleStatus.IN_REVIEW,
            [styles.deprecatedArticle]:
              selectedArticleStatus?.status === ArticleStatus.DEPRECATED,
            [styles.statusContentForAgent]: currentUserRole === UserRole.AGENT
          })}
          data-testid="article-status-info"
        >
          <div className={styles.statusInfo}>
            {selectedArticleStatus?.icon}
            <span>{selectedArticleStatus?.label}</span>
          </div>
          <KeyboardArrowDownIcon className={styles.arrowDown} />
        </div>
        {isOpen && (
          <div
            className={classNames(styles.statusMenu, {
              [styles.statusMenuOnReviewPage]:
                discardSource === DiscardSource.ARTICLE_REVIEW_PAGE
            })}
          >
            <div className={styles.statusMenuInfo}>
              <AEIcon name="swap_horiz" />
              <span>Update status to</span>
            </div>
            {statusBtn.map((button: ArticleStatusBtn) => (
              <div
                className={styles.articleStatusBtn}
                key={button.status}
                onClick={() => handleArticleStatus(button.status)}
              >
                {button.icon}
                <span>{button.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      {currentUserRole !== UserRole.AGENT && (
        <div
          className={styles.infoContent}
          data-testid="article-available-publicly"
        >
          <div className={styles.checkBoxContainer}>
            <Checkbox
              checked={isMarkedInternal}
              onChange={handleCheckbox}
              disableRipple
              sx={{
                "&.MuiCheckbox-root": {
                  padding: 0
                },
                "&.Mui-checked": {
                  color: "#2a767e"
                }
              }}
              data-testid="internal-article"
            />
            <span>Mark as Internal</span>
          </div>
        </div>
      )}
      {(showReviewersList ||
        (articleReviewers[article.id] &&
          articleReviewers[article.id].length > 0)) && (
        <div
          className={classNames(styles.articleSectionHeading, {
            [styles.articleSectionHeadingNotForAgent]:
              currentUserRole !== UserRole.AGENT
          })}
          data-testid="article-reviewers"
        >
          <span className={styles.headingTitle}>REVIEWERS</span>
          <span className={styles.reviewersDivider} />
        </div>
      )}
      <div className={styles.reviewerList}>
        {articleReviewers[article.id] &&
          articleReviewers[article.id].length > 0 &&
          articleReviewers[article.id].map(
            (reviewer: ArticleReviewer, index: number) => {
              return (
                <div
                  key={index}
                  className={classNames(styles.userDetail, {
                    [styles.currentUserDetail]:
                      currentUser?.id === reviewer.reviewer_id &&
                      currentUserRole !== UserRole.AGENT &&
                      article.status !== ArticleStatus.DEPRECATED &&
                      reviewer.status !== ArticleReviewStatus.APPROVED,
                    [styles.approvedArticleUserDetail]:
                      reviewer.status === ArticleReviewStatus.APPROVED &&
                      currentUser?.id === reviewer.reviewer_id
                  })}
                >
                  <div>
                    <span className={styles.userInitial}>
                      {reviewer.email.slice(0, 1).toUpperCase()}
                    </span>
                    <span className={styles.userEmail}>
                      {truncate(reviewer.email, { length: 25 })}
                    </span>
                  </div>
                  <div
                    className={classNames(styles.userAction, {
                      [styles.userActionApprove]:
                        currentUser?.id === reviewer.reviewer_id &&
                        article.status !== ArticleStatus.DEPRECATED &&
                        reviewer.status === ArticleReviewStatus.PENDING &&
                        currentUserRole !== UserRole.AGENT
                    })}
                  >
                    {article.status !== ArticleStatus.DEPRECATED &&
                    currentUserRole !== UserRole.AGENT &&
                    currentUser?.id === reviewer.reviewer_id &&
                    reviewer.status === ArticleReviewStatus.PENDING ? (
                      <div
                        className={styles.approveArticle}
                        onClick={() => handleReviewStatus(index)}
                      >
                        <DoneAllIcon className={styles.doneAllIcon} />
                        <span>Approve</span>
                      </div>
                    ) : (
                      <span
                        className={classNames(styles.reviewStatus, {
                          [styles.approvedReviewStatus]:
                            reviewer.status === ArticleReviewStatus.APPROVED
                        })}
                      >
                        {reviewer.status.slice(0, 1).toUpperCase() +
                          reviewer.status.slice(1)}
                      </span>
                    )}
                    {(article.status === ArticleStatus.DRAFT ||
                      currentUserRole !== UserRole.AGENT) && (
                      <AEIcon
                        name="delete"
                        size="1.25rem"
                        className={classNames(styles.reviewerDelete, {
                          [styles.reviewerDeleteInReview]:
                            currentUser?.id === reviewer.reviewer_id &&
                            article.status !== ArticleStatus.DEPRECATED &&
                            reviewer.status === ArticleReviewStatus.PENDING
                        })}
                        onClick={() => handleRemoveReviewer(index)}
                      />
                    )}
                  </div>
                </div>
              );
            }
          )}
      </div>
      {showReviewersList && (
        <div className={styles.addReviewer}>
          <ArticleSearchUser
            articleUser={ArticleUser.REVIEW}
            userInput={selectedReviewer}
            handleUserSearchClear={handleUserSearchClear}
            placeholder="Reviewer name"
            source={UserSearchSource.ARTICLE_REVIEW}
          />
          <div
            className={classNames(styles.addReviewerBtn, {
              [styles.disableAddBtn]:
                isReviewerExist || !Boolean(selectedReviewer.email)
            })}
            onClick={handleAddReviewer}
            data-testid="add-reviewer"
          >
            <AddIcon className={styles.addIcon} />
            <span className={styles.addText}>Add</span>
          </div>
        </div>
      )}
      <div className={styles.articleSectionHeading}>
        <span className={styles.headingTitle}>DETAILS</span>
        <span className={styles.detailsDivider} />
      </div>
      <div className={styles.articleDetails} data-testid="article-source">
        <span className={styles.detailsTitle}>Sources</span>
        <span className={styles.detailsContainer}>
          <ArrowOutwardIcon
            className={classNames(styles.ticketSourceIcon, {
              [styles.disable]: !Boolean(ticketId || externalId)
            })}
          />
          <span className={styles.ticketSource}>
            <Link
              to={Boolean(ticketId) ? `/tickets/${ticketId}` : ""}
              className={classNames({
                [styles.disableLink]: !Boolean(ticketId)
              })}
              onClick={handleLink}
            >
              {Boolean(externalId) ? `Ticket #${externalId}` : "-"}
            </Link>
          </span>
        </span>
      </div>
      <div className={styles.articleDetails} data-testid="article-created-by">
        <span className={styles.detailsTitle}>Created by</span>
        <span className={styles.detailsContent}>{article?.authorEmail}</span>
      </div>
      <div className={styles.articleDetails} data-testid="article-created">
        <span className={styles.detailsTitle}>Created</span>
        <span className={styles.detailsContent}>
          {displayAge(article?.createdOn, undefined, true)}
        </span>
      </div>
      {article.status !== ArticleStatus.DRAFT && (
        <div className={styles.articleDetails}>
          <span className={styles.detailsTitle}>Latest reviewed</span>
          <span className={styles.detailsContent}>
            {displayAge(article?.updatedOn, undefined, true)}
          </span>
        </div>
      )}
      {hasReviewerApproved !== ReviewerApprovalStatus.NONE && (
        <Toast
          type={
            hasReviewerApproved === ReviewerApprovalStatus.SUCCESS
              ? ToastType.SUCCESS
              : ToastType.ERROR
          }
          autoClose={2000}
          onClose={onToastMsgClose}
        >
          {hasReviewerApproved === ReviewerApprovalStatus.SUCCESS
            ? "Approval saved successfully."
            : "Something went wrong."}
        </Toast>
      )}
    </div>
  );
};

export { ArticleDetails };
