import { ApiError } from "@aptedge/lib-ui/src/utils/ApiError";
import { OK } from "http-status-codes";

async function request<Data>(
  url: RequestInfo,
  options?: RequestInit
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Data | any> {
  const response = await fetch(url, options);
  if (response.status >= OK && response.status < 300) {
    return response.json() as Promise<Data>;
  } else {
    throw new ApiError(response.status, url.toString());
  }
}

export { request };
