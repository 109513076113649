import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { FC } from "react";
import "./Header.scss";
import { ActionButtons } from "../ActionButtons/ActionButtons";

const Header: FC = () => {
  return (
    <div className="header-preview-container">
      <div>
        <AeIcons.AiAnswerPreview className="ai-answer" />
        <span className="title">Answer AI</span>
      </div>
      <ActionButtons />
    </div>
  );
};

export default Header;
