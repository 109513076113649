import { createSlice } from "@reduxjs/toolkit";
import { ConfigResponse } from "../../types/selfService";

type SliceStateType = {
  cssConfig: ConfigResponse | null;
  isSearchInputFocused: boolean;
  user: {
    email: string;
    name: string;
    ticketFields?: Record<string, string>;
  };
};

const initialState: SliceStateType = {
  cssConfig: {} as ConfigResponse,
  user: {
    email: "",
    name: ""
  },
  isSearchInputFocused: false
};

const selfServiceSlice = createSlice({
  name: "selfService",
  initialState,
  reducers: {
    updateCssConfig: (state, action) => {
      state.cssConfig = action.payload;
    },
    setF1User: (state, action) => {
      state.user = action.payload;
    },
    setInputFocusChange: (state, action) => {
      state.isSearchInputFocused = action.payload;
    }
  }
});

export const {
  updateCssConfig,
  setF1User,
  setInputFocusChange
} = selfServiceSlice.actions;

export default selfServiceSlice.reducer;
