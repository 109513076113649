import { Tabs } from "@aptedge/lib-ui/src/components/Tabs/Tabs";
import { useMediaQuery } from "@aptedge/lib-ui/src/hooks/useMediaQuery";
import {
  IEdgeInfo,
  ITicketListing,
  IUserListData
} from "@aptedge/lib-ui/src/types/entities";
import React, { Dispatch, SetStateAction } from "react";
import { useInfiniteQuery, useQueryClient, UseQueryResult } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchEdgeAuditLogs } from "../../clients/Edges/fetchEdgeAuditLogs";
import { PageContent } from "../../components/PageContent/PageContent";
import { IPaginatedRequest, IPaginatedResponse } from "../../types/clients";
import { EdgeAuditWidget } from "./audits/EdgeAuditWidget";
import { EdgeBadges } from "./badges/EdgeBadges";
import { EdgeAssignee } from "./EdgeAssignee";
import { EdgeBreadcrumbs } from "./EdgeBreadcrumbs";
import { EdgeButtons } from "./EdgeButtons";
import { EdgeCommentWidget } from "./EdgeCommentWidget";
import "./EdgeContent.scss";
import { EdgeIssuesWidget } from "./EdgeIssuesWidget";
import { EdgeLabels } from "./EdgeLabels";
import { EdgeSpace } from "./EdgeSpace";
import { EdgeStatus } from "./EdgeStatus";
import { EdgeSummaryWidget } from "./EdgeSummaryWidget";
import { EdgeTeam } from "./EdgeTeam";
import { EdgeTimestamps } from "./EdgeTimestamps";
import { EdgeTitle } from "./EdgeTitle";
import { EdgeResourcesWidget } from "./resources/EdgeResourcesWidget";
import { EdgeTicketsWidget } from "./tickets/EdgeTicketsWidget";

type TicketArgs = IPaginatedRequest<{ edge: number; textQuery?: string }>;
interface EdgeContentProps {
  edge: IEdgeInfo;
  ticketsArgs: TicketArgs;
  setTicketsArgs: Dispatch<SetStateAction<TicketArgs>>;
  edgeTicketsQuery: UseQueryResult<IPaginatedResponse<ITicketListing[]>>;
  onUpdateEdge: (newEdge: IEdgeInfo) => void;
  onRevalidate: () => void;
  userListData: IUserListData[] | [];
}

const EdgeContent: React.FC<EdgeContentProps> = (props) => {
  const {
    edge,
    ticketsArgs,
    setTicketsArgs,
    edgeTicketsQuery,
    onUpdateEdge,
    onRevalidate,
    userListData
  } = props;

  const queryClient = useQueryClient();

  const onMerge = (newEdge: IEdgeInfo): void => {
    onUpdateEdge(newEdge);
    edgeTicketsQuery.refetch();
  };

  const onDesktop = useMediaQuery("(min-width: 992px)"); // lg breakpoint in bootstrap.

  const loadAuditLogs = useInfiniteQuery(
    [WebCacheKey.AUDIT_LOGS_INFINITE, edge.id],
    ({ pageParam = 1 }) =>
      fetchEdgeAuditLogs(edge.id, {
        page: pageParam,
        perPage: 5,
        sortBy: null
      }),
    {
      getPreviousPageParam: (data) => (data.page > 1 ? data.page - 1 : false),
      getNextPageParam: (data) => (data.hasNext ? data.page + 1 : false)
    }
  );

  const auditLogs =
    loadAuditLogs.data?.pages.flatMap((page) => page.items) || [];

  return (
    <PageContent className="edge-content">
      <div className="container">
        <div className="id-section">
          <EdgeBreadcrumbs edge={edge} />
          <EdgeBadges edge={edge} />
        </div>
        <div className="row">
          <div className="col-lg-6">
            <section>
              <EdgeTitle edge={edge} onUpdateEdge={onUpdateEdge} />
            </section>
            {!onDesktop && (
              <section>
                <EdgeButtons
                  edge={edge}
                  onUpdateEdge={onUpdateEdge}
                  onRevalidate={onRevalidate}
                  onMerge={onMerge}
                />
              </section>
            )}
            <section>
              <EdgeLabels edge={edge} onUpdateEdge={onUpdateEdge} />
            </section>
            <section>
              <EdgeTimestamps edge={edge} userListData={userListData} />
            </section>
            <section>
              <EdgeTeam edge={edge} onUpdateEdge={onUpdateEdge} />
            </section>
            <section>
              <EdgeSpace edge={edge} onUpdateEdge={onUpdateEdge} />
            </section>
            <section className="d-flex order-2">
              <div className="w-50 pr-1">
                <EdgeAssignee edge={edge} onUpdateEdge={onUpdateEdge} />
              </div>
              <div className="w-50 pl-1">
                <EdgeStatus edge={edge} onUpdateEdge={onUpdateEdge} />
              </div>
            </section>
            <section>
              <EdgeSummaryWidget
                key={edge.id}
                edge={edge}
                onUpdateEdge={onUpdateEdge}
              />
            </section>
            <section>
              <EdgeResourcesWidget edge={edge} onUpdateEdge={onUpdateEdge} />
            </section>
            <section>
              <Tabs tabs={["Comments", "History"]}>
                <EdgeCommentWidget edge={edge} onUpdate={onRevalidate} />
                <EdgeAuditWidget
                  logs={auditLogs}
                  isLoading={loadAuditLogs.isLoading}
                  isFetchingNextPage={loadAuditLogs.isFetchingNextPage}
                  hasNextPage={loadAuditLogs.hasNextPage}
                  fetchNextPage={() => loadAuditLogs.fetchNextPage()}
                />
              </Tabs>
            </section>
          </div>
          <div className="col-lg-6">
            {onDesktop && (
              <section>
                <EdgeButtons
                  edge={edge}
                  onUpdateEdge={onUpdateEdge}
                  onRevalidate={onRevalidate}
                  onMerge={onMerge}
                />
              </section>
            )}
            <section>
              <EdgeIssuesWidget edge={edge} onUpdateEdge={onUpdateEdge} />
            </section>
            <section>
              <EdgeTicketsWidget
                edge={edge}
                ticketsQuery={edgeTicketsQuery}
                ticketsArgs={ticketsArgs}
                setTicketsArgs={setTicketsArgs}
                onUpdateEdge={onUpdateEdge}
                onChange={() => {
                  queryClient.invalidateQueries(WebCacheKey.EDGE_TICKETS); // Invalidate all queries for this cacheKey.
                }}
              />
            </section>
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default EdgeContent;
