import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useOutsideAlerter } from "@aptedge/lib-ui/src/hooks/useOutsideClick";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateArticleStatus } from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  ArticleStatus,
  ArticleStatusBtn,
  GeneratedKnowledgeResult,
  IUser,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { marked } from "marked";
import { useEffect, useState, useRef, RefObject } from "react";
import { useQuery } from "react-query";
import turndown from "turndown";
import { fetchArticle } from "../clients/GeneratedKnowledge/fetchArticle";
import { useAuth } from "../context/AuthContext";
import { ARTICLE_STATUS_BUTTON } from "../pages/ArticlesPage/ViewArticleModal";

const triggerCopyEvent = (
  text: string,
  event: string,
  id?: string,
  subject?: string
): void => {
  dataLayerPush({
    event: event,
    data: {
      text: JSON.stringify(text),
      article_title: subject,
      article_id: id
    }
  });
};

const turndownService = new turndown();
turndownService.addRule("strikethrough", {
  filter: ["s", "strike", "del"] as (keyof HTMLElementTagNameMap)[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replacement: function (content: any) {
    return "~~" + content + "~~";
  }
});

type UseViewArticleModalProps = {
  kbGenPublishProcess: boolean;
  isLoading: boolean;
  title: string;
  body: string;
  article?: GeneratedKnowledgeResult;
  currentUserRole: UserRole;
  currentUser?: IUser;
  showArticleActions: boolean;
  isExpanded: boolean;
  exportRef: RefObject<HTMLDivElement>;
  handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBodyChange: (content: string) => void;
  handleUnsavedCancel: () => void;
  handleExport: () => void;
  handleCopyHtml: () => void;
  handleCopyMarkdown: () => void;
};

function useViewArticleModal(
  articleId: string,
  checkArticleContentChanged: (isArticleContentChanged: boolean) => void,
  handleUnsavedChanges: () => void
): UseViewArticleModalProps {
  const { flags } = useFlags();
  const { kbGenPublishProcess } = flags;
  const { user: currentUser } = useAuth();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const exportRef = useRef<HTMLDivElement>(null);

  const { currentUserRole } = useAppSelector((state) => state.app);

  const dispatch = useAppDispatch();

  const articleQuery = useQuery(
    ["article", articleId],
    () => fetchArticle({ articleId }),
    {
      cacheTime: 0,
      staleTime: 0
    }
  );

  const article = articleQuery?.data;
  const isLoading = articleQuery?.isLoading;

  const articleStatusAndIcon =
    ARTICLE_STATUS_BUTTON.find(
      (btn) => btn.status.toLowerCase() === article?.status.toLowerCase()
    ) || ({} as ArticleStatusBtn);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(e.target.value);
    checkArticleContentChanged(title !== article?.title);
  };

  const handleBodyChange = (content: string): void => {
    const markdownFromHtml = turndownService.turndown(content);
    setBody(markdownFromHtml);
    checkArticleContentChanged(body !== article?.body.body);
  };

  const handleUnsavedCancel = (): void => {
    handleUnsavedChanges();
  };

  const handleExport = (): void => {
    setIsExpanded(!isExpanded);
  };

  const handleCopyHtml = (): void => {
    const htmlContent = marked.parse(body);
    navigator.clipboard?.writeText(htmlContent ?? "");
    triggerCopyEvent(
      htmlContent,
      GTM_EVENTS.GTM_COPY_KB_GEN_ARTICLE_HTML,
      article?.id,
      article?.title
    );
    handleCloseExportMenu();
  };

  const handleCopyMarkdown = (): void => {
    navigator.clipboard?.writeText(body ?? "");
    triggerCopyEvent(
      body,
      GTM_EVENTS.GTM_COPY_KB_GEN_ARTICLE_MARKDOWN,
      article?.id,
      article?.title
    );
    handleCloseExportMenu();
  };

  const handleCloseExportMenu = (): void => {
    setIsExpanded(false);
  };

  useOutsideAlerter(exportRef, handleCloseExportMenu);

  const showArticleActions = Boolean(
    (((article?.status === ArticleStatus.DRAFT ||
      article?.status === ArticleStatus.IN_REVIEW) &&
      article.authorEmail === currentUser?.email) ||
      currentUserRole !== UserRole.AGENT) &&
      kbGenPublishProcess
  );

  useEffect(() => {
    if (article) {
      setTitle(article.title);
      setBody(article.body.body);
      dispatch(updateArticleStatus(articleStatusAndIcon.status));
    }
  }, [article, articleStatusAndIcon, dispatch]);

  return {
    kbGenPublishProcess,
    isLoading,
    title,
    body,
    article,
    currentUserRole,
    currentUser,
    showArticleActions,
    isExpanded,
    exportRef,
    handleTitleChange,
    handleBodyChange,
    handleUnsavedCancel,
    handleExport,
    handleCopyHtml,
    handleCopyMarkdown
  };
}

export { useViewArticleModal };
