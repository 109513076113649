import kebabCase from "lodash/kebabCase";
import querystring from "query-string";

/**
 * Converts an object to a query string.
 *
 * NOTE: Nested objects and arrays of objects are not supported.
 */
function toQueryString(obj: Record<string, unknown>): string {
  return querystring.stringify(toKebab(obj), {
    arrayFormat: "comma",
    skipNull: true
  });
}

type Dictionary<T> = {
  [index: string]: T;
};
type SimpleTypes = string | number | boolean | null | undefined;
type ShallowDictionary = Dictionary<SimpleTypes | SimpleTypes[]>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toKebab(original: Record<string, any>): ShallowDictionary {
  return Object.entries(original).reduce(
    (memo: ShallowDictionary, [key, value]) => {
      memo[kebabCase(key)] = value;

      return memo;
    },
    {}
  );
}

function post(data: Record<string, unknown>): RequestInit {
  return {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json"
    }
  };
}

function patch(data: Record<string, unknown>): RequestInit {
  return {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json"
    }
  };
}
function put(
  data: Record<string, unknown> | Record<string, unknown>[]
): RequestInit {
  return {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json"
    }
  };
}
export { post, patch, put, toQueryString };
