import type {
  CookedTicket,
  EdgeSupport
} from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { EdgeTicketAdder } from "./edgeTicketAdder";
import { SDKClient } from "./sdkClient";
import { TicketUpdater } from "./ticketUpdater";
import { post } from "./utils";

@injectable()
class EdgeTicketAdderProd implements EdgeTicketAdder {
  constructor(
    @inject(SDKClient) private client: SDKClient,
    @inject(TicketUpdater) private ticketUpdater: TicketUpdater
  ) {}

  async add({
    edgeId,
    ticket,
    patch
  }: {
    edgeId: number;
    ticket: CookedTicket;
    patch?: Partial<Omit<CookedTicket, "id" | "externalId">>;
  }): Promise<[CookedTicket, EdgeSupport]> {
    const url = `/api/edges/${edgeId}/examples/?tickets-by-id=${ticket.id}`;
    const updateTicket = !!patch
      ? this.ticketUpdater.update({
          cookedTicketId: ticket.id,
          patch
        })
      : Promise.resolve(ticket);
    const linkEdge = updateTicket.then(() =>
      this.client.fetch<EdgeSupport>(url, post({}))
    );

    return Promise.all([updateTicket, linkEdge]);
  }
}

export { EdgeTicketAdderProd };
