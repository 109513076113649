import { SearchRateLimitDetails } from "@aptedge/lib-ui/src/types/selfService";
import { inject, injectable } from "inversify";
import { SDKClient } from "../../sdkClient";
import { RateLimit } from "./rateLimit";

@injectable()
class RateLimitProd implements RateLimit {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async getSearchRateLimit(): Promise<SearchRateLimitDetails> {
    const url = "/api/self-service/enabled/";

    return this.client.fetch(url);
  }
}

export { RateLimitProd };
