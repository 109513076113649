import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IJiraOnPremStatus,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import WithLoading from "../../../components/WithLoading/WithLoading";
import {
  useJiraOnPremiseInternalIssueDataSource,
  useJiraCloudInternalIssueDataSource
} from "../../../hooks/clients/useDataSource";
import { AzureDevopsCloudConfig } from "./AzureDevops/AzureDevopsCloudConfig";
import { JiraCloudConfig } from "./JiraCloudConfig";
import { JiraOnPremiseConfig } from "./JiraOnPremiseConfig";

const IssueSourceConfig: React.FunctionComponent = () => {
  const jiraCloudSource = useJiraCloudInternalIssueDataSource();
  const jiraOnPremiseSource = useJiraOnPremiseInternalIssueDataSource();

  const jiraCloudIssueSource = jiraCloudSource?.dataSource ?? null;
  const jiraOnPremiseIssueSource = jiraOnPremiseSource?.dataSource ?? null;
  const isLoading = jiraCloudSource.isLoading;
  const error = jiraOnPremiseSource.error ?? null;
  const jiraCloudError = jiraCloudSource?.error;
  const isOnPremLoading = jiraOnPremiseSource.isLoading;
  const refetchOnPremiseTap = jiraOnPremiseSource.reload;

  return (
    <section className="mb-5">
      <div className="row">
        <WithLoading isLoading={isLoading} fallback={<Spinner />}>
          <div className="col-md-5 p-2 pr-3">
            <h4 className="text-uppercase">Product Issues</h4>
            <p>Connect AptEdge to your Product Issue System.</p>
          </div>
          <div className="col-md-7 p-2 pl-3">
            {jiraOnPremiseIssueSource?.status !==
              IJiraOnPremStatus.AUTHENTICATED && (
              <>
                <JiraCloudConfig
                  issueResource={jiraCloudIssueSource}
                  error={jiraCloudError}
                  isLoading={isLoading}
                />
                <div className="mb-5"></div>
              </>
            )}
            {jiraCloudIssueSource?.oauthStatus !==
              OAuthStatus.AUTHENTICATED && (
              <>
                <JiraOnPremiseConfig
                  reloadTap={refetchOnPremiseTap}
                  issueResource={jiraOnPremiseIssueSource}
                  error={error}
                  isLoading={isOnPremLoading}
                />
                <div className="mb-5"></div>
              </>
            )}
            <AzureDevopsCloudConfig />
          </div>
        </WithLoading>
      </div>
      <hr className="mt-4" />
    </section>
  );
};

export { IssueSourceConfig };
