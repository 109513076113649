/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PlatformTicket,
  PlatformUser,
  PlatformSettings
} from "@aptedge/lib-support-sdk/src";
import { ServiceNowSDK } from "@aptedge/lib-support-sdk/src/platforms/serviceNow";
import { logger } from "@aptedge/lib-ui/src/utils/logger";
import { injectable } from "inversify";
import { request } from "./request";
import { SDKClient } from "./sdkClient";

@injectable()
class SDKClientServiceNow extends SDKClient {
  constructor(private sdk: ServiceNowSDK) {
    super();
  }

  getCurrentTicket(): Promise<PlatformTicket> {
    return Promise.resolve("Promise") as any;
  }
  getCurrentUser(): Promise<PlatformUser> {
    return Promise.resolve("Promise") as any;
  }
  getAppSettings(): Promise<PlatformSettings> {
    return this.sdk.metadata();
  }
  async fetch<Data>(apiPath: string, init?: RequestInit): Promise<Data> {
    if (apiPath.includes("ticket")) return {} as any;
    const params = new URLSearchParams(window.location.search) || "";
    const token = localStorage.getItem("snow_token");

    const url = `https://${params.get("instance")}.aptedge.io/${apiPath.replace(
      /^\//g,
      ""
    )}`;
    const options: RequestInit = {
      ...init,
      referrerPolicy: "no-referrer",
      headers: {
        ...init?.headers,
        [SDKClient.AUTH_HEADER]: `Bearer ${token}`,
        [SDKClient.CLIENT_APP_TYPE_HEADER]: "zendesk",
        [SDKClient.USER_EMAIL_HEADER]: params.get("email") ?? ""
      }
    };

    return request(url, options);
  }

  async navigateTo(...params: Array<string | number>): Promise<void> {
    // no-op, just log.
    logger.log("would navigate to", ...params);
  }

  async resize(size: { height: string }): Promise<void> {
    // no-op, just log.
    logger.log("would resize to", size);
  }
}

export { SDKClientServiceNow };
