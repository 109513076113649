import type { EdgeSupport } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { EdgeTicketRemover } from "./edgeTicketRemover";
import { SDKClient } from "./sdkClient";

@injectable()
class EdgeTicketRemoverProd implements EdgeTicketRemover {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async remove({
    edgeId,
    cookedTicketId
  }: {
    edgeId: number;
    cookedTicketId: number;
  }): Promise<EdgeSupport> {
    const url = `/api/edges/${edgeId}/examples/?tickets-by-id=${cookedTicketId}`;

    return this.client.fetch<EdgeSupport>(url, {
      method: "DELETE",
      headers: {
        "content-type": "text/plain"
      }
    });
  }
}

export { EdgeTicketRemoverProd };
