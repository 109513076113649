import type { CookedTicket } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { SDKClient } from "./sdkClient";
import { TicketCooker } from "./ticketCooker";
import { post, toQueryString } from "./utils";

@injectable()
class TicketCookerProd implements TicketCooker {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async cook(
    zendeskTicketId: number,
    updatedOnDateString?: string,
    refresh?: boolean
  ): Promise<CookedTicket> {
    const lastUpdated = updatedOnDateString
      ? new Date(updatedOnDateString)
      : new Date();
    const lastUpdatedDate = DateTime.fromJSDate(lastUpdated);
    const updatedOnDate = lastUpdatedDate.isValid
      ? lastUpdatedDate
      : DateTime.local();
    const updatedOn = dateToSeconds(updatedOnDate);

    const query = toQueryString({
      ticketId: zendeskTicketId,
      updatedOn,
      refresh: refresh ? true : false
    });

    return this.client.fetch(`/api/tickets/?${query}`, post({}));
  }
}

function dateToSeconds(dateTime: DateTime): number {
  return Math.floor(dateTime.toSeconds());
}

export { TicketCookerProd };
