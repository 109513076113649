import { TicketSummaryResult } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { SDKClient } from "./sdkClient";
import { TicketSummary } from "./ticketSummary";
import { post } from "./utils";

@injectable()
class TicketSummaryProd implements TicketSummary {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async search(ticketId: string): Promise<TicketSummaryResult> {
    const url = `/api/insights/ticket-summary`;
    const res = await this.client.timedFetch<TicketSummaryResult>(
      url,
      post({
        format: "qna--insight-ticket-summary",
        template_params: { TICKET_ID: String(ticketId) }
      })
    );
    return {
      response: res.data.response,
      latencyMillis: res.endTimeMillis - res.startTimeMillis
    };
  }
}

export { TicketSummaryProd };
