import "reflect-metadata";
import { ContentLayout } from "@aptedge/lib-support-ui/src/features/ContentLayout/ContentLayout";
// import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { Preview } from "@aptedge/lib-ui/src/features/EmbeddedSearch/components/Preview/Preview";
import { sendMessage } from "@aptedge/lib-ui/src/features/EmbeddedSearchV2/helpers";
import SearchResultPage from "@aptedge/lib-ui/src/features/EmbeddedSearchV2/SearchResultPage/SearchResultPage";
import {
  // useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
// import { setPreviewMode } from "@aptedge/lib-ui/src/redux/reduxSlice/embeddedAppSlice";
// import { updateAnswerId } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
// import { generateUniqueId } from "@aptedge/lib-ui/src/utils/utils";
import { FC, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { SingleArticle } from "../Article/Article";
import { Articles } from "../Articles/Articles";
import { EmbeddedSearchV3 } from "../EmbeddedSearchV3/EmbeddedSearchV3";
import { SessionReplay } from "../SessionReplay/SessionReplay";
import styles from "./styles.module.scss";

export enum Emb_App_Routes {
  HOME = "/",
  SEARCH = "/search",
  ARTICLES = "/articles",
  ARTICLE = "/article-page",
  RRWEB_PLAYER = "/rrweb-player"
}

export const EmbeddedApp: FC = () => {
  const { searchQuery } = useAppSelector((state) => state.search);
  const { isPreviewMode } = useAppSelector((state) => state.embeddedApp);
  const { cssConfig } = useAppSelector((state) => state.ssApp);
  // const router = useHistory();
  // const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { flags } = useFlags();

  // const onContinueBtnClick = (): void => {
  //   sendMessage({
  //     openModal: true
  //   });
  //   dispatch(setPreviewMode(false));
  //   dispatch(updateAnswerId(generateUniqueId()));
  //   router.push(Emb_App_Routes.SEARCH);
  // };

  useEffect(() => {
    sendMessage({
      getUser: true,
      flags: { sessionReplay: flags.sessionReplay }
    });

    if (pathname === Emb_App_Routes.HOME) {
      sendMessage({ getSearchQuery: true });
    }

    if (pathname === Emb_App_Routes.ARTICLE)
      sendMessage({ expandedPage: "52em" });
    else sendMessage({ expandedPage: "30em" });
  }, [flags.sessionReplay, pathname]);

  if (isPreviewMode) {
    if (cssConfig?.proactive_answers.enabled)
      return (
        <ContentLayout
          isPreviewMode={isPreviewMode}
          className={styles.previewContainer}
          contentClassName={styles.previewContent}
        >
          <Preview searchQuery={searchQuery} />
        </ContentLayout>
      );
    // return (
    //   <button className={styles.previewBtn} onClick={onContinueBtnClick}>
    //     <div className={styles.left}>
    //       <AEIcon name="contact_support" size="2rem" filled />
    //       <span className={styles.text}>
    //         Get an instant answer to your question
    //       </span>
    //     </div>
    //     <AEIcon name="arrow_right_alt" size="2rem" filled />
    //   </button>
    // );
  }

  return (
    <Switch>
      <Route path={Emb_App_Routes.HOME} exact component={EmbeddedSearchV3} />

      <Route exact path={Emb_App_Routes.SEARCH} component={SearchResultPage} />

      <Route
        exact
        path={Emb_App_Routes.ARTICLES}
        render={() => (
          <ContentLayout>
            <Articles />
          </ContentLayout>
        )}
      />
      <Route
        exact
        path={Emb_App_Routes.ARTICLE}
        render={() => (
          <ContentLayout>
            <SingleArticle />
          </ContentLayout>
        )}
      />
      <Route
        exact
        path={Emb_App_Routes.RRWEB_PLAYER}
        render={() => (
          <ContentLayout disableContentPadding>
            <SessionReplay />
          </ContentLayout>
        )}
      />
    </Switch>
  );
};
