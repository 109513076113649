import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import isEqual from "lodash/isEqual";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createFilter } from "../../clients/AptAnswers/createFilter";
import { updateFilter } from "../../clients/AptAnswers/updateFilter";
import { WebCacheKey } from "../../clients/cache";
import { useAptAnswersFilters } from "../../hooks/useAptAnswersFilters";
import { AptAnswersErrorMessage } from "./AptAnswersErrorMessage";
import { AptAnswersFilters } from "./AptAnswersFilters";
import styles from "./CustomerSelfServiceFilters.module.scss";
import { getAptAnswersFilterInfo } from "./utils/getAptAnswersFilterInfo";
import { getFilterRulesForPayload } from "./utils/getFilterRulesForPayload";
import { mergeFiltersWithIntegrations } from "./utils/mergeFiltersWithIntegrations";

const CustomerSelfServiceFilters: FC = () => {
  const { filterPurpose, filterType } = getAptAnswersFilterInfo(
    AptAnswersFilterType.CSS_FILTER
  );
  const filterName = AptAnswersFilterType.CSS_FILTER;

  // Get integration and filter data
  const {
    isError,
    isLoading,
    isSuccess,
    filtersData,
    integrationsData
  } = useAptAnswersFilters(filterType);

  const memoizedFiltersData = useMemo(() => {
    return filtersData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filtersData)]);

  const integrations = useMemo(
    () =>
      mergeFiltersWithIntegrations({
        filterName,
        filtersData: memoizedFiltersData,
        integrationsData
      }),
    [filterName, memoizedFiltersData, integrationsData]
  );

  const [integrationValues, setIntegrationValues] = useState(integrations);

  useEffect(() => {
    if (!isEqual(integrationValues, integrations)) {
      setIntegrationValues(integrations);
    }
  }, [integrationValues, integrations]);

  // Define mutations
  const queryClient = useQueryClient();
  const resetOnMutation = useCallback(() => {
    queryClient.invalidateQueries([
      WebCacheKey.APT_ANSWERS_FILTERS,
      filterType
    ]);
  }, [queryClient, filterType]);
  const createFilterMutation = useMutation(createFilter, {
    onSuccess: () => {
      resetOnMutation();
    }
  });
  const updateFilterMutation = useMutation(updateFilter, {
    onSuccess: () => {
      resetOnMutation();
    }
  });

  // Execute mutations
  const handleItemSave = (integrationName: string): void => {
    const integration = integrationValues.find((integration) => {
      return integration.name === integrationName;
    });
    if (!integration || !integration.filter) {
      return;
    }
    const filterRules = getFilterRulesForPayload({
      filterPurpose,
      integration
    });
    integration.filter.id
      ? updateFilterMutation.mutate({
          filters: [
            {
              id: integration.filter.id,
              name: filterName,
              filterType,
              filterRules
            }
          ]
        })
      : createFilterMutation.mutate({
          filters: [
            {
              name: filterName,
              filterType,
              filterRules
            }
          ]
        });
  };

  return (
    <div className={styles.container}>
      {isLoading && <Spinner />}
      {isError && (
        <AptAnswersErrorMessage message="There as an error loading Knowledge Sources." />
      )}
      {isSuccess && (
        <AptAnswersFilters
          filterType={filterType}
          filterName={filterType}
          integrations={integrationValues}
          setIntegrationValues={setIntegrationValues}
          handleItemSave={handleItemSave}
        />
      )}
    </div>
  );
};

export { CustomerSelfServiceFilters };
