import {
  AptAnswersFilterTypeValues,
  AptAnswersFilterType
} from "@aptedge/lib-ui/src/types/entities";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { fetchFilters } from "../clients/AptAnswers/fetchFilters";
import { fetchIntegrations } from "../clients/AptAnswers/fetchIntegrations";
import { WebCacheKey } from "../clients/cache";

const ONE_HOUR = 60 * 1000 * 60;

const QUERY_OPTIONS = {
  staleTime: ONE_HOUR,
  cacheTime: ONE_HOUR,
  refetchOnMount: false,
  refetchOnWindowFocus: false
};

type UseAptAnswersFiltersValue = {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  filtersData: AptAnswersFilterTypeValues;
  integrationsData: {
    [key: string]: string[];
  };
};

function useAptAnswersFilters(
  filterType: AptAnswersFilterType
): UseAptAnswersFiltersValue {
  const location = useLocation();
  const {
    data: filtersData,
    isLoading: filtersIsLoading,
    isSuccess: filtersIsSuccess,
    isError: filtersIsError
  } = useQuery(
    [WebCacheKey.APT_ANSWERS_FILTERS, filterType, location.key],
    () => fetchFilters({ filterType }),
    QUERY_OPTIONS
  );

  const filterTypeData = filtersData?.[filterType];

  const {
    data: integrationsData,
    isLoading: integrationsIsLoading,
    isSuccess: integrationsIsSuccess,
    isError: integrationsIsError
  } = useQuery([WebCacheKey.APT_ANSWERS_INTEGRATIONS], () =>
    fetchIntegrations()
  );

  const isError = filtersIsError || integrationsIsError;
  const isLoading = filtersIsLoading || integrationsIsLoading;
  const isSuccess = filtersIsSuccess || integrationsIsSuccess;

  return {
    isError,
    isLoading,
    isSuccess,
    filtersData: filterTypeData ?? {},
    integrationsData: integrationsData ?? {}
  };
}

export { useAptAnswersFilters };
