import type { CookedTicket } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import type { Paginated } from "../types/clients";
import { MOCK_LINKED_TICKET } from "./mocks";
import { SDKClient } from "./sdkClient";

type Args = { edgeId: number };
type Response = CookedTicket[];

@injectable()
abstract class EdgeTicketsFetcher {
  abstract fetch(args: Args): Promise<Response>;
}

@injectable()
class EdgeTicketsFetcherMock implements EdgeTicketsFetcher {
  constructor(
    private mockFn: EdgeTicketsFetcher["fetch"] = () =>
      Promise.resolve([MOCK_LINKED_TICKET])
  ) {}

  async fetch(args: Args): Promise<Response> {
    return this.mockFn(args);
  }
}

@injectable()
class EdgeTicketsFetcherProd implements EdgeTicketsFetcher {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async fetch({ edgeId }: Args): Promise<Response> {
    const url = `/api/tickets/?edge=${edgeId}&page=1&per-page=4&sort-by=desc(createdOn)`;

    return this.client.fetch<Paginated<Response>>(url).then((res) => res.items);
  }
}

export { EdgeTicketsFetcher, EdgeTicketsFetcherMock, EdgeTicketsFetcherProd };
