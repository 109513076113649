import {
  IEdgeInfo,
  IEdgeListing,
  IEdgeResult,
  IEdgeStatus,
  ITicketListing,
  ResultOffsetType
} from "@aptedge/lib-ui/src/types/entities";
import faker from "faker";

function buildEdge(overrides: Partial<IEdgeInfo> = {}): IEdgeInfo {
  const id = overrides.id ?? faker.random.number();

  overrides.comments = (overrides.comments ?? []).map((c) => ({
    ...c,
    edgeId: id
  }));

  return {
    name: `Edge ${id}`,
    displayName: `EDGE-${id}: Edge ${id}`,
    internalIssues: [],
    summary: null,
    richSummary: null,
    comments: [],
    status: IEdgeStatus.NEW,
    pointOfContact: null,
    labels: [],
    links: [],
    createdOn: 1,
    createdBy: null,
    updatedOn: 1,
    userUpdatedOn: 1,
    userUpdatedBy: null,
    isAutolinkEnabled: false,
    isAutoreplyEnabled: false,
    archived: false,
    assignee: null,
    usersWatching: [],
    team: null,
    tickets: [],
    space: undefined,
    filters: [],
    url: "",
    offset: {} as ResultOffsetType,
    ...overrides,
    id
  };
}

function convertEdgeToListing(
  edge: IEdgeInfo,
  tickets: Pick<ITicketListing, "id" | "externalId">[] = []
): IEdgeListing {
  const { usersWatching: _watching, links: _links, ...shared } = edge;

  return { ...shared, tickets, links: [] };
}

function convertEdgeToResult(
  edge: IEdgeInfo,
  tickets: Pick<ITicketListing, "id" | "externalId">[] = []
): IEdgeResult {
  const { usersWatching: _watching, links: _links, ...shared } = edge;

  return { ...shared, tickets, links: [], type: "edge" };
}

export { buildEdge, convertEdgeToListing, convertEdgeToResult };
