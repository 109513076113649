import { IEdgeListing } from "@aptedge/lib-ui/src/types/entities";
import { Truthy } from "@aptedge/lib-ui/src/utils/utils";
import { useMemo } from "react";
import { useBusinessDataSourceIsAuthorized } from "../../hooks/clients/useDataSource";
import {
  buildEdge,
  convertEdgeToListing
} from "../../mocks/builders/edgeBuilder";
import { buildLabel } from "../../mocks/builders/labelBuilder";
import { EdgeAssigneeCell } from "./cells/EdgeAssigneeCell";
import { EdgeIdCell } from "./cells/EdgeIdCell";
import { EdgeNameCell } from "./cells/EdgeNameCell";
import { EdgeStatusCell } from "./cells/EdgeStatusCell";
import { EdgeTicketsCell } from "./cells/EdgeTicketsCell";
import { EdgeUpdatedOnCell } from "./cells/EdgeUpdatedOnCell";
import { Column, useTable, UseTableResult } from "./useTable";
import { getCssGridMinMax } from "./utils";

const DUMMY_EDGE = convertEdgeToListing(
  buildEdge({ id: 0, labels: [buildLabel({ id: -1 })] })
);

const areRowsEqual: (a: IEdgeListing, b: IEdgeListing) => boolean = (a, b) =>
  a.id === b.id;

const fixedCol = getCssGridMinMax("min-content", 120);

interface Props {
  data?: IEdgeListing[];
  isSelectable?: boolean;
  isLoading?: boolean;
}

function useEdgeTable(props: Props): UseTableResult<IEdgeListing> {
  const { data, isLoading, isSelectable } = props;

  const hasSalesforce = useBusinessDataSourceIsAuthorized();

  const columns = useMemo(() => {
    return [
      {
        id: "id",
        label: "Edge",
        minMax: getCssGridMinMax("min-content", 80),
        component: EdgeIdCell
      },
      {
        id: "name",
        minMax: hasSalesforce ? getCssGridMinMax(400) : getCssGridMinMax(500),
        component: EdgeNameCell
      },
      {
        id: "updatedOn",
        label: "Updated",
        align: "middle",
        minMax: fixedCol,
        component: EdgeUpdatedOnCell,
        isSortable: true
      },
      {
        id: "tickets",
        label: "Tickets",
        sortKey: "ticketCount",
        justify: "center",
        align: "middle",
        minMax: fixedCol,
        component: EdgeTicketsCell,
        isSortable: true
      },
      {
        id: "status",
        label: "Status",
        align: "middle",
        minMax: getCssGridMinMax("min-content", 120),
        isSortable: true,
        component: EdgeStatusCell
      },
      {
        id: "assignee",
        label: "Assignee",
        align: "middle",
        justify: "center",
        minMax: getCssGridMinMax("min-content", 100),
        component: EdgeAssigneeCell
      }
    ].filter(Truthy) as Column<IEdgeListing>[];
  }, [hasSalesforce]);

  return useTable({
    data,
    isLoading,
    isSelectable,
    areRowsEqual,
    columns,
    dummyRow: DUMMY_EDGE
  });
}

export { useEdgeTable };
