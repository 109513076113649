import {
  PlatformSettings,
  PlatformTicket,
  PlatformUser,
  SalesforceSDK
} from "@aptedge/lib-support-sdk";
import { logger } from "@aptedge/lib-ui/src/utils/logger";
import { injectable } from "inversify";
import { request } from "./request";
import { SDKClient } from "./sdkClient";

@injectable()
class SDKClientSalesforce extends SDKClient {
  constructor(private sdk: SalesforceSDK) {
    super();
  }

  async getCurrentUser(): Promise<PlatformUser> {
    return this.sdk.getUser();
  }

  async getCurrentTicket(): Promise<PlatformTicket> {
    return this.sdk.getTicket();
  }

  async getAppSettings(): Promise<PlatformSettings> {
    return this.sdk.metadata();
  }

  async navigateTo(...params: Array<string | number>): Promise<void> {
    return this.sdk.routeTo(...params).then((res) => {
      return;
    });
  }

  async resize(size: { height: string }): Promise<void> {
    return this.sdk.resize(size).then((res) => {
      return;
    });
  }

  async fetch<Data>(apiPath: string, init?: RequestInit): Promise<Data> {
    try {
      const [currentUser, settings] = await Promise.all([
        this.getCurrentUser(),
        this.getAppSettings()
      ]);

      const url = `https://${settings.domain}/${apiPath.replace(/^\//g, "")}`;

      const options: RequestInit = {
        ...init,
        headers: {
          ...init?.headers,
          [SDKClient.AUTH_HEADER]: `Bearer ${settings.token}`,
          [SDKClient.CLIENT_APP_TYPE_HEADER]: "salesforce",
          [SDKClient.USER_EMAIL_HEADER]: currentUser.email
        }
      };

      // TODO - remove these log lines once the apps are more stable.
      logger.log("request - options", options);
      const response = await request<Data>(url, options);
      logger.log("request - data", response);

      return response;
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = e as any;
      logger.error("request - error", err.message);
      return err;
    }
  }
}

export { SDKClientSalesforce };
