import { SentryProvider } from "@aptedge/lib-ui/src/context/SentryContext";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateSearchOnRouteChange } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  ISearchFilter,
  ISearchResultSortingModes,
  QUERY_PARAMS,
  SearchFilterSubType
} from "@aptedge/lib-ui/src/types/entities";
import { ThemeProvider } from "@mui/material/styles";
import { History } from "history";
import { FC, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";
import { ToastContainer } from "../components/Toast/ToastContainer";
import theme from "../theme";
import { AuthProvider } from "./AuthContext";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AptEdge?: (configObject: Record<string, any>) => void;
  }
}
interface Props {
  history: History;
  queryClient: QueryClient;
}

const AppProviders: FC<Props> = (props) => {
  const { children, history, queryClient } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleBackButtonPressed = (): void => {
      const queryParams = new URLSearchParams(window.location.search);

      const updatedState = {
        searchQuery: queryParams.get(QUERY_PARAMS.QUERY) ?? "",
        searchFilter:
          (queryParams.get(QUERY_PARAMS.FILTER) as ISearchFilter) ??
          ISearchFilter.ALL,
        searchFilterSubType: (queryParams.get(QUERY_PARAMS.FILTER_SUB_TYPE) ??
          "") as SearchFilterSubType,
        sortMode:
          (queryParams.get(QUERY_PARAMS.SORT) as ISearchResultSortingModes) ??
          ISearchResultSortingModes.RELEVANCE,
        page: Number(queryParams.get(QUERY_PARAMS.PAGE) ?? 1),
        /* this line of code sets the answerId redux state if it exists in queryparams while user
          clicks on forward and back button of browser thus maintaining answerId in browser history.
          Also moved the answerId state from answergpt slice to search slice to handle race around condition
          when we change route and update state at the same time on back button click.  */
        answerId: queryParams.get(QUERY_PARAMS.ANSWER_ID) ?? "",
        resultId: queryParams.get(QUERY_PARAMS.RESULT_ID) ?? ""
      };

      dispatch(updateSearchOnRouteChange(updatedState));
    };
    window.addEventListener("popstate", handleBackButtonPressed);
    return () =>
      window.removeEventListener("popstate", handleBackButtonPressed);
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SentryProvider>
          <AuthProvider>
            <Router history={history}>{children}</Router>
            <ToastContainer />
          </AuthProvider>
        </SentryProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export { AppProviders };
