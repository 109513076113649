import { IRichTextContent } from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import { IDatalayer } from "@aptedge/lib-ui/src/utils/event";
import { DateTime } from "luxon";
import { UseMutationResult } from "react-query";
import { ContentType } from "./http";

export enum AppType {
  WEB_APP = "WEB_APP",
  SUPPORT_APP = "SUPPORT_APP"
}
export interface ImageUploadResult {
  success: boolean;
  name?: string;
  url?: string;
}
export enum QUERY_PARAMS {
  QUERY = "query",
  RESULT_ID = "resultId",
  FILTER_SUB_TYPE = "filterSubType",
  FILTER = "filter",
  SORT = "sort",
  PAGE = "page",
  ADDITIONAL_INSTRUCTIONS = "additional-instructions",
  ANSWER_ID = "answerId",
  ARTICLE_ID = "articleId",
  ARTICLE_CREATION_STATE = "articleCreationState",
  ARTICLE_STATUS = "articleStatus",
  PRODUCT_FILTERS = "product_filters",
  QUICK_FILTERS = "quick_filters",
  EXTERNAL_ID = "external_id",
  UPDATE_ANSWER_ID = "updateAnswerId"
}
export enum SEARCH_RESULT_TYPE {
  EDGE = "edge",
  TICKET = "ticket",
  ISSUE = "issue",
  ARTICLE = "article",
  SOCIAL = "social",
  DOCUMENT = "document",
  FEDERATED = "federated",
  GENERATED_KNOWLEDGE = "generated_knowledge"
}
export enum ApiChatMessageSender {
  SYSTEM = "SYSTEM",
  AI = "AI",
  HUMAN = "HUMAN"
}
export enum ApiChatStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR"
}

export interface ApiStreamingJSONAnswerSource {
  title?: string;
  url?: string;
  pages?: number[];
}

export interface ApiStreamingJSONAnswerContent {
  new_searches?: string[];
  summary?: string;
  details?: string;
  text?: string;
  sources?: ApiStreamingJSONAnswerSource[];
  followups?: string[];
  title?: string;
  description?: string;
}

export interface ApiAction {
  content: ApiStreamingJSONAnswerContent;
  query: string;
  initiator: ApiChatMessageSender;
  status: ApiChatStatus;
}

export interface ApiQuestion {
  actions: ApiAction[];
  query: string;
  status: ApiChatStatus;
}

export interface ApiAnswer {
  questions: ApiQuestion[];
  timers: Record<string, unknown>;
}

export interface GQADocSearchResult extends PaginatedCompositeSearchResult {
  answer?: ApiAnswer;
}

declare global {
  interface Window {
    // Window layer is controlled on GTM, so typecheck is not feasible here.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: (...args: any[]) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    aptedgeEvents: IDatalayer<any>[];
  }
}

// Base Entity
export interface IEntity {
  id: number;
  name: string;
}

export enum EntityType {
  PRODUCT = "product",
  THEME = "theme",
  CUSTOMER = "customer",
  FEATURE = "feature"
}

const { CUSTOMER, ...LabelEntity } = EntityType;
export { LabelEntity };
export type LabelEntityType = Exclude<EntityType, "customer">;

export enum MetricCategory {
  PRODUCT = "product",
  THEME = "theme",
  STATUS = "status",
  TEAM = "team",
  ASSIGNEE = "assignee",
  CUSTOMER = "customer",
  FEATURE = "feature",
  EDGE = "edge",
  JIRA = "jira"
}

export interface IMetricsCategoryItem {
  id: number;
  name: string;
  parentName?: string | null;
  change: number;
  total: number;
  type: MetricCategory;
  age?: number;
}

export interface EntityFilters {
  products: number[];
  features: number[];
  themes: number[];
  customers: number[];
}

// Business Data Source

export enum BusinessDataTaskStatus {
  UNKNOWN = "unknown",
  PENDING = "pending",
  SUCCESSFUL = "successful",
  FAILED = "failed"
}

export enum BusinessDataSourceType {
  SALESFORCE = "SALESFORCE"
}

// Customers

export interface IOriginalCustomer {
  id: number;
  name: string;
  pinned: boolean;
  value: { amount: number };
}

export interface ICustomer extends IEntity {
  pinned: boolean;
  monetaryValue: number;
}

export interface IOriginalCustomerListItem extends IOriginalCustomer {
  ticketCount: number;
}

export interface ICustomerListItem extends ICustomer {
  ticketCount: number;
}

// Edges

export enum EdgeAuditLogType {
  EDGE_ARCHIVING = "EDGE_ARCHIVING",
  EDGE_ASSIGNEE_CHANGE = "EDGE_ASSIGNEE_CHANGE",
  EDGE_TEAM_CHANGE = "EDGE_TEAM_CHANGE",
  EDGE_SPACE_CHANGE = "EDGE_SPACE_CHANGE",
  EDGE_COMMENT_CHANGE = "EDGE_COMMENT_CHANGE",
  EDGE_CREATION = "EDGE_CREATION",
  EDGE_EXAMPLE_CHANGE = "EDGE_EXAMPLE_CHANGE",
  EDGE_FILE_CHANGE = "EDGE_FILE_CHANGE",
  EDGE_INTERNAL_ISSUE_REFERENCE_CHANGE = "EDGE_INTERNAL_ISSUE_REFERENCE_CHANGE",
  EDGE_LABEL_CHANGE = "EDGE_LABEL_CHANGE",
  EDGE_MERGING = "EDGE_MERGING",
  EDGE_MIGRATION = "EDGE_MIGRATION",
  EDGE_NAME_CHANGE = "EDGE_NAME_CHANGE",
  EDGE_SUMMARY_CHANGE = "EDGE_SUMMARY_CHANGE",
  EDGE_STATUS_TRANSITION = "EDGE_STATUS_TRANSITION",
  EDGE_WEBLINK_CHANGE = "EDGE_WEB_LINK_CHANGE"
}

export type IBaseAuditLog = {
  id: number;
  authorId: number | null;
  createdOn: number;
  displayText: string;
  edgeId: number;
  edgeDisplayName: string;
};

export type IEdgeAuditLogArchiving = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_ARCHIVING;
  data: { archived: boolean };
};

export type IEdgeAuditLogAssigneeChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_ASSIGNEE_CHANGE;
  data: {
    newUser?: { id: number; email: string };
    oldUser?: { id: number; email: string };
  };
};

export type IEdgeAuditLogCommentChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_COMMENT_CHANGE;
  data: {
    added: { newText: IRichTextContent; id: number }[];
    edited: {
      newText: IRichTextContent;
      oldText: IRichTextContent;
      id: number;
    }[];
    deleted: { oldText: IRichTextContent; id: number }[];
  };
};

export type IEdgeAuditLogCreation = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_CREATION;
  data: { name: string };
};

export type IEdgeAuditLogExampleChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_EXAMPLE_CHANGE;
  data: {
    added: { id: number; externalId: string; displayId?: string }[];
    removed: { id: number; externalId: string; displayId?: string }[];
  };
};

export type IEdgeAuditLogFileChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_FILE_CHANGE;
  data: {
    added?: { fileId: string; fileName: string; contentType: ContentType }[];
    removed?: { fileId: string; fileName: string; contentType: ContentType }[];
  };
};

export type IEdgeAuditLogInternalIssueChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_INTERNAL_ISSUE_REFERENCE_CHANGE;
  data: {
    added: { id: number; externalId: string }[];
    removed: { id: number; externalId: string }[];
  };
};

type LabelAuditData = {
  added: { id: number; displayName: string }[];
  removed: { id: number; displayName: string }[];
};

export type IEdgeAuditLogLabelChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_LABEL_CHANGE;
  data: {
    [EntityType.PRODUCT]?: LabelAuditData;
    [EntityType.FEATURE]?: LabelAuditData;
    [EntityType.THEME]?: LabelAuditData;
  };
};

export type IEdgeAuditLogMerging = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_MERGING;
  data: {
    from?: { id: number; displayName: string }[];
    to?: { id: number; displayName: string }[];
  };
};

export type IEdgeAuditLogMigration = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_MIGRATION;
  data: {
    from?: { id: number; displayName: string };
    to?: { id: number; displayName: string };
    ticketIds: number[];
  };
};

export type IEdgeAuditLogNameChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_NAME_CHANGE;
  data: { oldText: string; newText: string };
};

export type IEdgeAuditLogSummaryChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_SUMMARY_CHANGE;
  data: { oldText?: IRichTextContent; newText?: IRichTextContent };
};

export type IEdgeAuditLogTeamChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_TEAM_CHANGE;
  data: {
    currentTeam?: ITeam;
    previousTeam?: ITeam;
  };
};

export type IEdgeAuditLogSpaceChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_SPACE_CHANGE;
  data: {
    currentSpace?: string;
    previousSpace?: string;
  };
};

export type IEdgeAuditLogStatusChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_STATUS_TRANSITION;
  data: { previous: IEdgeStatus; current: IEdgeStatus };
};

export type IEdgeAuditLogWeblinkChange = IBaseAuditLog & {
  type: EdgeAuditLogType.EDGE_WEBLINK_CHANGE;
  data: { added: string[]; removed: string[] };
};

export type IEdgeAuditLog =
  | IEdgeAuditLogArchiving
  | IEdgeAuditLogAssigneeChange
  | IEdgeAuditLogCommentChange
  | IEdgeAuditLogCreation
  | IEdgeAuditLogExampleChange
  | IEdgeAuditLogFileChange
  | IEdgeAuditLogInternalIssueChange
  | IEdgeAuditLogLabelChange
  | IEdgeAuditLogMerging
  | IEdgeAuditLogMigration
  | IEdgeAuditLogNameChange
  | IEdgeAuditLogStatusChange
  | IEdgeAuditLogSummaryChange
  | IEdgeAuditLogTeamChange
  | IEdgeAuditLogSpaceChange
  | IEdgeAuditLogWeblinkChange;

export interface IEdgeListing {
  id: number;
  name: string;
  displayName: string;
  internalIssues: IProductIssue[];
  tickets: Pick<ITicketListing, "id" | "externalId">[];
  links: IEdgeLink[];
  summary: string | null;
  richSummary: IRichTextContent | null;
  pointOfContact: string | null;
  assignee: IUserListing | null;
  comments: IEdgeComment[];
  labels: ILabel[];
  status: IEdgeStatus | null;
  createdOn: number;
  createdBy: IUserListing | null;
  updatedOn: number;
  userUpdatedOn: number | null;
  userUpdatedBy: IUserListing | null;
  archived: boolean;
  team: ITeam | null;
  space: string | undefined;
  filters: number[];
  ticketCount?: number;
  isAutoreplyEnabled: boolean;
  isAutolinkEnabled: boolean;
  preview?: string;
  url: string;
  offset: ResultOffsetType;
}

export interface IEdgeInfo extends IEdgeListing {
  links: IEdgeLink[];
  usersWatching: number[];
  status: IEdgeStatus;
  internalIssues: IProductIssueDetailed[];
}

export type IEdgeResult = IEdgeListing & { type: "edge" };
export type ITicketResult = ITicketListing & { type: "ticket" };
export type IArticleResult = IArticle & { type: "article" };
export type IFederatedResult = IFederated & { type: "federated" };
export type IDocumentResult = IDocument & { type: "document" };
export type IIssueResult = IIssueListing & { type: "issue" };
export type IGeneratedKnowledgeResult = IGeneratedKnowledgeListing & {
  type: "generated_knowledge";
};

export type ICompositeResult =
  | IEdgeResult
  | ITicketResult
  | IArticleResult
  | IFederatedResult
  | IIssueResult
  | ISocialResult
  | IDocumentResult
  | IGeneratedKnowledgeResult;

export interface PaginatedSearchResultInfo {
  hasNext: boolean;
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
  timers: Record<string, unknown>;
}
export interface PaginatedCompositeSearchResult
  extends PaginatedSearchResultInfo {
  items: ICompositeResult[];
}

export type IEdgeSearchResult = Pick<
  IEdgeListing,
  | "id"
  | "name"
  | "richSummary"
  | "labels"
  | "createdOn"
  | "archived"
  | "status"
  | "ticketCount"
  | "updatedOn"
  | "offset"
>;

export type IJobStatus = "pending" | "running" | "success" | "failed";

export type IBulkReplyFilters = {
  status?: string[]; // The user can set a status filter so that only tickets with that/those status(es) will receive the reply.
};

export type IBulkReply = {
  /** Airflow job ID */
  runId: string;
  edgeId: number;
  /** Timestamp when the reply was initialized */
  createdOn: number;
  /** Timestamp when the reply was sent */
  completedOn?: number;
  createdBy: Pick<IUser, "id" | "email">;
  status: IJobStatus;
  filters: IBulkReplyFilters | undefined;
  content: string;
  internal: boolean;
};

export interface ILabel {
  id: number;
  name: string;
  parentName?: string | null;
  type: EntityType;
}

export interface IEdgeComment {
  createdOn: number;
  updatedOn: number;
  richText: IRichTextContent | null;
  id: number;
  user: IUserListing;
  edgeId: number;
}

export enum IEdgeStatus {
  NEW = "NEW",
  REVIEW = "REVIEW",
  CONFIRMED = "CONFIRMED",
  ESCALATED = "ESCALATED",
  RESOLVED = "RESOLVED"
}

// Edge Resources (Files / Links)
export interface IEdgeLink {
  url: string;
  label?: string | null;
  urlReference: number;
}

export interface IEdgeFile {
  id: string;
  name: string;
  contentType: ContentType;
}

// Features

export interface IFeature extends IEntity {
  productId: number;
  productName: string;
  rule: IRule | null;
}

// Impact

export interface IBaseImpact {
  customerCount?: number;
  ticketCount: number;
  monetaryValue?: number;
}

export interface IImpactByCustomer extends IBaseImpact {
  customer: number;
  customerName: string;
}

export interface IImpactByProduct extends IBaseImpact {
  product: number;
  productName: string;
}

export interface IImpactByFeature extends IBaseImpact {
  feature: number;
  featureName: string;
}

export interface IImpactByTheme extends IBaseImpact {
  theme: number;
  themeName: string;
}

export interface IRawImpact extends IBaseImpact {
  product?: number;
  productName?: string;
  feature?: number;
  featureName?: string;
  customer?: number;
  customerName?: string;
  theme?: number;
  themeName?: string;
}

export interface IEnrichedImpact {
  productName?: string;
  featureName?: string;
  customerName?: string;
  themeName?: string;
}

export type IImpact = IRawImpact & IEnrichedImpact;

// Internal Issues

export interface IInternalIssue {
  id: number;
  externalId: string;
}

export enum InternalIssueSourceType {
  MOCK = "MOCK",
  JIRA = "JIRA"
}

export interface IInternalIssueSource extends IProviderSource {
  type: InternalIssueSourceType;
}

export type IJiraCloudInternalIssueSource = IProviderSource;

export interface IJiraOnPremiseInternalIssueSource {
  accessToken: string;
  configuredBaseUrl: string;
  status: IJiraOnPremStatus;
}

export interface ICreateJiraOnPremiseInternalIssueSourceRequest {
  configuredBaseUrl: string;
  accessToken: string;
}

export type ICreateJiraCloudInternalIssueSourceRequest = Pick<
  IJiraCloudInternalIssueSource,
  "oauthClientId" | "oauthClientSecret" | "configuredBaseUrl"
>;

export type IConfluenceCloudArticleSource = IProviderSource;

export interface ISharepointSource {
  id: number;
  instanceName: string;
  name: string;
  providerUserLogin: boolean;
  status: string;
  tenantId: string;
  type: string;
}

export interface ISharepointDriveSource {
  id: number;
  instanceName: string;
  name: string;
  providerUserLogin: boolean;
  status: string;
  tenantId: string;
  type: string;
  sites?: ISharepointDriveSite[];
}

export interface ISharepointDriveSite {
  siteId: string;
  error?: string;
  siteName: string;
  siteUrl: string;
}

export type ICreateSharepointSourceRequest = Pick<
  ISharepointSource,
  "tenantId"
>;

export type ICreateSharepointDriveSourceRequest = Pick<
  ISharepointSource,
  "tenantId"
>;

export interface IConfluenceOnPremArticleSource {
  configuredBaseUrl: string;
  accessToken: string;
  status: string;
}

export interface IZendeskKBArticleSource {
  zendeskInstanceName: string;
  accessToken: string;
  username: string;
  status: string;
  type: string;
  id: string;
  providerUserLogin: string;
  name: string;
}

export interface IZendeskKBArticleSourceRequest {
  zendeskInstanceName: string;
  accessToken: string;
  username: string;
}

export type ICreateConfluenceCloudArticleSourceRequest = Pick<
  IConfluenceCloudArticleSource,
  "oauthClientId" | "oauthClientSecret"
>;

export type ICreateConfluenceOnPremArticleSourceRequest = Pick<
  IConfluenceOnPremArticleSource,
  "configuredBaseUrl" | "accessToken"
>;

export type ICreateOneloginSourceRequest = {
  url: string;
  issuerUrl: string;
  certificate: string;
};

export type IOneloginSource = {
  url?: string;
  issuerUrl?: string;
  certificate?: string;
};

export type IOneloginUrl = {
  redirect?: string;
};

// Labels
export type ILabelSetting = {
  id: number;
  name: string;
  type: LabelEntityType;
  rule: IRule | null;
  children?: ILabelSetting[] | null;
};

// Notifications
export interface INotification<Details extends IEdgeAuditLog = IEdgeAuditLog> {
  id: number;
  createdOn: number;
  markedReadOn: number | null;
  details: Details;
  reason: string;
}

export interface INotificationConfig {
  watchedStatuses?: string;
  watchedLabels: {
    [EntityType.PRODUCT]?: ILabel[];
    [EntityType.FEATURE]?: ILabel[];
    [EntityType.THEME]?: ILabel[];
  };
}

// OAuth Providers

export enum OAuthStatus {
  AUTHENTICATED = "AUTHENTICATED",
  UNAUTHENTICATED = "UNAUTHENTICATED"
}

export enum IJiraOnPremStatus {
  AUTHENTICATED = "AUTHENTICATED",
  UNAUTHENTICATED = "UNAUTHENTICATED"
}

export interface IProviderSource {
  id: number;
  name: string;
  providerUserLogin: string;
  oauthStatus: OAuthStatus;
  oauthClientId: string;
  oauthClientSecret: string;
  type: string;
  configuredBaseUrl?: string;
}

export interface IBusinessDataSource extends IProviderSource {
  canBeATicketSource: boolean;
  type: BusinessDataSourceType;
  instanceName?: string;
}

// Phrases

export interface IPhrase {
  id: number;
  ruleId: number;
  value: string;
}

export interface IPhraseSetting {
  phrase: IPhrase;
  label: ILabelSetting;
}

// Products

export interface IProduct extends IEntity {
  features: IFeature[];
  rule: IRule | null;
}

// Product Issues

export interface IProductIssue {
  id: number;
  externalId: string;
  urlReference: { id: number; url: string } | null;
}

export interface IProductIssueDetails {
  status: {
    id: string;
    name: string;
  };
  created: string;
  creator: {
    key: string;
    name: string;
    avatarUrls: {
      "16x16": string;
      "24x24": string;
      "32x32": string;
      "48x48": string;
    };
    displayName: string;
    emailAddress: string;
  };
  summary: string;
  updated: string;
  assignee: {
    key: string;
    name: string;
    avatarUrls: {
      "16x16": string;
      "24x24": string;
      "32x32": string;
      "48x48": string;
    };
    displayName: string;
    emailAddress: string;
  } | null;
  priority: {
    id: string;
    name: string;
  };
  issuetype: {
    id: string;
    name: string;
    description: string;
  };
  description: string;
}

export interface IProductIssueDetailed extends IProductIssue {
  details: IProductIssueDetails | null;
}

// Rules

export enum RuleType {
  PRODUCT = "product_rule",
  FEATURE = "feature_rule",
  THEME = "theme_rule"
}

export interface IRule {
  type: string;
  id: number;
  phrases: IPhrase[];
  entity: number;
}

// Templates

export enum TemplateLocation {
  EDGE_DESCRIPTION = "EDGE_DESCRIPTION",
  TICKET_DESCRIPTION = "TICKET_DESCRIPTION"
}

export type ITemplate = {
  id: number;
  version?: string; // Indicates the version of the *text editor*. Currently "markdown.0.1".
  content: string; // e.g. "## Hello, this is a markdown template"
  location: TemplateLocation; // enum value for where this template should be used.
};

// Themes

export interface ITheme extends IEntity {
  common: boolean;
  rule: IRule | null;
}

// Tickets

export interface ITicketSource extends IProviderSource {
  type: TicketSourceType;
  instanceName: string;
  brandNameFilter: string;
  ticketFormNameFilter: string;
  appToken: string;
  scope: string;
}

export enum TicketSourceType {
  SALESFORCE = "SALESFORCE",
  ZENDESK = "ZENDESK",
  MOCK = "MOCK"
}

export interface ICustomFieldMapping {
  id: number;
  fieldName: string;
  displayName: string;
  displayValue?: string;
}

export interface ITicketFieldValue {
  name: string;
  value: string;
}

export interface ITicketEdgeMetadata {
  edgeId: number;
  filterId: number;
  userId?: number;
  issueId?: string;
  urlReferenceId?: string;
  linkedOn?: number;
}

export interface ITicketListing {
  id: number;
  subject: string;
  externalId: string;
  displayId: string;
  url: string;
  // -1 means ticket is still open
  closedOn: number;
  createdOn: number;
  updatedOn: number;
  featureOwner?: string;
  productOwner?: string;
  themeOwner?: string;
  priority: string;
  status: string;
  customer: Pick<ICustomer, "id" | "name"> | null;
  products: Pick<IProduct, "id" | "name">[];
  features: Pick<IFeature, "id" | "name">[];
  themes: Pick<ITheme, "id" | "name">[];
  space?: string;
  ticketType?: string;
  reason?: string;
  description: string;
  summary: IRichTextContent | null;
  requesterUser: ITicketUser | null;
  ticketSource: number;
  frustration: ITicketFrustration | null;
  urlReferences: IUrlReferenceShort[] | null;
  edges: number[];
  assigneeUser: ITicketUser | null;
  edgesMetadata: ITicketEdgeMetadata[];
  preview?: string;
  body?: string;
  offset: ResultOffsetType;
}

export interface ITicketInfo extends ITicketListing {
  comments: ITicketComment[];
  fields: ITicketFieldValue[] | null;
  monetaryValue: number | null;
}

export interface ITicketComment {
  frustration: ITicketFrustration | null;
  plainText: string;
  htmlText: string | null;
  timestamp: number;
  fromTicketUser: ITicketUser | null;
  channel: string | null;
  internal: boolean;
  id: number;
  externalId: string;
  ticket: number;
}

export interface ITicketUser {
  id: number;
  email: string;
  name: string;
}

export interface ITicketFrustration {
  id: number;
  score: number;
  text: string;
  ticket: number;
}

export interface ITicketSearchHit {
  ticketId: string;
  matchedText?: string;
  matchedTextUrl?: string;
  status: string;
  ticketUrl: string;
}

export interface ITicketSearchResult {
  query: string;
  hits: ITicketSearchHit[];
  totalCount: number;
}

export interface IIssueListing {
  id: string;
  title: string;
  url: string;
  displayUrl: string;
  description?: string;
  createdOn?: number;
  updatedOn?: number;
  sourceType: ISSUES_SOURCE_TYPE;
  offset: ResultOffsetType;
}

export interface ISocialResult {
  sourceType: "slack" | "microsoft-teams";
  createdOn: number;
  channel: string;
  displayUrl: string;
  id: string;
  preview: string;
  title: string;
  type: "social";
  updatedOn: number;
  url: string;
  body?: string;
  offset: ResultOffsetType;
}
interface ISocialInfo {
  sourceType: "slack" | "microsoft-teams";
  createdOn: number;
  channel: string;
  displayUrl: string;
  id: string;
  updatedOn: number;
  url: string;
  body: string;
  name: string;
}
export interface ISocialComment extends ISocialInfo {
  comments: ISocialInfo[];
}
export interface IOriginalTimeSeriesData {
  date: number;
  total: number;
  linked: number;
}

export interface ITimeSeriesData {
  date: DateTime;
  total: number;
  linked: number;
}

export interface IMetricData {
  date: number;
  total: number;
  linked: number;
}

export enum TicketStatus {
  OPEN = "Open",
  CLOSED = "Closed"
}

export interface IArticle {
  id: string;
  title: string;
  url: string;
  displayUrl: string;
  sourceType: ARTICLE_SOURCE_TYPE;
  preview: string;
  createdOn?: number;
  updatedOn?: number;
  body?: string;
  offset: ResultOffsetType;
}

type GeneratedKnowledgeBody = {
  body: string;
};

export interface IGeneratedKnowledgeListing {
  id: string | number;
  title: string;
  body: GeneratedKnowledgeBody;
  createdOn: number;
  updatedOn: number;
  preview: string;
  sourceType: GENERATED_KNOWLEDGE_SOURCE_TYPE;
  url: string;
  offset: ResultOffsetType;
  ticketIds: GeneratedKnowledgeTicketId;
  status: ArticleStatus;
}

export interface IFederated {
  id: string;
  title: string;
  url: string;
  displayUrl: string;
  thumbnailUrl: string;
  sourceType: FEDERATED_SOURCE_TYPE;
  preview: string;
  createdOn?: number;
  updatedOn?: number;
  body?: string;
  offset: ResultOffsetType;
}

export interface IDocument {
  id: string;
  title: string;
  url: string;
  displayUrl: string;
  sourceType: DOCUMENT_SOURCE_TYPE;
  preview: string;
  sourceId: string;
  createdOn?: number;
  updatedOn?: number;
  body?: string;
  offset: ResultOffsetType;
}

export interface IUrlReferenceShort {
  id: number;
  domain: string;
  url: string;
  productIssueId?: string;
}

// URL Reference (to Jira)
export interface IUrlReference extends IUrlReferenceShort {
  ticketUrls: Array<{
    id: string;
    url: string;
  }>;
}

export interface IArticleSetting {
  value: string;
}

// Saved Queries

export enum SavedQueryType {
  CREATED_TICKETS = "CREATED_TICKETS",
  OPEN_TICKETS = "OPEN_TICKETS",
  EDGE_LISTING = "EDGE_LISTING",
  CREATED_EDGES = "CREATED_EDGES",
  EDGE_AUTOLINKING = "EDGE_AUTOLINKING"
}

export interface ISavedQuery {
  id: number;
  type: SavedQueryType;
  query: string;
  name: string;
  pinned: boolean;
  updatedOn: number;
  createdOn: number;
  reply?: string;
  replyDisabled?: boolean;
  replyInternal?: boolean;
}

// User

export interface IUser {
  id: number;
  email: string;
  admin: boolean;
  accountName: string;
  onboardingComplete: boolean;
}

export enum UserRole {
  ADMIN = "Admin",
  REVIEWER = "Reviewer",
  AGENT = "Agent"
}

export interface IUserListing {
  id: number;
  email: string;
  banned?: boolean;
}

// Query

export enum StatusQuery {
  ALL = "all",
  OPEN = "open",
  CLOSED = "closed"
}

// Team

export interface ITeam {
  id: number;
  name: string;
  edgeCount: number;
}

export interface ISpace {
  name: string;
}

export interface ISlackSocialChannelInfoRequest {
  channels: string[];
}

export interface IMSTeamsSocialChannelInfoRequest {
  groupUrl: string;
  shouldShowChannels: boolean;
}

export interface ISharepointDriveSiteInfoRequest {
  sitePath: string;
  siteDomain: string;
}

export enum ISlackStatus {
  AUTHENTICATED = "AUTHENTICATED",
  UNAUTHENTICATED = "UNAUTHENTICATED"
}

export interface ISlackChannel {
  channelId: string;
  error?: string;
  channelName?: string;
  isMember?: boolean;
  isPrivate?: boolean;
}
export interface ISlackSocialSource {
  appId: string;
  accessToken: string;
  channels?: ISlackChannel[];
  id: number;
  status: ISlackStatus;
}

export interface IMSTeamsSource {
  id: number;
  instanceName: string;
  name: string;
  providerUserLogin: boolean;
  status: string;
  tenantId: string;
  type: string;
  filters:
    | [
        {
          channels: [
            {
              channelId: string;
              channelName: string;
              description: string;
              isPrivate: boolean;
              isSelected: boolean;
            }
          ];
          error?: string;
          description?: string;
          isPrivate?: boolean;
          groupId?: string;
          groupName?: string;
        }
      ]
    | [];
}

interface IMSTeamsChannels {
  channelId: string;
  channelName: string;
  description: string;
  isPrivate: boolean;
  isSelected: boolean;
}

export interface IMSTeamsGroup {
  channels?: IMSTeamsChannels[] | undefined;
  error?: string;
  description?: string;
  isPrivate?: boolean;
  groupId?: string;
  groupName?: string;
}

export interface ISlackChannelUpdateSource {
  channels: string[];
}

export interface IMSTeamsChannelUpdateSource {
  tenantId: string;
  filters: [];
}

export interface ISharepointDriveSiteUpdateSource {
  sites: string[];
}

export enum IMSTeamsFlags {
  MUTATE = "mutate",
  SELECT = "select",
  CHECK = "check",
  DELETE = "delete",
  LOAD = "load",
  RELOAD = "reload"
}

export enum ISearchFilter {
  ARTICLE = "articles",
  EDGES = "edges",
  ISSUES = "issues",
  TICKETS = "tickets",
  SOCIAL = "social",
  ALL = "all",
  GENERATED_KNOWLEDGE = "generated_knowledge"
}
export enum ISearchResultSortingModes {
  RELEVANCE = "relevance",
  CREATED_DATE = "createdDate",
  UPDATED_DATE = "updatedDate"
}

export enum INTEGRATION_STATUS {
  OK = "ok",
  ERROR = "error",
  TIME_OUT = "timeOut"
}

export type IntegrationStatus = "ok" | "error" | "timeOut";

export interface ImageUploadResult {
  success: boolean;
  name?: string;
  url?: string;
}

export interface ISalesforceKnowledgeArticleSourceRequest {
  fromBusinessDataSource: number;
}

export interface ISalesforceKnowledgeArticleSourceData {
  id: number;
  instanceName: string;
  name: string;
  providerUserLogin: string;
  salesforceInstanceName: string;
  status: OAuthStatus;
  type: string;
}

export interface ISnowKnowledgeArticleSourceRequest {
  instanceName: string;
  oauthClientSecret: string;
  oauthClientId: string;
}
export interface ISnowKnowledgeArticleSourceData {
  id: number;
  oauthInstanceName: string;
  oauthClientSecret: string;
  oauthClientId: string;
  name: string;
  providerUserLogin: string;
  status: OAuthStatus;
  type: string;
}

export enum ARTICLE_SOURCE_TYPE {
  ZENDESK = "zendesk-knowledge-base",
  CONFLUENCE = "confluence",
  SALESFORCE = "salesforce-knowledge",
  NOTION = "notion",
  SHAREPOINT_SITES = "sharepoint",
  SHAREPOINT_DRIVE = "sharepoint-drive",
  SERVICENOW_KNOWLEDGE = "servicenow-knowledge",
  DOC360 = "website-document360",
  MADCAP_FLARE = "website-madcap-flare",
  CX_ONE_EXPERT = "website-cxone-expert",
  DOCUSAURUS = "website-docusaurus"
}

export enum FEDERATED_SOURCE_TYPE {
  FEDERATED = "federated"
}

export enum GENERATED_KNOWLEDGE_SOURCE_TYPE {
  GENERATED_KNOWLEDGE = "generated_knowledge"
}

export enum DOCUMENT_SOURCE_TYPE {
  SHAREPOINT_DRIVE = "sharepointdrive",
  EDGE = "edge",
  SALESFORCE_FILES = "salesforcefiles"
}

export interface IAzureDevopsIssueSourceRequest {
  organization: string;
  accessToken: string;
}

export interface IAzureDevopsIssueSource {
  id: number;
  organization: string;
  accessToken: string;
  instanceName: string;
  name: string;
  providerUserLogin: string;
  status: OAuthStatus;
  type: string;
}

export interface INotionSourceRequest {
  accessToken: string;
}

export interface INotionSource {
  id: number;
  organization: string;
  accessToken: string;
  instanceName: string;
  name: string;
  providerUserLogin: string;
  status: OAuthStatus;
  type: string;
}

export enum ISSUES_SOURCE_TYPE {
  JIRA = "jira",
  AZURE_DEVOPS = "azure_devops"
}

export enum SOCIAL_SOURCE_TYPE {
  SLACK = "slack",
  MS_TEAMS = "microsoft-teams"
}

export interface IAzureDevopsIssuesSourceRequest {
  organization: string;
  accessToken: string;
}

export interface IAzureDevopsIssuesSource {
  id: number;
  organization: string;
  accessToken: string;
  instanceName: string;
  name: string;
  providerUserLogin: string;
  status: OAuthStatus;
  type: string;
}

export interface INotionSourceRequest {
  accessToken: string;
}

export interface INotionSource {
  id: number;
  organization: string;
  accessToken: string;
  instanceName: string;
  name: string;
  providerUserLogin: string;
  status: OAuthStatus;
  type: string;
}

export interface IAzureDevopsIssuesSourceRequest {
  organization: string;
  accessToken: string;
}

export interface IAzureDevopsIssuesSource {
  id: number;
  organization: string;
  accessToken: string;
  instanceName: string;
  name: string;
  providerUserLogin: string;
  status: OAuthStatus;
  type: string;
}

export interface IUserListData {
  admin: boolean;
  banned: boolean;
  email: string;
  id: number;
  role: UserRole;
}
export interface IUserListingResponse {
  hasNext: boolean;
  items: IUserListData[];
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
}

export type UserSupport = {
  userId: number;
  userEmail: string;
  accountName: string;
  prettyAccountName: string;
  onboardingComplete: boolean;
  selfServiceToken: string;
  role: UserRole;
};

export type NameAndIdSupport = {
  id: number;
  name: string;
};

export type UrlReferenceSupport = {
  id: number;
  domain: string;
  productIssueId?: string;
  url: string;
};

export type LinkSupport = {
  label?: string;
  url: string;
  urlReference: number;
};

type TicketUserSupport = {
  email: string;
  name: string;
};

export type CookedTicket = {
  id: number;
  externalId: string;
  displayId: string;
  subject: string;
  description: string;
  summary?: IRichTextContent;
  preview: string;
  edges?: number[];
  themes: NameAndIdSupport[];
  products: NameAndIdSupport[];
  features: NameAndIdSupport[];
  customer: { name: string };
  status?: string;
  createdOn: number;
  updatedOn: number;
  frustration: ITicketFrustration | null;
  urlReferences: UrlReferenceSupport[] | null;
  assigneeUser: TicketUserSupport | null;
  url?: string;
  offset: ResultOffsetType;
};

export type TemplateSupport = {
  id: number;
  version: string; // Indicates the version of the *text editor*. Currently "markdown.0.1".
  content: string; // e.g. "## Hello, this is a markdown template"
  location: TemplateLocation; // enum value for where this template should be used.
};

export enum SearchTypeSupport {
  ALL = "all",
  EDGES = "edges",
  TICKETS = "tickets",
  ARTICLES = "articles",
  DOCUMENT = "document",
  ISSUES = "issues",
  SOCIAL = "social"
}

export interface IDropdownMenuSupport {
  id: string;
  label: string;
  key: string;
  Icon: React.ReactNode;
}

export type PaginatedCompositeSearchResultSupport = {
  items: SearchResultSupport[];
  hasNext: boolean;
  totalPages: number;
  latencyMillis: number;
  timers: Record<string, unknown>;
  page?: number;
  perPage?: number;
  total?: number;
};

export type PaginatedEdgeSearchResultSupport = {
  items: EdgeResultSupport[];
  hasNext: boolean;
  totalPages: number;
  total: number;
};

export type PaginatedTicketSearchResultSupport = {
  items: TicketResultSupport[];
  hasNext: boolean;
  totalPages: number;
  total: number;
};

export type PaginatedArticleSearchResultSupport = {
  items: ArticleResultSupport[];
  hasNext: boolean;
  totalPages: number;
  total: number;
};

export type PaginatedDocumentSearchResultSupport = {
  items: DocumentResultSupport[];
  hasNext: boolean;
  totalPages: number;
  total: number;
};

export type PaginatedIssueSearchResultSupport = {
  items: IssueResultSupport[];
  hasNext: boolean;
  totalPages: number;
  total: number;
};

export type PaginatedSocialSearchResultSupport = {
  items: ISocialSupport[];
  hasNext: boolean;
  totalPages: number;
  total: number;
};

export type EdgeResultSupport = EdgeSupport & { type: "edge" };
export type TicketResultSupport = CookedTicket & { type: "ticket" };
export type ArticleResultSupport = ArticleSupport & { type: "article" };
export type FederatedResultSupport = FederatedSupport & { type: "federated" };
export type DocumentResultSupport = DocumentSupport & { type: "document" };
export type GeneratedKnowledgeResultSupport = GeneratedKnowledgeSupport & {
  type: "generated_knowledge";
};
export type IssueResultSupport = IssueSupport & { type: "issue" };
export type CompositeResultSupport = EdgeResultSupport | TicketResultSupport;

export type SearchResultsSupport = {
  all?: (
    | EdgeResultSupport
    | TicketResultSupport
    | ArticleResultSupport
    | IssueResultSupport
    | ISocialSupport
    | DocumentResultSupport
    | GeneratedKnowledgeResultSupport
  )[];
  edges?: EdgeResultSupport[];
  tickets?: TicketResultSupport[];
  articles?: ArticleResultSupport[];
  documents?: DocumentResultSupport[];
  issues?: IssueResultSupport[];
  social?: ISocialSupport[];
};

export type SearchResultSupport =
  | EdgeResultSupport
  | TicketResultSupport
  | ArticleResultSupport
  | FederatedResultSupport
  | IssueResultSupport
  | ISocialSupport
  | DocumentResultSupport
  | GeneratedKnowledgeResultSupport;

export interface ISocialSupport {
  sourceType: "slack" | "microsoft-teams";
  createdOn: number;
  channel?: string;
  displayUrl: string;
  id: string;
  preview: string;
  title: string;
  type: "social";
  updatedOn?: number;
  url?: string;
  body?: string;
  offset: ResultOffsetType;
}

export type DocumentSupport = {
  id: string;
  title: string;
  url: string;
  displayUrl: string;
  sourceType: DOCUMENT_SOURCE_TYPE;
  sourceId: string;
  preview: string;
  createdOn?: number;
  updatedOn?: number;
  body?: string;
  offset: ResultOffsetType;
};

export type GeneratedKnowledgeSupport = {
  id: string;
  title: string;
  body: GeneratedKnowledgeBody;
  createdOn: number;
  updatedOn: number;
  preview: string;
  sourceType: GENERATED_KNOWLEDGE_SOURCE_TYPE;
  url: string;
  offset: ResultOffsetType;
};

export type IssueSupport = {
  id: string | number;
  title: string;
  url: string;
  description?: string;
  preview: string;
  created_on?: number;
  createdOn?: number;
  updated_on?: number;
  updatedOn: number;
  displayUrl: string;
  sourceType: ISSUES_SOURCE_TYPE;
  offset: ResultOffsetType;
};

export type ArticleSupport = {
  id: string;
  title: string;
  url: string;
  preview: string;
  createdOn: number;
  displayUrl: string;
  sourceType: ARTICLE_SOURCE_TYPE;
  updatedOn: number;
  body?: string;
  offset: ResultOffsetType;
};

export interface FederatedSupport {
  id: string;
  title: string;
  url: string;
  displayUrl: string;
  thumbnailUrl?: string;
  sourceType: FEDERATED_SOURCE_TYPE;
  preview: string;
  createdOn?: number;
  updatedOn?: number;
  body?: string;
  offset: ResultOffsetType;
}

export type EdgeSupport = {
  id: number;
  name: string;
  richSummary: IRichTextContent | null;
  status: string | null;
  tickets?: Pick<CookedTicket, "id" | "externalId">[];
  assignee: { id: number; email: string } | null;
  links?: LinkSupport[];
  archived: boolean;
  preview: string;
  labels: ILabel[];
  updatedOn: number;
  createdOn: number;
  ticketCount?: number;
  url?: string;
  offset: ResultOffsetType;
};

export type ICompositeResultSupport =
  | EdgeResultSupport
  | TicketResultSupport
  | ArticleResultSupport
  | DocumentResultSupport
  | IssueResultSupport
  | ISocialSupport
  | GeneratedKnowledgeResultSupport;

export type RecentSearchItemSupport =
  | IssueSupport
  | ISocialSupport
  | ArticleResultSupport
  | DocumentResultSupport
  | FederatedResultSupport;

export type RecentSearchResultSupport =
  | EdgeResultSupport
  | TicketResultSupport
  | ArticleResultSupport
  | DocumentResultSupport
  | IssueResultSupport
  | ISocialSupport;

export interface SuggestionsResultsSupport {
  suggestions: string[];
}
export interface InsightSearchSuggestionResponse {
  questions: string[];
}
export interface InsightAutoSearchResponse {
  response: "ticket title and descriptions will be returned";
}

export type InsightSummaryUpdate = {
  summary: string;
  timestamp: number;
};
export interface InsightTicketSummaryResponse {
  problemSummary: string;
  sentiment: string;
  updates: InsightSummaryUpdate[];
  timeToResolution: string;
}
export interface InsightCaseLogResponse {
  response: string;
}
export interface InsightNextStepsResponse {
  response: string;
  reasons: string;
  sources: [{ title: string; url: string }];
}

interface FeedbackResults {
  type?: string | null;
  id: string | number;
  title?: string | null;
  preview?: string;
  sourceType?: string;
  final_rank?: number;
  keyword_search_rank?: number;
  semantic_search_rank?: number;
  search_type?: string;
}

export type TopSearchResults = FeedbackResults;

export interface SearchTelemetryEvent {
  search_query: string;
  integration_type?: SearchFilterSubType;
  total_search_results: number;
  top_search_results: TopSearchResults[];
  search_result_position?: number;
  page?: number;
  search_filter?: string;
  search_sub_filter?: string;
}

export type TicketSummaryResult = {
  response: string;
  latencyMillis: number;
};

export type TicketKBArticleResult = {
  response: string;
};

export type SearchFilterSubType =
  | ARTICLE_SOURCE_TYPE
  | SOCIAL_SOURCE_TYPE
  | ISSUES_SOURCE_TYPE
  | "";

export enum APPROVAL_STATUS {
  APPROVAL = "approval",
  DISAPPROVAL = "disapproval"
}

export enum SearchSource {
  TICKET_INSIGHTS = "ticket_insights",
  SEARCH = "search"
}

export interface IDynamicSourceRequest {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface IDynamicIntegrations {
  description: string | null;
  icon_url: string;
  inputs: {
    [key: string]: string | number;
  };
  key: string;
  name: string;
}

export interface IDynamicSource {
  id: string;
  name: string;
  status: OAuthStatus;
  apifyTaskId: string;
  startUrls: string;
  type: "WEBSITE";
  instanceName: string;
  providerUserLogin: boolean;
  key: string;
  inputData: Record<string, string>;
}

export type GeneratedKnowledgeTicketId = {
  external_id: number | string;
  ticket_id: number | string;
  displayId: number | string;
};

export interface ArticleTicketInfo {
  id: GeneratedKnowledgeTicketId;
  articleIds: string[];
  articleCount: number;
  createdOn: number;
  createdBy: string;
}

export interface GeneratedKnowledgeResult {
  id: string;
  title: string;
  body: GeneratedKnowledgeBody;
  authorEmail: string;
  createdOn: number;
  updatedOn: number;
  ticketIds: GeneratedKnowledgeTicketId[];
  status: string;
  reviewers: ArticleReviewer[];
  public: boolean;
}

export interface GeneratedKnowledgeListData {
  items: GeneratedKnowledgeResult[];
  grouped_by_status: {
    deprecated?: number;
    draft?: number;
    in_review?: number;
    published?: number;
  };
  hasNext: boolean;
  perPage: number;
  page: number;
  total: number;
  totalPages: number;
  userDrafts: number;
  userReviews: number;
}

export interface ResultType {
  id: string;
  icon: React.ReactElement;
  date: string;
  title: string;
  description?: string;
  updatedOn: number | undefined;
  labels?: ILabel[];
  url: string;
  type: string;
  originalId: string | number;
  sourceType?: string;
  sourceId?: string;
  summary?: IRichTextContent | null;
  externalId?: string;
}

export type ResultOffsetType = {
  title: Array<[number, number]>;
  body: Array<[number, number]>;
  comment: CommentOffset;
};

export type CommentOffset = {
  [id: string]: Array<[number, number]>;
};

export enum FeedbackMessageState {
  DEFAULT = "default",
  APPROVAL = "approval",
  DISAPPROVAL = "disapproval",
  ADDITIONAL = "additional",
  SUBMIT = "submit"
}

export enum ArticleCreationState {
  GENERATION = "generation",
  GENERATED = "generated"
}

export enum ArticleStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
  IN_REVIEW = "in_review",
  DEPRECATED = "deprecated",
  ALL = "all"
}

export enum ArticleStatusLabel {
  DRAFT = "Draft",
  PUBLISHED = "Published",
  IN_REVIEW = "In review",
  DEPRECATED = "Deprecated"
}

export type ArticleReviewer = {
  created_on: number;
  email: string;
  reviewer_id: number;
  status: string;
  updated_on: number;
};

export type ArticleReviewerList = {
  [key: string]: ArticleReviewer[];
};

export type ArticleAvailablePublicly = {
  [key: string]: boolean;
};

export type ArticleStatusBtn = {
  icon: JSX.Element;
  status: ArticleStatus;
  selected: boolean;
  label: ArticleStatusLabel;
};

export enum ArticleReviewStatus {
  APPROVED = "approved",
  PENDING = "pending"
}

export enum ReviewerApprovalStatus {
  SUCCESS = "success",
  ERROR = "error",
  NONE = "none"
}

export type FeedbackIds = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type AptAnswersFilter = {
  id: string;
  name: string;
  filterType: string;
  filterRules: AptAnswersFilterRule[];
  manageAllSelected?: boolean;
};

export type AptAnswersFilterRule = {
  integration: string;
  fieldsStr: string;
  condition: string;
  value: string;
};

export type AptAnswersIntegrationsObject = {
  name: string;
  fields: string[];
  filter: AptAnswersFilter | AptAnswersNewFilter | undefined;
};

export type AptAnswersFilterRuleUpdate = {
  idx: number;
  key: "fieldsStr" | "condition" | "value";
  value: string;
};

export enum AptAnswersFilterPurpose {
  "SHOW",
  "HIDE"
}

export enum AptAnswersFilterType {
  CSS_FILTER = "css-filter",
  HIDDEN_FILTER = "hidden-filter",
  QUICK_FILTER = "quick-filter",
  PRODUCT_FILTER = "product-filter"
}

export enum AptAnswersConditions {
  EQUALS = "equals",
  CONTAINS = "contains",
  STARTS_WITH = "startswith",
  NOT_EQUALS = "not-equals",
  NOT_CONTAINS = "not-contains",
  NOT_STARTS_WITH = "not-startswith"
}

export type TicketDetailsType = {
  assigneeUser: null | {
    id: number;
    name: string;
    email: string;
  };
  caseNumber: null | string;
  channel: string;
  closedOn: number;
  comments: {
    channel: string;
    externalId: string;
    fromTicketUser: {
      email: string;
      id: number;
      name: string;
    };
    frustration: null | string;
    htmlText: string;
    id: number;
    internal: boolean;
    plainText: string;
    ticketSourceId: number;
    timestamp: number;
  }[];
  createdOn: number;
  customer: {
    id: number;
    name: string;
  };
  description: string;
  displayId: string;
  edges: {
    id: number;
    type: string;
    data: string; // Customize based on your actual data structure
  }[];
  edgesMetadata: {
    key: string;
    value: string; // Customize based on your actual metadata structure
  }[];
  escalationLikely: boolean;
  events: {
    id: number;
    type: string;
    timestamp: number;
  }[];
  externalId: string;
  featureModificationTime: number;
  featureOwner: string;
  features: {
    name: string;
    status: string;
  }[];
  fields: {
    name: string;
    value: string;
  }[];
  frustration: null | string;
  id: number;
  isAutoReplied: boolean;
  loadedOn: number;
  monetaryValue: number;
  priority: string;
  productModificationTime: number;
  productOwner: string;
  products: {
    name: string;
    version: string;
  }[];
  reason: null | string;
  requesterUser: {
    email: string;
    id: number;
    name: string;
  };
  status: string;
  subject: string;
  summary: {
    content: string;
    version: string;
  };
  themeModificationTime: number;
  themeOwner: string;
  themes: {
    name: string;
    status: string;
  }[];
  ticketSource: number;
  ticketType: null | string;
  updatedOn: number;
  url: string;
  urlReferences: {
    url: string;
    label: string;
  }[];
};

export interface AptAnswersGroupFilter {
  id: string;
  name: string;
}

export type AptAnswersGroupFilterItem = [string, AptAnswersFilter[]];

export type AptAnswersFilterTypeValues = {
  [key: string]: AptAnswersFilter[];
};

export type AptAnswersNewFilter = {
  id: null;
  filterRules: AptAnswersFilterRule[];
  manageAllSelected?: boolean;
};

export type AptAnswersFiltersResponse = {
  "hidden-filter"?: {
    "hidden-filter": AptAnswersFilter[];
  };
  "css-filter"?: {
    "css-filter": AptAnswersFilter[];
  };
  "quick-filter"?: {
    [key: string]: AptAnswersFilter[];
  };
  "product-filter"?: {
    [key: string]: AptAnswersFilter[];
  };
};

export type MyProductsResponse = {
  id: string;
  filterIds: {
    productFilters: string[];
  };
};

export type MyProductsListItem = {
  id: string;
  name: string;
};

export type AptAnswersSearchContextItem = {
  id: string;
  name: string;
};

export type AptAnswersSearchContext = {
  isLoading: boolean;
  quickFiltersIsError: boolean;
  quickFiltersIsSuccess: boolean;
  quickFiltersIsEmpty: boolean;
  quickFiltersItems: AptAnswersSearchContextItem[];
  productFiltersIsError: boolean;
  productFiltersIsSuccess: boolean;
  productFiltersIsEmpty: boolean;
  productFiltersItems: AptAnswersSearchContextItem[];
};

export enum AptAnswersContextType {
  QUICK_FILTER = "quick-filter",
  PRODUCT_FILTER = "product-filter"
}

export type UseMutationResultType<Args, Response> = UseMutationResult<
  Response,
  unknown,
  Args,
  unknown
>;
export type TicketFields = {
  externalId: string;
  isFilterable: boolean;
  name: string;
};
