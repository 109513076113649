import type { CookedTicket } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { SDKClient } from "./sdkClient";
import { TicketUpdater } from "./ticketUpdater";
import { patch } from "./utils";

@injectable()
class TicketUpdaterProd implements TicketUpdater {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async update(args: {
    cookedTicketId: number;
    patch: Partial<Omit<CookedTicket, "id" | "externalId">>;
  }): Promise<CookedTicket> {
    return this.client.fetch(
      `/api/tickets/${args.cookedTicketId}/`,
      patch(args.patch)
    );
  }
}

export { TicketUpdaterProd };
