import { useOutsideAlerter } from "@aptedge/lib-ui/src/hooks/useOutsideClick";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  GeneratedKnowledgeResult,
  IUser,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { marked } from "marked";
import { useEffect, useState, RefObject, useRef } from "react";
import turndown from "turndown";
import { useAuth } from "../context/AuthContext";

const triggerCopyEvent = (
  text: string,
  event: string,
  id?: string,
  subject?: string
): void => {
  dataLayerPush({
    event: event,
    data: {
      text: JSON.stringify(text),
      article_title: subject,
      article_id: id
    }
  });
};

const turndownService = new turndown();
turndownService.addRule("strikethrough", {
  filter: ["s", "strike", "del"] as (keyof HTMLElementTagNameMap)[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replacement: function (content: any) {
    return "~~" + content + "~~";
  }
});

type ArticleItem = {
  title: string;
  body: string;
  currentUser?: IUser;
  currentUserRole: UserRole;
  isExpanded: boolean;
  exportRef: RefObject<HTMLDivElement>;
  handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBodyChange: (content: string) => void;
  handleExport: () => void;
  handleCopyHtml: () => void;
  handleCopyMarkdown: () => void;
};

function useArticleItem(article: GeneratedKnowledgeResult): ArticleItem {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const exportRef = useRef<HTMLDivElement>(null);
  const { user: currentUser } = useAuth();

  const { currentUserRole } = useAppSelector((state) => state.app);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(e.target.value);
  };

  const handleBodyChange = (content: string): void => {
    const markdownFromHtml = turndownService.turndown(content);
    setBody(markdownFromHtml);
  };

  const handleExport = (): void => {
    setIsExpanded(!isExpanded);
  };

  const handleCopyHtml = (): void => {
    const htmlContent = marked.parse(body);
    navigator.clipboard?.writeText(htmlContent ?? "");
    triggerCopyEvent(
      htmlContent,
      GTM_EVENTS.GTM_COPY_KB_GEN_ARTICLE_HTML,
      article?.id,
      article?.title
    );
    handleCloseExportMenu();
  };

  const handleCopyMarkdown = (): void => {
    navigator.clipboard?.writeText(body ?? "");
    triggerCopyEvent(
      body,
      GTM_EVENTS.GTM_COPY_KB_GEN_ARTICLE_MARKDOWN,
      article?.id,
      article?.title
    );
    handleCloseExportMenu();
  };

  const handleCloseExportMenu = (): void => {
    setIsExpanded(false);
  };

  useOutsideAlerter(exportRef, handleCloseExportMenu);

  useEffect(() => {
    if (article) {
      setBody(article.body.body);
      setTitle(article.title);
    }
  }, [article]);

  return {
    title,
    body,
    currentUser,
    currentUserRole,
    isExpanded,
    exportRef,
    handleTitleChange,
    handleBodyChange,
    handleExport,
    handleCopyHtml,
    handleCopyMarkdown
  };
}

export { useArticleItem };
