import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import React from "react";
import Logo from "../images/trimmed-logo.png";

interface Props {
  onReset: () => void;
}

function AppErrorFallback(props: Props): React.ReactElement {
  const { onReset } = props;

  return (
    <>
      <div className="text-center mt-4 mb-4">
        <img alt="logo" data-testid="logo" src={Logo} height="40px" />
        <p className="text-danger mt-2">{AppErrorFallback.MESSAGE}</p>
      </div>
      <div className="d-flex justify-content-center">
        <Button color="secondary" onClick={onReset}>
          Try Again
        </Button>
      </div>
    </>
  );
}

AppErrorFallback.MESSAGE = "We're sorry. Something went wrong.";

export { AppErrorFallback };
