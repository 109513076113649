import type { CookedTicket } from "@aptedge/lib-ui/src/types/entities";
import { injectable } from "inversify";

@injectable()
abstract class TicketCooker {
  abstract cook(
    zendeskTicketId: number,
    updatedOn?: string,
    refresh?: boolean
  ): Promise<CookedTicket>;
}

export { TicketCooker };
