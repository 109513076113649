import { SearchFilters } from "@aptedge/lib-ui/src/services/search/searchFilterService";
import { inject, injectable } from "inversify";
import { DynamicFilters } from "./fetchDynamicFilters";
import { SDKClient } from "./sdkClient";

@injectable()
class DynamicFiltersProd implements DynamicFilters {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async fetchFilters(): Promise<{
    groups: SearchFilters[];
  }> {
    const url = "/api/dynamic/filters";
    return this.client.fetch(url);
  }
}

export { DynamicFiltersProd };
