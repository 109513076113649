import { makeAnswerAPICallArgs } from "@aptedge/lib-ui/src/features/Search/hooks/useAiAnswerQuery";
import { GQADocSearchResult } from "@aptedge/lib-ui/src/types/entities";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import { injectable } from "inversify";

@injectable()
abstract class AnswerEngine {
  abstract search(
    args: makeAnswerAPICallArgs
  ): Promise<TimedResponse<GQADocSearchResult>>;
}

export { AnswerEngine };
