import {
  CreateTicketData,
  CreateTicketResponse,
  MapSessionWithTicketResponse,
  SaveSessionResponse,
  SessionData
} from "@aptedge/lib-ui/src/types/sessionReplay";
import { injectable } from "inversify";

@injectable()
abstract class Dashcam {
  abstract saveSession(sessionData: SessionData): Promise<SaveSessionResponse>;
  abstract createTicket(
    ticketData: CreateTicketData
  ): Promise<CreateTicketResponse>;
  abstract mapSessionWithTicket(
    sessionId: string,
    ticketId: string,
    recordingBlobUrl?: string
  ): Promise<MapSessionWithTicketResponse>;

  abstract uploadSessionUsingSingedUrl(
    signedUrl: string,
    sessionJson: Record<string, string>[]
  ): Promise<Response>;
}

export { Dashcam };
