import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

interface TicketFieldsProps {
  externalIds: string[];
}

async function updateTicketFields({
  externalIds
}: TicketFieldsProps): Promise<void> {
  const url = `${getBackendApiUrl()}/tickets/ticket-fields`;
  const options = patch({ externalIds });
  return request(url, options).then((response) => response);
}

export { updateTicketFields };
