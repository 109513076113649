import {
  SearchTypeSupport,
  SuggestionsResultsSupport
} from "@aptedge/lib-ui/src/types/entities";
import { injectable } from "inversify";

@injectable()
abstract class SearchSuggestions {
  abstract search(
    query: string,
    type?: SearchTypeSupport
  ): Promise<SuggestionsResultsSupport>;
}

export { SearchSuggestions };
