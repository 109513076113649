import TiptapEditor from "@aptedge/lib-ui/src/components/Editor/TiptapEditor";
import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  ArticleStatus,
  ArticleStatusLabel,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import ClearIcon from "@mui/icons-material/Clear";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import { TextField, TextareaAutosize } from "@mui/material";
import classNames from "classnames";
import React, { forwardRef, PropsWithChildren } from "react";
import { useViewArticleModal } from "../../hooks/useViewArticleModal";
import { ArticleMarkdownDisplay } from "../ArticlesReviewPage/ArticleMarkdownDisplay";
import { ArticleActions } from "./ArticleActions";
import { ArticleDetails } from "./ArticleDetails";
import { DiscardSource } from "./DiscardModal";
import styles from "./ViewArticleModal.module.scss";

interface ViewArticleModalProps {
  articleId: string;
  handleClose: () => void;
  checkArticleContentChanged: (isArticleContentChanged: boolean) => void;
  showConfirmationModal: boolean;
  handleUnsavedChanges: () => void;
}

export const ARTICLE_STATUS_BUTTON = [
  {
    icon: <TaskOutlinedIcon className={styles.articleStatusIcon} />,
    label: ArticleStatusLabel.PUBLISHED,
    selected: false,
    status: ArticleStatus.PUBLISHED
  },
  {
    icon: <AEIcon name="edit_document" size="1.25rem" />,
    label: ArticleStatusLabel.DRAFT,
    selected: false,
    status: ArticleStatus.DRAFT
  },
  {
    icon: <PlagiarismOutlinedIcon className={styles.articleStatusIcon} />,
    label: ArticleStatusLabel.IN_REVIEW,
    selected: false,
    status: ArticleStatus.IN_REVIEW
  },
  {
    icon: <AEIcon name="scan_delete" size="1.25rem" />,
    label: ArticleStatusLabel.DEPRECATED,
    selected: false,
    status: ArticleStatus.DEPRECATED
  }
];

const ViewArticleModal = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ViewArticleModalProps>
>(
  (
    {
      articleId,
      handleClose,
      checkArticleContentChanged,
      showConfirmationModal,
      handleUnsavedChanges
    },
    ref
  ) => {
    const {
      kbGenPublishProcess,
      isLoading,
      article,
      currentUserRole,
      currentUser,
      title,
      body,
      showArticleActions,
      isExpanded,
      exportRef,
      handleTitleChange,
      handleBodyChange,
      handleUnsavedCancel,
      handleExport,
      handleCopyHtml,
      handleCopyMarkdown
    } = useViewArticleModal(
      articleId,
      checkArticleContentChanged,
      handleUnsavedChanges
    );

    return (
      <div className={styles.container}>
        <div
          ref={ref}
          className={styles.contentContainer}
          data-testid="article-popup"
        >
          {isLoading && (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          )}
          {article && (
            <>
              {kbGenPublishProcess && (
                <div className={styles.articleSubHeading}>
                  <span>
                    {article.status !== ArticleStatus.DRAFT
                      ? "Review article"
                      : "Save article"}
                  </span>
                  <div className={styles.articleSubHeadingAction}>
                    <div
                      onClick={handleExport}
                      className={styles.exportContainer}
                      data-testid="export-article"
                    >
                      <span>Export</span>
                      <Icons.ChevronDown />
                    </div>
                    {isExpanded && (
                      <div className={styles.exportDropdown} ref={exportRef}>
                        <span
                          onClick={handleCopyHtml}
                          className={styles.exportItem}
                          data-testid="copy-article-html"
                        >
                          Copy HTML
                        </span>
                        <span
                          onClick={handleCopyMarkdown}
                          className={styles.exportItem}
                          data-testid="copy-article-markdown"
                        >
                          Copy Markdown
                        </span>
                      </div>
                    )}
                    <ClearIcon
                      onClick={handleClose}
                      className={styles.clearIcon}
                      data-testid="close-popup"
                    />
                  </div>
                </div>
              )}
              {!kbGenPublishProcess && (
                <>
                  <div className={styles.articleHeader}>
                    <h3>{title}</h3>
                    <button onClick={handleClose}>
                      <Icons.Times size="lg" />
                    </button>
                  </div>
                  <div className={styles.articleContent}>
                    <ArticleMarkdownDisplay articleBody={body} />
                  </div>
                </>
              )}
              {kbGenPublishProcess && title && body && (
                <div
                  className={classNames(styles.articleContent, {
                    [styles.articleContentV2]: kbGenPublishProcess,
                    [styles.articleContentWithoutActions]:
                      kbGenPublishProcess && !showArticleActions
                  })}
                >
                  <div className={styles.bodyLeftContainer}>
                    <div className={styles.headerContainer}>
                      {((article.status === ArticleStatus.PUBLISHED ||
                        article.status === ArticleStatus.DEPRECATED) &&
                        currentUserRole === UserRole.AGENT &&
                        currentUser?.email === article.authorEmail) ||
                      (currentUser?.email !== article.authorEmail &&
                        currentUserRole === UserRole.AGENT) ? (
                        <h3 className={styles.title}>{title}</h3>
                      ) : (
                        <div
                          className={styles.titleInputContainer}
                          data-testid="edit-popup-title"
                        >
                          <TextField
                            value={title}
                            onChange={handleTitleChange}
                            fullWidth={true}
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                              inputComponent: TextareaAutosize,
                              inputProps: {
                                minRows: 1,
                                className: styles.textfield
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {((article.status === ArticleStatus.PUBLISHED ||
                      article.status === ArticleStatus.DEPRECATED) &&
                      currentUserRole === UserRole.AGENT &&
                      currentUser?.email === article.authorEmail) ||
                    (currentUser?.email !== article.authorEmail &&
                      currentUserRole === UserRole.AGENT) ? (
                      <div>
                        <ArticleMarkdownDisplay articleBody={body} />
                      </div>
                    ) : (
                      <div
                        className={styles.bodyTextareaContainer}
                        data-testid="edit-popup-body"
                      >
                        <TiptapEditor
                          initialContent={body}
                          onChange={handleBodyChange}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.bodyRightContainer}>
                    <ArticleDetails
                      article={article}
                      currentUser={currentUser}
                      currentUserRole={currentUserRole}
                    />
                  </div>
                </div>
              )}
              {showArticleActions && (
                <ArticleActions
                  article={article}
                  title={title}
                  body={body}
                  handleClose={handleClose}
                  discardSource={DiscardSource.ARTICLE_POPUP}
                />
              )}
            </>
          )}
        </div>
        {showConfirmationModal && (
          <>
            <div className={styles.backdrop}></div>
            <div className={styles.confirmationContainer}>
              <span className={styles.confirmationHeader}>Save Changes</span>
              <span className={styles.confirmationBody}>
                You have unsaved changes that may be lost if you leave this
                page. Are you sure you want to continue without saving?
              </span>
              <div className={styles.confirmationActions}>
                <button className={styles.confirm} onClick={handleClose}>
                  Yes
                </button>
                <button className={styles.cancel} onClick={handleUnsavedCancel}>
                  No
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export { ViewArticleModal };
