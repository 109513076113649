import { InsightNextStepsResponse } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { SDKClient } from "../../sdkClient";
import { post } from "../../utils";
import { InsightNextSteps } from "./insightNextSteps";

@injectable()
class InsightNextStepsProd implements InsightNextSteps {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async search(
    ticketId: string,
    autoSearchQueryTitle: string,
    format = "qna--insight-next-steps"
  ): Promise<InsightNextStepsResponse> {
    const url = "api/insights/next-steps";

    const payload = post({
      format: format,
      render_only: true,
      template_params: {
        TICKET_ID: String(ticketId),
        AUTO_SEARCH_QUERY: autoSearchQueryTitle
      }
    });
    return this.client.fetch(url, payload);
  }
}

export { InsightNextStepsProd };
