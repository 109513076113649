import { IDatalayer } from "@aptedge/lib-ui/src/utils/event";
import { inject, injectable } from "inversify";
import { EventReporter } from "./eventReporter";
import { SDKClient } from "./sdkClient";

@injectable()
class EventReporterProd implements EventReporter {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  sendEventViaBackend<T>({ event, data }: IDatalayer<T>): Promise<Response> {
    const requestData = {
      headers: {
        "content-type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        event: event,
        properties: data
      })
    };

    const url = `api/analytics_events`;

    return this.client.fetch(url, requestData);
  }

  reportEvent<T>({ event, data }: IDatalayer<T>): Promise<void> {
    return this.sendEventViaBackend({ event, data }).then((result) => {
      // This logging is intentional! Please do not delete.
      console.groupCollapsed(
        `%cEvent captured: "${event}"`,
        "color: green; font-weight: 900"
      );
      console.log(data);
      console.groupEnd();
    });
  }
}

export { EventReporterProd };
