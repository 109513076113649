import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { GPTContext } from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/GPTContext";
import { WelcomePage } from "@aptedge/lib-ui/src/features/WelcomePage/WelcomePage";
import Helmet from "react-helmet";
import { AutoComplete } from "../../components/AutoComplete/AutoComplete";
import { GlobalSearchList } from "../../components/GlobalSearch/GlobalSearchList/GlobalSearchList";
import AnswerResult from "./AnswerResult";
import GlobalSearchFilter from "./GlobalSearchFilter";
import "./GlobalSearchPage.scss";
import useGlobalSearch from "./hooks/useGlobalSearch";
import { SearchFilters } from "./SearchFilters/SearchFilters";
import SearchResultPreviewCard from "./SearchResultPreview/SearchResultPreviewCard";

const GlobalSearchPage: React.FC = () => {
  const {
    isSearchListRefActive,
    searchContainerRef,
    handleActiveRefForSearchList,
    searchRef,
    flags,
    refetchAnswer,
    searchResultsArrowNavigation,
    userData,
    isLoading,
    showFeedback,
    answerId
  } = useGlobalSearch();

  if (isLoading) return <Spinner />;

  if (!userData?.onboardingComplete) return <WelcomePage />;

  return (
    <>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <GPTContext.Provider value={{ refetchAnswer }}>
        <div className="global-search-page-container">
          <div
            className="global-search-page-main"
            ref={isSearchListRefActive ? searchContainerRef : null}
          >
            <div>
              <div className="global-search-controls-container">
                <AutoComplete
                  handleActiveRefForSearchList={handleActiveRefForSearchList}
                  searchRef={searchRef}
                />
                <SearchFilters />
              </div>
              <GlobalSearchFilter />
              <hr />
            </div>
            <div className="global-search-page-results">
              <div className="global-search-results-container">
                <AnswerResult answerId={answerId} flags={flags} />
                <GlobalSearchList
                  arrowNavigation={searchResultsArrowNavigation}
                />
              </div>
              <SearchResultPreviewCard showFeedback={showFeedback} />
            </div>
          </div>
        </div>
      </GPTContext.Provider>
    </>
  );
};

export default GlobalSearchPage;
