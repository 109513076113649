import type { CookedTicket } from "@aptedge/lib-ui/src/types/entities";
import { injectable } from "inversify";

@injectable()
abstract class TicketUpdater {
  abstract update(args: {
    cookedTicketId: number;
    patch: Partial<Omit<CookedTicket, "id" | "externalId">>;
  }): Promise<CookedTicket>;
}

export { TicketUpdater };
