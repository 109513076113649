import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ArticleAvailablePublicly,
  ArticleReviewerList,
  ArticleStatus,
  IUserListing,
  QUERY_PARAMS
} from "../../types/entities";
import { isSupportApp } from "../../utils/supportApp";

export enum ArticleUser {
  CREATE = "create",
  REVIEW = "review"
}
const SEARCH_PATH = isSupportApp
  ? `?${window.location.hash.split("?")[1]}`
  : window.location.search;

const queryParams = new URLSearchParams(SEARCH_PATH);

const INITIAL_PAGE = Number(queryParams.get(QUERY_PARAMS.PAGE) ?? 1);

type SliceStateType = {
  draftArticleCount: number;
  reviewArticleCount: number;
  filterArticleStatus: string[];
  tabArticleStatus: string;
  articleCreatedUser: IUserListing;
  articleReviewedUser: IUserListing;
  page: number;
  availablePublicly: ArticleAvailablePublicly;
  articleStatus: ArticleStatus;
  articleReviewers: ArticleReviewerList;
  totalPages: number;
  resetFilters: boolean;
  totalArticles: number;
  selectedReviewer: IUserListing;
};

const initialState: SliceStateType = {
  draftArticleCount: 0,
  reviewArticleCount: 0,
  filterArticleStatus: [],
  tabArticleStatus: ArticleStatus.ALL,
  articleCreatedUser: {} as IUserListing,
  articleReviewedUser: {} as IUserListing,
  page: Number(INITIAL_PAGE),
  availablePublicly: {} as ArticleAvailablePublicly,
  articleStatus: ArticleStatus.ALL,
  articleReviewers: {} as ArticleReviewerList,
  totalPages: 0,
  resetFilters: false,
  totalArticles: 0,
  selectedReviewer: {} as IUserListing
};

const kbGenSlice = createSlice({
  name: "knowledgeBase",
  initialState,
  reducers: {
    updateDraftArticleCount: (state, action: PayloadAction<number>) => {
      state.draftArticleCount = action.payload;
    },
    updateReviewArticleCount: (state, action: PayloadAction<number>) => {
      state.reviewArticleCount = action.payload;
    },
    updateFilterArticleStatus: (state, action: PayloadAction<string[]>) => {
      state.filterArticleStatus = action.payload;
    },
    updateTabArticleStatus: (state, action: PayloadAction<string>) => {
      state.tabArticleStatus = action.payload;
    },
    updateArticleCreatedUser: (state, action: PayloadAction<IUserListing>) => {
      state.articleCreatedUser = action.payload;
    },
    updateArticleReviewedUser: (state, action: PayloadAction<IUserListing>) => {
      state.articleReviewedUser = action.payload;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    updateAvailablePublicly: (
      state,
      action: PayloadAction<ArticleAvailablePublicly>
    ) => {
      state.availablePublicly = action.payload;
    },
    updateArticleStatus: (state, action: PayloadAction<ArticleStatus>) => {
      state.articleStatus = action.payload;
    },
    updateArticleReviewers: (
      state,
      action: PayloadAction<ArticleReviewerList>
    ) => {
      state.articleReviewers = action.payload;
    },
    updateTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    updateResetFilters: (state, action: PayloadAction<boolean>) => {
      state.resetFilters = action.payload;
    },
    updateTotalArticles: (state, action: PayloadAction<number>) => {
      state.totalArticles = action.payload;
    },
    updateSelectedReviewer: (state, action: PayloadAction<IUserListing>) => {
      state.selectedReviewer = action.payload;
    }
  }
});

export const {
  updateDraftArticleCount,
  updateReviewArticleCount,
  updateFilterArticleStatus,
  updateArticleCreatedUser,
  updateArticleReviewedUser,
  updateTabArticleStatus,
  updatePage,
  updateAvailablePublicly,
  updateArticleStatus,
  updateArticleReviewers,
  updateTotalPages,
  updateResetFilters,
  updateTotalArticles,
  updateSelectedReviewer
} = kbGenSlice.actions;

export default kbGenSlice.reducer;
