import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { IEdgeFile, IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { deleteEdgeFile } from "../../../clients/Edges/deleteEdgeFile";
import { fetchEdgeFiles } from "../../../clients/Edges/fetchEdgeFiles";
import { convertQueryStateToClientStatus } from "../../../clients/utils/convertQueryStateToClientStatus";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { MutationErrorToast } from "../../../components/ErrorHandler/MutationErrorToast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { TooltipMessage } from "../../../registry/TooltipMessage";
import "./EdgeFileList.scss";
import { EdgeFilePreviewModal } from "./EdgeFilePreviewModal";

interface Props {
  edge: IEdgeInfo;
  onUpdate: () => void;
}

const EdgeFileList: React.FC<Props> = ({ edge, onUpdate }) => {
  const isDisabled = edge.archived;

  const [fileToDelete, setFileToDelete] = useState<IEdgeFile>();
  const [previewedFile, setPreviewedFile] = useState<IEdgeFile>();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const edgeFilesQuery = useQuery(
    [WebCacheKey.EDGE_FILES, edge.id],
    () => fetchEdgeFiles(edge.id),
    { useErrorBoundary: true }
  );

  const removeEdgeFile = useMutation(deleteEdgeFile, {
    onSuccess: () => {
      setFileToDelete(undefined);
      onUpdate();
    }
  });

  const handleClickDelete = (file: IEdgeFile): void => {
    removeEdgeFile.reset();
    setFileToDelete(file);
  };

  const handleDelete = (): void => {
    if (!fileToDelete) {
      return;
    }

    removeEdgeFile.mutate({ edgeId: edge.id, fileId: fileToDelete.id });
  };

  const files = edgeFilesQuery.data || [];

  return (
    <div className="edge-file-list">
      <>
        <small>Files</small>
        <WithLoading isLoading={edgeFilesQuery.isLoading}>
          {!!files.length ? (
            <ul className="list-group mb-3">
              {files.map((file, key) => (
                <li
                  key={`${key}-${file}`}
                  className="list-group-item d-flex align-items-center justify-content-between"
                >
                  <div>
                    <Icons.File className="text-gray-500" />
                    <span className="ml-3">{file.name}</span>
                  </div>
                  <div className="icons">
                    {!isDisabled && (
                      <>
                        <Tooltip content={TooltipMessage.PREVIEW}>
                          <span
                            className="icon-btn"
                            data-testid={`open-preview-${key}`}
                            onClick={() => {
                              setPreviewedFile(file);
                              setPreviewModalOpen(true);
                            }}
                          >
                            <Icons.ExternalLink />
                          </span>
                        </Tooltip>
                        <span
                          className="icon-btn delete"
                          data-testid={`remove-file-${key}`}
                          onClick={() => handleClickDelete(file)}
                        >
                          <Icons.Trash />
                        </span>
                      </>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <NoData message={NoDataMessage.EDGE_FILES} />
          )}
        </WithLoading>
      </>
      <ConfirmationModal
        title="Delete this file?"
        loadingTitle="Deleting file..."
        status={convertQueryStateToClientStatus(removeEdgeFile)}
        isOpen={!!fileToDelete && !isDisabled}
        onClose={() => setFileToDelete(undefined)}
      >
        <p>This will delete the file linked to the Edge.</p>
        <div className="mt-3 d-flex justify-content-end">
          <Button
            className="mr-2"
            color="secondary"
            onClick={() => setFileToDelete(undefined)}
          >
            Cancel
          </Button>
          <Button color="danger" onClick={() => handleDelete()}>
            Delete
          </Button>
        </div>
      </ConfirmationModal>
      {!!previewedFile && (
        <EdgeFilePreviewModal
          edge={edge}
          file={previewedFile}
          isOpen={previewModalOpen}
          onClose={() => setPreviewModalOpen(false)}
        />
      )}
      <MutationErrorToast
        resetError={removeEdgeFile.reset}
        error={removeEdgeFile.error}
      />
    </div>
  );
};

export { EdgeFileList };
