import { Container } from "inversify";
import React, { createContext, useContext } from "react";

type Context = Container;
const ContainerContext = createContext<Context>(new Container());

const ContainerProvider: React.FC<{ container?: Container }> = ({
  children,
  container = new Container()
}) => {
  return (
    <ContainerContext.Provider value={container}>
      {children}
    </ContainerContext.Provider>
  );
};

const useContainer = (): Context => useContext(ContainerContext);

export { ContainerProvider, useContainer };
