import { AptAnswersFilterPurpose } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./FilterManageAllOption.module.scss";

interface FilterManageAllOptionProps {
  manageAllSelected: boolean;
  handleManageAllClick: () => void;
  integrationName: string;
  filterPurpose: AptAnswersFilterPurpose;
}

const FilterManageAllOption: React.FC<FilterManageAllOptionProps> = ({
  manageAllSelected,
  handleManageAllClick,
  integrationName,
  filterPurpose
}) => {
  const message =
    filterPurpose === AptAnswersFilterPurpose.SHOW
      ? `Show all results from ${integrationName}`
      : `Hide all results from ${integrationName}`;
  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        id="manage-all"
        checked={manageAllSelected}
        onChange={handleManageAllClick}
        onClick={(e) => e.stopPropagation()}
      />
      <label htmlFor="manage-all">{message}</label>
    </div>
  );
};

export { FilterManageAllOption };
