import { inject, injectable } from "inversify";
import { EmptyAgentDescriptionConfigFetcher } from "./emptyAgentDescriptionConfigFetcher";
import { SDKClient } from "./sdkClient";

@injectable()
class EmptyAgentDescriptionConfigFetcherProd
  implements EmptyAgentDescriptionConfigFetcher {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async fetch(): Promise<boolean | null> {
    return this.client.fetch("/api/config/EMPTY_AGENT_DESCRIPTION_WARNING/");
  }
}

export { EmptyAgentDescriptionConfigFetcherProd };
