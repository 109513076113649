import { IRichTextContent } from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import type {
  CookedTicket,
  EdgeSupport
} from "@aptedge/lib-ui/src/types/entities";
import { injectable } from "inversify";

@injectable()
abstract class EdgeCreator {
  abstract create(args: {
    name: string;
    ticket: CookedTicket;
    richSummary?: IRichTextContent;
    ticketPatch?: Partial<Omit<CookedTicket, "id" | "externalId">>;
  }): Promise<[CookedTicket, EdgeSupport]>;
}

export { EdgeCreator };
