import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import classNames from "classnames";
import * as React from "react";
import styles from "./ArticlesTableHeader.module.scss";

const ArticlesTableHeader: React.FC = () => {
  const { flags } = useFlags();

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerV2]: flags.kbGenPublishProcess
      })}
    >
      <div className={styles.headerItem}>Article</div>
      {flags.kbGenPublishProcess && (
        <div className={styles.headerItem}>Status</div>
      )}
      <div className={styles.headerItem}>
        {flags.kbGenPublishProcess ? "Sources" : "Source Ticket"}
      </div>
      <div className={styles.headerItem}>
        {flags.kbGenPublishProcess ? "Modified on" : "Created date"}
        {flags.kbGenPublishProcess && <AeIcons.ChevronDown />}
      </div>
      <div className={styles.headerItem}>Created by</div>
      {flags.kbGenPublishProcess && (
        <div className={styles.headerItem}>Reviewed by</div>
      )}
      {!flags.kbGenPublishProcess && (
        <div className={styles.headerItem}>Actions</div>
      )}
    </div>
  );
};

export { ArticlesTableHeader };
