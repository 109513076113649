import type { TemplateSupport } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { SDKClient } from "./sdkClient";
import { TemplateFetcher } from "./templateFetcher";

@injectable()
class TemplateFetcherProd implements TemplateFetcher {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async fetch(): Promise<TemplateSupport[]> {
    return this.client.fetch("/api/templates");
  }
}

export { TemplateFetcherProd };
