import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import SkeletonLoader from "@aptedge/lib-ui/src/components/SkeletonLoader/SkeletonLoader";
import useMappedResults from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/useMappedResults";
import MarkdownAnswer from "@aptedge/lib-ui/src/features/AiAnswer/components/MarkdownAnswer";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  AnswerQuestion,
  AnswerAction,
  updateAnswerCardVisibility
} from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  updateResultId,
  updateSearchCardVisibility
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { QUERY_PARAMS } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import styles from "./AnswerSearchResult.module.scss";

const AnswerSearchResult: React.FC = () => {
  const { removeQueryParams } = useQueryParams();
  const { mappedResults, totalSearchResults } = useMappedResults();
  const { isSearchLoading } = useAppSelector((state) => state.search);
  const {
    answer,
    answerCardVisibility,
    isAnswerLoaded,
    isAnswerLoading
  } = useAppSelector((state) => state.answerGPT);

  const dispatch = useAppDispatch();

  const showPreview = (): void => {
    dispatch(updateAnswerCardVisibility(true));
    dispatch(updateSearchCardVisibility(false));
    dispatch(updateResultId(""));
    removeQueryParams(QUERY_PARAMS.RESULT_ID);
  };

  const isLoadingState = isSearchLoading || isAnswerLoading || !isAnswerLoaded;

  return (
    <div
      className={classNames(styles.answerSearchResult, {
        [styles.answerCardVisible]: answerCardVisibility
      })}
      onClick={showPreview}
    >
      <div className={styles.answerHeader}>
        <AeIcons.AiAnswer />
        <div className={styles.title}>Answer AI</div>
      </div>
      {isLoadingState ? (
        <SkeletonLoader lines={3} containerClassName={styles.loader} />
      ) : (
        <div className={styles.answerBody}>
          {isAnswerLoaded &&
            answer.map((question: AnswerQuestion, i: number) => (
              <div key={i}>
                {i > 0 && <h1>{question.query}</h1>}
                {question.actions.map((action: AnswerAction, j: number) => (
                  <MarkdownAnswer
                    key={j}
                    resultsMap={mappedResults}
                    totalResults={totalSearchResults}
                    answer={action.answer}
                  />
                ))}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export { AnswerSearchResult };
