import { useSentry } from "@aptedge/lib-ui/src/context/SentryContext";
import { sanitizeSnippet } from "@aptedge/lib-ui/src/utils/generator";
import { FC, ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import styles from "./styles.module.scss";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const remarkGfm = require("remark-gfm").default;

type Props = {
  markdown?: string | null;
  components?: Record<string, unknown>;
  disableLinks?: boolean;
};

const getDefaultComponents = (
  disableLinks: boolean
): Record<string, unknown> => ({
  a: ({
    href,
    children
  }: {
    href?: string | undefined;
    children?: ReactNode;
  }) =>
    href && !disableLinks ? (
      <a
        className="preview-link"
        target="_blank"
        rel="noopener noreferrer"
        href={href}
      >
        {children}
      </a>
    ) : null,
  code: ({ children }: { children?: ReactNode }) => (
    <pre className={styles.codePreview}>
      <code>{children}</code>
    </pre>
  )
});

const MarkdownViewer: FC<Props> = ({
  markdown,
  components,
  disableLinks = false
}) => {
  const sentry = useSentry();

  const updatedMarkdown = sanitizeSnippet(sentry, "", {}, markdown ?? "");

  if (!updatedMarkdown) return null;
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{ ...components, ...getDefaultComponents(disableLinks) }}
    >
      {updatedMarkdown}
    </ReactMarkdown>
  );
};

export default MarkdownViewer;
