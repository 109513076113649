import { SDKClient } from "@aptedge/lib-support-ui/src/clients/sdkClient";
import useMappedResults from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/useMappedResults";
import Body from "@aptedge/lib-ui/src/features/AiAnswer/components/Body/Body";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  AnswerQuestion,
  AnswerAction
} from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import { animated, useSpring } from "@react-spring/web";
import classNames from "classnames";
import { FC, Fragment, useEffect, useRef } from "react";
import QuestionFooter from "../../../EmbeddedSearchV2/SearchResultPage/QuestionFooter";
import styles from "./MultiStepAnswer.module.scss";

interface Props {
  truncateLongAnswer?: boolean;
  resultsMap?: Map<string, ICompositeResult>;
  client?: SDKClient;
  aptEdgeBaseUrl?: string;
  questionWrapperComponent?: React.ElementType;
  showFirstQueryTitle?: boolean;
  questionQueryTitleClass?: string;
  showQuestionFooter?: boolean;
  scrollAnswerIntoView?: boolean;
}
const MultiStepAnswer: FC<Props> = ({
  truncateLongAnswer,
  resultsMap,
  client,
  aptEdgeBaseUrl,
  questionWrapperComponent,
  showFirstQueryTitle,
  questionQueryTitleClass,
  showQuestionFooter,
  scrollAnswerIntoView
}) => {
  const { mappedResults, totalSearchResults } = useMappedResults();
  const { answer, isAnswerLoaded } = useAppSelector((state) => state.answerGPT);
  const { answerStreamingStarted } = useAppSelector((state) => state.search);

  const renderNewSearches = (
    actions: AnswerAction[],
    index: number
  ): JSX.Element | undefined => {
    if (index === 0) {
      return;
    }

    const newSearches = actions[index - 1].newSearches;
    if (newSearches) {
      return <i>Searching for {newSearches.join(", ")}</i>;
    }
  };
  const style = useSpring({
    opacity: isAnswerLoaded ? 1 : 0,
    transform: isAnswerLoaded ? "translateX(0px)" : "translateX(40px)",
    config: { tension: 200, friction: 20, duration: 600 }
  });

  const Wrapper = questionWrapperComponent ?? Fragment;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollAnswerIntoView || !containerRef.current) return;

    containerRef.current.scrollIntoView({
      block: "start",
      behavior: answerStreamingStarted
        ? "smooth"
        : ("instant" as ScrollBehavior)
    });
  }, [answerStreamingStarted, scrollAnswerIntoView]);

  return (
    <>
      {answer.map((question: AnswerQuestion, i: number) => (
        <div
          key={i}
          className={classNames(styles.container, {
            [styles.lastAnswer]: i === answer.length - 1 && i > 0
          })}
          id={i === answer.length - 1 ? "lastAnswer" : ""}
          ref={i === answer.length - 1 ? containerRef : null}
        >
          {(showFirstQueryTitle || i > 0) && (
            <h2 className={questionQueryTitleClass}>{question.query}</h2>
          )}
          {question.actions.map((action: AnswerAction, j: number) => (
            <div key={j}>
              {j > 0 && (
                <>
                  <hr />
                  {renderNewSearches(question.actions, j)}
                </>
              )}
              {action.answer && (
                <Wrapper className={styles.wrapperStyle}>
                  <Body
                    answer={action.answer}
                    resultsMap={resultsMap ?? mappedResults}
                    totalResults={totalSearchResults}
                    truncateLongAnswer={truncateLongAnswer}
                    client={client}
                    aptEdgeBaseUrl={aptEdgeBaseUrl}
                    answerBodyClass={styles.answerBody}
                  />
                </Wrapper>
              )}
            </div>
          ))}
          {showQuestionFooter && isAnswerLoaded && (
            <animated.div style={style}>
              <QuestionFooter
                questionIndex={i}
                questionText={question.query}
                showFeedback={i === answer?.length - 1}
              />
            </animated.div>
          )}
        </div>
      ))}
    </>
  );
};

export default MultiStepAnswer;
