import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import styles from "./styles.module.scss";

type Props = {
  icon?: JSX.Element;
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
};

const Card: FC<Props> = ({ icon, title, children, className, onClick }) => {
  return (
    <div
      className={classNames(styles.cardContainer, className)}
      onClick={onClick}
    >
      {title &&
        (React.isValidElement(title) ? (
          title // Render the JSX directly if `title` is a React element
        ) : (
          <div className={styles.title}>
            {icon}
            {title}
          </div>
        ))}
      {children}
    </div>
  );
};

export default Card;
