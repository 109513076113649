import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { useModalRouter } from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import classNames from "classnames";
import React from "react";
import "./AutoBreadcrumbs.scss";
import { Page } from "./AutoLinkRoutes";

interface Props {
  onCancel: () => void;
}

const AutoBreadcrumbs: React.FC<Props> = ({ onCancel }) => {
  const router = useModalRouter();
  const currentRoute = router.currentRoute;

  const handleBack = (): void => {
    if (currentRoute === Page.CONDITIONS) {
      onCancel();
    } else if (currentRoute === Page.PREVIEW) {
      router.push(Page.CONDITIONS);
    } else if (!!currentRoute) {
      router.push(currentRoute - 1);
    }
  };

  const renderDescription = (): string | null => {
    switch (currentRoute) {
      case Page.CONDITIONS:
        return "When new tickets meet the following conditions, they will be added to this Edge automatically.";
      case Page.AUTO_REPLY:
        return "Add auto reply to the new auto linked tickets.";
      default:
        return null;
    }
  };

  if (currentRoute === Page.START) {
    return null;
  }

  return (
    <div className="auto-breadcrumbs">
      <div className="breadcrumb-list">
        <Icons.ChevronLeft onClick={handleBack} />
        <div
          className={classNames("breadcrumb-item", {
            active: router.currentRoute === Page.CONDITIONS
          })}
        >
          Auto Link Conditions
        </div>
        <Icons.ChevronRight />
        <div
          className={classNames("breadcrumb-item", {
            active: router.currentRoute === Page.PREVIEW
          })}
        >
          Preview
        </div>
      </div>
      <div className="description">{renderDescription()}</div>
    </div>
  );
};

export { AutoBreadcrumbs };
