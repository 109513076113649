import {
  PlatformSettings,
  PlatformTicket,
  PlatformUser
} from "@aptedge/lib-support-sdk";
import {
  TimedResponse,
  makeTimedResponse
} from "@aptedge/lib-ui/src/utils/request";
import { injectable } from "inversify";

@injectable()
abstract class SDKClient {
  static AUTH_HEADER = "Authorization";
  static USER_EMAIL_HEADER = "X-Aptedge-Zendesk-User-Email";
  static CLIENT_APP_TYPE_HEADER = "X-Aptedge-App-Client-Type";
  static CLIENT_APP_NAME = "X-Aptedge-App-Name";

  abstract getCurrentTicket(): Promise<PlatformTicket>;
  abstract getCurrentUser(): Promise<PlatformUser>;
  abstract getAppSettings(): Promise<PlatformSettings>;

  /**
   * Mechanism for all HTTP requests. Accepts the same args as the native `fetch()`
   * but calls `res.json()` internally to return a JSON response.
   */
  abstract fetch<Data>(input: string, init?: RequestInit): Promise<Data>;
  timedFetch<Data>(
    input: string,
    init?: RequestInit
  ): Promise<TimedResponse<Data>> {
    const startTime = Date.now();
    return this.fetch<Data>(input, init).then<TimedResponse<Data>>((data) =>
      makeTimedResponse(data, startTime)
    );
  }

  abstract navigateTo(...params: Array<string | number>): Promise<void>;
  abstract resize(size: { height: string }): Promise<void>;
}

export { SDKClient };
