import { InsightSearchSuggestionResponse } from "@aptedge/lib-ui/src/types/entities";
import { injectable } from "inversify";

@injectable()
abstract class InsightSearchSuggestions {
  abstract search(
    ticketId: string,
    autoSearchQueryTitle: string,
    format?: string
  ): Promise<InsightSearchSuggestionResponse>;
}

export { InsightSearchSuggestions };
