import { v4 as uuidv4 } from "uuid";
import { useAppDispatch } from "../redux/hook/hook";
import { updateAnswerId } from "../redux/reduxSlice/searchSlice";
import { QUERY_PARAMS } from "../types/entities";
import { useQueryParams } from "./useQueryParams";

export interface IUseSetParamsWithAnswerId {
  removeQueryParams: (key: QUERY_PARAMS) => void;
  setSearchQueryParam: (key: QUERY_PARAMS, value: string) => void;
}

export const useSetParamsWithAnswerId = (): IUseSetParamsWithAnswerId => {
  const { queryParams, history, removeQueryParams } = useQueryParams();
  const dispatch = useAppDispatch();

  /* This function is triggered when we apply filters in the case of AnswerGPT search to set the answerId with filters in the query parameters */
  const updateQueryParamsWithAnswerIdOnFilterChange = (): void => {
    const answerId = uuidv4();
    dispatch(updateAnswerId(answerId));
    queryParams.set(QUERY_PARAMS.ANSWER_ID, answerId);
    history.push(`?${queryParams.toString()}`);
  };

  const setSearchQueryParam = (key: string, value: string): void => {
    queryParams.set(key, value);
    updateQueryParamsWithAnswerIdOnFilterChange();
  };

  return {
    removeQueryParams,
    setSearchQueryParam
  };
};
