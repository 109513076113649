import { InsightAutoSearchResponse } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { SDKClient } from "../../sdkClient";
import { post } from "../../utils";
import { InsightAutoSearch } from "./insightAutoSearch";

@injectable()
class InsightAutoSearchProd implements InsightAutoSearch {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async search(
    ticketId: string,
    format = "qna--insight-auto-search"
  ): Promise<InsightAutoSearchResponse> {
    const url = "api/insights/auto-search";

    const payload = post({
      format: format,
      render_only: true,
      template_params: {
        TICKET_ID: String(ticketId)
      }
    });
    return this.client.fetch(url, payload);
  }
}

export { InsightAutoSearchProd };
