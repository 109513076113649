import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { ICustomerListItem } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { WebCacheKey } from "../../clients/cache";
import { fetchCustomersById } from "../../clients/Customers/fetchCustomersById";
import { fetchCustomersPaginated } from "../../clients/Customers/fetchCustomersPaginated";
import { unwrapPaginatedClient } from "../../clients/utils/paginated";
import { toSortString } from "../../clients/utils/sort";
import { MultiSelectAsync } from "../SelectAsync/MultiSelectAsync";
import { CUSTOMER_FILTER } from "./data";
import { CUST_TICKET_SORT } from "./sort";

const fetchCustomers = unwrapPaginatedClient(fetchCustomersPaginated);

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function CustomerFilter(props: Props): React.ReactElement {
  const sortBy = toSortString(CUST_TICKET_SORT);

  return (
    <FilterWrapper {...CUSTOMER_FILTER} {...props}>
      {({ values, onChange }) => (
        <MultiSelectAsync<ICustomerListItem, number>
          name="customer-filter"
          value={values?.map(Number)}
          valueKey={WebCacheKey.CUSTOMERS_BY_ID}
          valueLoader={
            fetchCustomersById as (
              ids: number[]
            ) => Promise<ICustomerListItem[]>
          }
          optionsKey={WebCacheKey.CUSTOMERS_LIST}
          getOptionAsValue={(customer) => customer.id}
          optionsLoader={(textSearch) =>
            fetchCustomers({
              name: textSearch,
              page: 1,
              perPage: 30,
              sortBy
            })
          }
          getOptionLabel={(c) => c.name}
          onChange={(customers) => onChange((customers || []).map(String))}
        />
      )}
    </FilterWrapper>
  );
}

export { CustomerFilter };
