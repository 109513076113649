import { FC, ReactNode, memo } from "react";
interface IProps {
  condition: boolean;
  children: ReactNode;
  fallback?: ReactNode;
}
const If: FC<IProps> = memo(({ children, condition, fallback = null }) => {
  if (condition) return <>{children}</>;
  return <>{fallback}</>;
});

export default If;
