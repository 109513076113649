import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import { TicketFields } from "@aptedge/lib-ui/src/types/entities";
import {
  ChangeEventHandler,
  RefObject,
  useEffect,
  useRef,
  useState
} from "react";
import { useMutation, useQuery } from "react-query";
import { WebCacheKey } from "../clients/cache";
import { fetchTicketFields } from "../clients/Tickets/fetchTicketFields";
import { updateTicketFields } from "../clients/Tickets/updateTicketFields";

type UseTicketFieldsProps = {
  isLoading: boolean;
  containerRef: RefObject<HTMLDivElement>;
  ticketFieldsRef: RefObject<HTMLDivElement>;
  showConfirmationModal: boolean;
  popupOpen: boolean;
  ticketFields: TicketFields[];
  textInput: string;
  isMaxTicketFieldSelected: boolean;
  selectedTicketFieldsCount: number;
  handleClear: () => void;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleTicketFieldSelection: (externalId: string) => void;
  togglePopup: () => void;
  handleSave: () => void;
  handleClose: () => void;
  handleUnsavedCancel: () => void;
};

export const getSortedTicketFields = (
  updatedTicketFields: TicketFields[]
): TicketFields[] => {
  const selected: TicketFields[] = [];
  const unselected: TicketFields[] = [];

  for (const ticketField of updatedTicketFields) {
    if (ticketField.isFilterable) {
      selected.push(ticketField);
    } else {
      unselected.push(ticketField);
    }
  }

  return [
    ...selected.sort((a, b) => a.name.localeCompare(b.name)),
    ...unselected.sort((a, b) => a.name.localeCompare(b.name))
  ];
};

function useTicketFields(): UseTicketFieldsProps {
  const [popupOpen, setPopupOpen] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [ticketFields, setTicketFields] = useState<TicketFields[]>([]);
  const [originalTicketFields, setOriginalTicketFields] = useState<
    TicketFields[]
  >([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const ticketFieldsRef = useRef<HTMLDivElement>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [hasCustomFieldsToggled, setHasCustomFieldsToggled] = useState(false);

  const ticketFieldsQuery = useQuery([WebCacheKey.TICKET_FIELDS], () =>
    fetchTicketFields()
  );

  const { data: ticketFieldsData, isLoading, refetch } = ticketFieldsQuery;

  const selectedTicketFieldsCount = ticketFields.filter(
    (fields) => fields.isFilterable === true
  ).length;

  const isMaxTicketFieldSelected = Boolean(selectedTicketFieldsCount === 25);

  const updateFields = useMutation(updateTicketFields);

  const handleTicketFieldSelection = (externalId: string): void => {
    if (ticketFields) {
      const newTicketFields = [...ticketFields];
      const updatedTicketFields = [];

      for (let i = 0; i < newTicketFields.length; i++) {
        const fields = newTicketFields[i];
        if (fields.externalId === externalId) {
          fields.isFilterable = !fields.isFilterable;
          setHasCustomFieldsToggled(true);
        }
        updatedTicketFields.push(fields);
      }

      const sortedTicketFields = getSortedTicketFields(updatedTicketFields);
      setTicketFields(sortedTicketFields);
    }
  };

  const resetFilterStates = (): void => {
    setShowConfirmationModal(false);
    setHasCustomFieldsToggled(false);
  };

  const handleSave = (): void => {
    resetFilterStates();
    updateFields.mutate({
      externalIds: ticketFields
        .filter((field) => field.isFilterable)
        .map((field) => field.externalId)
    });
  };

  const normalizeString = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, " ").trim();
  };

  // This function creates a quick lookup table using externalId and updates the list which will be displayed in the dropdown.
  // It handles scenarios where the user clears the input using the cancel icon or manually deletes the search text,
  // ensuring that the selected fields are sorted to the top of the list.
  const getUpdatedTicketFields = (): TicketFields[] => {
    const ticketFieldsMap = new Map();

    ticketFields.forEach((fields) => {
      ticketFieldsMap.set(fields.externalId, fields.isFilterable);
    });

    const updatedTicketFields = originalTicketFields.map((updatedFields) => {
      if (ticketFieldsMap.has(updatedFields.externalId)) {
        updatedFields.isFilterable =
          ticketFieldsMap.get(updatedFields.externalId) || 0;
      }
      return updatedFields;
    });

    return getSortedTicketFields(updatedTicketFields);
  };

  const updateTicketFieldsOnInputChange = (inputText: string): void => {
    let newTicketFields: TicketFields[] = [];
    if (Boolean(inputText)) {
      const filteredTicketFields = originalTicketFields.filter((fields) =>
        inputText
          .toLowerCase()
          .split(" ")
          .every((word) => normalizeString(fields.name).includes(word))
      );
      newTicketFields = getSortedTicketFields(filteredTicketFields);
    } else {
      newTicketFields = getUpdatedTicketFields();
    }
    setTicketFields(newTicketFields);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e): void => {
    setTextInput(e.target.value);
    updateTicketFieldsOnInputChange(e.target.value);
  };

  const handleClear = (): void => {
    setTextInput("");
    const updatedTicketFields = getUpdatedTicketFields();
    setTicketFields(updatedTicketFields);
  };

  const togglePopup = (): void => {
    setPopupOpen(!popupOpen);
  };

  const handleClose = (): void => {
    resetFilterStates();
    handleSave();
  };

  const handleUnsavedCancel = (): void => {
    refetch();
    resetFilterStates();
  };

  const handleRedirection = (): void => {
    if (hasCustomFieldsToggled) {
      setShowConfirmationModal(true);
    }
  };

  useOnMouseDownOutside(containerRef, () => setPopupOpen(false));
  useOnMouseDownOutside(ticketFieldsRef, handleRedirection);

  useEffect(() => {
    if (ticketFieldsData) {
      const updatedTicketFields = getSortedTicketFields(ticketFieldsData);
      setTicketFields(updatedTicketFields);
      setOriginalTicketFields(updatedTicketFields);
    }
  }, [ticketFieldsData]);

  return {
    isLoading,
    containerRef,
    ticketFieldsRef,
    showConfirmationModal,
    popupOpen,
    ticketFields,
    textInput,
    isMaxTicketFieldSelected,
    selectedTicketFieldsCount,
    handleClear,
    handleInputChange,
    handleTicketFieldSelection,
    togglePopup,
    handleSave,
    handleClose,
    handleUnsavedCancel
  };
}

export { useTicketFields };
