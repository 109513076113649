import { TicketDetailsType } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { SDKClient } from "../../sdkClient";
import { TicketDetails } from "./ticketDetails";

@injectable()
class TicketDetailsProd implements TicketDetails {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async getTicket(ticketId: string): Promise<TicketDetailsType> {
    const url = `api/tickets/${ticketId}/`;

    return this.client.fetch(url);
  }
}

export { TicketDetailsProd };
