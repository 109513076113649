import { IFlagSet } from "@aptedge/lib-ui/src/context/FlagsContext/types";
import { inject, injectable } from "inversify";
import { SDKClient } from "./sdkClient";

@injectable()
abstract class FeatureFlagsFetcher {
  abstract fetch(): Promise<IFlagSet>;
}

@injectable()
class FeatureFlagsFetcherMock implements FeatureFlagsFetcher {
  constructor(
    private mockFn: () => Promise<Partial<IFlagSet>> = () => Promise.resolve({})
  ) {}

  async fetch(): Promise<IFlagSet> {
    return this.mockFn() as Promise<IFlagSet>;
  }
}

@injectable()
class FeatureFlagsFetcherProd implements FeatureFlagsFetcher {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async fetch(): Promise<IFlagSet> {
    const url = `/api/feature-flags/`;

    return this.client.fetch(url);
  }
}

export {
  FeatureFlagsFetcher,
  FeatureFlagsFetcherMock,
  FeatureFlagsFetcherProd
};
