enum TooltipMessage {
  LINKED_PRODUCT_ISSUES = "These are Product Issues linked from Support Tickets",
  IMPACT_DATA = "Toggle which impact data shows in the pie chart",
  LINKED_TICKETS = "Support Tickets that are linked to one or more Product Issues",
  TIME_WINDOW = "Time Window is based on a ticket's closed date. You cannot adjust the window for open tickets.",
  SERVICE_CLOUD_NOT_CONNECTED = "Your Salesforce account is not connected with Service Cloud.",
  SALESFORCE_NOT_CONNECTED = "Connect to Salesforce first in order to use ServiceCloud as a ticket source.",
  ENTITY_SEARCH_OR_FILTER = "Searching or Filtering by all entities is not yet supported.",
  LINKED_EDGE = "Linked to an Edge",
  URL_REFERENCE = "Contains Jira reference",
  ACTIVE_FILTERS = "Active filters",
  EXPAND_PAGE = "Expand to page",
  COPY_APTEDGE_LINK = "Copy AptEdge ticket link",
  CUSTOMER_TICKET_COUNT = "This count pertains to both open and closed tickets",
  VIEW_IN_JIRA = "View in Jira",
  PREVIEW = "Preview",
  DOWNLOAD_FILE = "Download file",
  FRUSTRATION_SCORE = "Frustration score",
  TICKET_COUNT = "Ticket count",
  ARCHIVED = "Archived",
  STATUS = "Status",
  ADD_TICKETS = "Add tickets to the Edge",
  MIGRATE_TICKETS = "Migrate tickets to another Edge",
  EXPORT_TICKETS = "Export tickets from the Edge",
  BULK_REPLY = "Reply to all linked tickets",
  AVERAGE_TICKET_AGE = "Average ticket age"
}

export { TooltipMessage };
