import { RefObject, useEffect, useRef, useState } from "react";
export enum SCROLL_DIRECTION {
  Left = "Left",
  Right = "Right"
}
interface IUseScroll {
  scrollX: number;
  scrollEnd: boolean;
  handleScroll: (direction: string) => void;
  filterRef: RefObject<HTMLDivElement>;
}
const useScroll = (): IUseScroll => {
  const filterRef = useRef<HTMLDivElement>(null);

  const [scrollEnd, setScrollEnd] = useState<boolean>(false);
  const [scrollX, setScrollX] = useState<number>(0);

  const handleScroll = (direction: string): void => {
    if (!filterRef.current) return;
    if (direction === SCROLL_DIRECTION.Right) {
      filterRef.current.style.transform = `translateX(${scrollX - 70}px)`;
      setScrollX(scrollX - 70);
    }
    if (direction === SCROLL_DIRECTION.Left) {
      filterRef.current.style.transform = `translateX(${scrollX + 70}px)`;
      setScrollX(scrollX + 70);
    }
  };
  useEffect(() => {
    (() => {
      if (!filterRef.current) return;
      const isScrollEnd =
        Math.abs(scrollX) >
          filterRef.current.scrollWidth - filterRef.current.offsetWidth ||
        filterRef.current?.scrollWidth - filterRef.current.offsetWidth === 0;
      setScrollEnd(isScrollEnd);
    })();
    if (scrollX) {
      if (!filterRef.current) return;
      filterRef.current.style.transform = `translateX(${scrollX}px)`;
    }
  }, [scrollX]);
  return { scrollX, scrollEnd, handleScroll, filterRef };
};

export default useScroll;
