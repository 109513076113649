import { makeAnswerAPICallArgs } from "@aptedge/lib-ui/src/features/Search/hooks/useAiAnswerQuery";
import { useAiAnswerQueryQuestion } from "@aptedge/lib-ui/src/features/Search/hooks/useAiAnswerQueryQuestion";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { AnswerQuestion } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  GQADocSearchResult,
  SearchSource
} from "@aptedge/lib-ui/src/types/entities";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import { AnswerEngine } from "../clients/answerEngine";
import { useContainer } from "../context/ContainerContext";
import { useResize } from "../context/ResizeContext";

interface GlobalSearchProps {
  answer: AnswerQuestion[];
  isAnswerLoading: boolean;
  isSearchLoading: boolean;
  additionalInstruction: string;
  searchQuery: string;
  autoSearchActive: boolean;
  showFeedback: boolean;
  searchSourceType: string;
  answerId: string;
}

const searchSourceType = SearchSource.SEARCH;

function useSupportGlobalSearch(enabled: boolean): GlobalSearchProps {
  const {
    searchQuery,
    autoSearchActive,
    searchResults,
    isSearchLoading,
    answerId
  } = useAppSelector((state) => state.search);
  const { ticket } = useAppSelector((state) => state.cookedTicket);
  const { ticketId } = ticket;

  const { additionalInstruction, answer, isAnswerLoading } = useAppSelector(
    (state) => state.answerGPT
  );

  const container = useContainer();
  const answerEngine = container.get(AnswerEngine);

  const makeAnswerAPICall = (
    args: makeAnswerAPICallArgs
  ): Promise<TimedResponse<GQADocSearchResult>> => {
    args.dropExternalId = ticketId;
    return answerEngine.search(args);
  };

  useAiAnswerQueryQuestion(enabled, makeAnswerAPICall);

  useResize({
    searchResultsLoading: isSearchLoading
  });

  const showFeedback = Boolean(
    (searchQuery &&
      !isSearchLoading &&
      searchResults &&
      searchResults.length > 0) ||
      answer
  );

  return {
    answer,
    isAnswerLoading,
    isSearchLoading,
    additionalInstruction,
    searchQuery,
    autoSearchActive,
    showFeedback,
    searchSourceType,
    answerId
  };
}

export { useSupportGlobalSearch };
