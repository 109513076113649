import { InsightTicketSummaryResponse } from "@aptedge/lib-ui/src/types/entities";
import { injectable } from "inversify";

@injectable()
abstract class InsightTicketSummary {
  abstract search(
    ticketId: string,
    format?: string
  ): Promise<InsightTicketSummaryResponse>;
}

export { InsightTicketSummary };
