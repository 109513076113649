import { GeneratedKnowledgeResult } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { SDKClient } from "./sdkClient";
import { TicketArticles } from "./ticketArticles";

@injectable()
class TicketArticlesProd implements TicketArticles {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async search(ticketId: number): Promise<GeneratedKnowledgeResult[]> {
    const url = `/api/generated_knowledge/?ticket_id=${ticketId}`;

    return this.client.fetch<GeneratedKnowledgeResult[]>(url);
  }
}

export { TicketArticlesProd };
