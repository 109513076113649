import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import {
  AptAnswersFilterRule,
  AptAnswersFilterRuleUpdate
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { FC, useState } from "react";
import styles from "./FilterRulesItemValueInput.module.scss";

interface FilterRulesItemValueInputProps {
  rule: AptAnswersFilterRule;
  idx: number;
  handleRuleUpdate: (o: AptAnswersFilterRuleUpdate) => void;
  handleDeleteRule: (idx: number) => void;
  isDisabled: boolean;
  isTop: boolean;
  isBottom: boolean;
}

const FilterRulesItemValueInput: FC<FilterRulesItemValueInputProps> = ({
  rule,
  idx,
  handleRuleUpdate,
  handleDeleteRule,
  isDisabled,
  isTop,
  isBottom
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    handleRuleUpdate({ idx, key: "value", value: e.target.value });
  };

  const handleFocus = (): void => {
    setIsFocused(true);
  };

  const handleBlur = (): void => {
    setIsFocused(false);
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    handleDeleteRule(idx);
  };

  const toggleFocus = (e: React.MouseEvent<HTMLTextAreaElement>): void => {
    e.stopPropagation();
    setIsFocused(!isFocused);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.inputTop]: isTop,
        [styles.inputBottom]: isBottom
      })}
    >
      {!isFocused ? (
        <input
          className={classNames({
            [styles.inputTop]: isTop,
            [styles.inputBottom]: isBottom,
            [styles.inputDisabled]: isDisabled
          })}
          placeholder="Enter value here"
          value={rule.value}
          disabled={isDisabled}
          readOnly
          onFocus={handleFocus}
          onClick={(e) => e.stopPropagation()}
        />
      ) : (
        <textarea
          value={rule.value}
          onChange={handleInputChange}
          onBlur={handleBlur}
          autoFocus
          rows={5}
          onClick={toggleFocus}
        />
      )}
      <button onClick={handleDelete}>
        <AeIcons.Trash />
      </button>
    </div>
  );
};

export { FilterRulesItemValueInput };
