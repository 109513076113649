import type {
  CookedTicket,
  EdgeSupport
} from "@aptedge/lib-ui/src/types/entities";
import { injectable } from "inversify";

@injectable()
abstract class EdgeTicketAdder {
  abstract add(args: {
    edgeId: number;
    ticket: CookedTicket;
    patch?: Partial<Omit<CookedTicket, "id" | "externalId">>;
  }): Promise<[CookedTicket, EdgeSupport]>;
}

export { EdgeTicketAdder };
