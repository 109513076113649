import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import {
  IBusinessDataSource,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import WithLoading from "../../../components/WithLoading/WithLoading";
import {
  useConfluenceCloudArticleDataSource,
  useConfluenceOnPremArticleDataSource,
  UseOAuthDataSourceResult,
  useSalesforceKnowledgeDataSource,
  useZendeskKBDataSource
} from "../../../hooks/clients/useDataSource";
import { ConfluenceCloudConfig } from "./ConfluenceCloudConfig";
import { ConfluenceOnPremConfig } from "./ConfluenceOnPremConfig";
import { Integrations } from "./DynamicIntegrations/DynamicIntegrations";
import { NotionConfig } from "./Notion/NotionConfig";
import { SalesforceKnowledgeConfig } from "./SalesforceKnowledgeConfig";
import { SNowConfig } from "./ServiceNow/SNowConfig";
import { SharepointConfig } from "./SharepointConfig";
import { SharepointDriveConfig } from "./SharepointDriveConfig";
import { ZendeskKBConfig } from "./ZendeskKBConfig";

interface Props {
  businessResource: UseOAuthDataSourceResult<IBusinessDataSource>;
}

const ArticleSourceConfig: React.FC<Props> = (props) => {
  const { flags } = useFlags();

  const confluenceCloudArticleResource = useConfluenceCloudArticleDataSource();
  const confluenceOnPremArticleResource = useConfluenceOnPremArticleDataSource();
  const zendeskKBArticleResource = useZendeskKBDataSource();
  const salesforceKnowledgeResource = useSalesforceKnowledgeDataSource();

  const confluenceCloudArticleSource =
    confluenceCloudArticleResource.dataSource || null;
  const zendeskKBArticleSource = zendeskKBArticleResource.dataSource || null;
  const isLoading = confluenceCloudArticleResource.isLoading;
  const isZendeskKbLoading = zendeskKBArticleResource?.isLoading;
  const zendeskKbError = zendeskKBArticleResource?.error;
  const refetchZendeskKBtap = zendeskKBArticleResource?.reload;
  const salesforceKnowledgeArticleSource =
    salesforceKnowledgeResource?.dataSource ?? null;
  const refeshSalesforceKnowlege = salesforceKnowledgeResource.reload;
  const isSalesforceKnowledgeLoading = salesforceKnowledgeResource?.isLoading;
  const isSalesforceKnowledgeConnectActive =
    props.businessResource?.dataSource?.oauthStatus ===
      OAuthStatus.AUTHENTICATED ?? false;
  const salesforceKnowledgeError = salesforceKnowledgeResource?.error;

  return (
    <section className="mb-5 mt-5">
      <div className="row">
        <WithLoading isLoading={isLoading} fallback={<Spinner />}>
          <div className="col-md-5 p-2 pr-3">
            <h4 className="text-uppercase">Knowledge</h4>
            <p>Connect AptEdge to your Knowledge System.</p>
          </div>
          <div className="col-md-7 p-2 pl-3">
            <ConfluenceCloudConfig
              articleIssueSource={confluenceCloudArticleSource}
            />
            <ConfluenceOnPremConfig
              dataSource={confluenceOnPremArticleResource}
            />
            {flags.sharepoint && <SharepointConfig />}
            <SharepointDriveConfig />
            <ZendeskKBConfig
              refetchZendeskKBtap={refetchZendeskKBtap}
              articleIssueSource={zendeskKBArticleSource}
              isLoading={isZendeskKbLoading}
              error={zendeskKbError}
            />
            {flags.serviceNowKnowledge && <SNowConfig />}

            {isSalesforceKnowledgeConnectActive && (
              <SalesforceKnowledgeConfig
                salesforceKnowledgeResource={salesforceKnowledgeArticleSource}
                refeshSalesforceKnowlege={refeshSalesforceKnowlege}
                businessResource={props?.businessResource?.dataSource ?? null}
                isLoading={isSalesforceKnowledgeLoading}
                error={salesforceKnowledgeError}
              />
            )}
            <NotionConfig />
            <Integrations />
          </div>
        </WithLoading>
      </div>
      <hr className="mt-4" />
    </section>
  );
};

export { ArticleSourceConfig };
