import { IRichTextContent } from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import type {
  CookedTicket,
  EdgeSupport
} from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { EdgeCreator } from "./edgeCreator";
import { EdgeTicketAdder } from "./edgeTicketAdder";
import { SDKClient } from "./sdkClient";
import { post } from "./utils";

@injectable()
class EdgeCreatorProd implements EdgeCreator {
  constructor(
    @inject(SDKClient) private client: SDKClient,
    @inject(EdgeTicketAdder) private ticketAdder: EdgeTicketAdder
  ) {}

  async create({
    name,
    ticket,
    richSummary,
    ticketPatch
  }: {
    name: string;
    ticket: CookedTicket;
    richSummary?: IRichTextContent;
    ticketPatch?: Partial<Omit<CookedTicket, "id" | "externalId">>;
  }): Promise<[CookedTicket, EdgeSupport]> {
    return this.client
      .fetch<EdgeSupport>("/api/edges/", post({ name, richSummary }))
      .then((e) =>
        this.ticketAdder.add({
          edgeId: e.id,
          ticket: ticket,
          patch: ticketPatch
        })
      );
  }
}

export { EdgeCreatorProd };
