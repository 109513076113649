import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useAppDispatch } from "../../redux/hook/hook";
import { updateSelectedReviewer } from "../../redux/reduxSlice/kbGenSlice";
import { IUserListing } from "../../types/entities";

interface IUseModal {
  modalRef: RefObject<HTMLDivElement> | null;
  modalVisibility: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
}

interface UseModalProps {
  closeModalOnClickOutside?: boolean;
  beforeClose?: () => void;
}

const useModal = ({
  closeModalOnClickOutside = true,
  beforeClose
}: UseModalProps): IUseModal => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalVisibility, closeModal] = useState(false);

  const dispatch = useAppDispatch();

  const handleCloseModal = useCallback((): void => {
    if (beforeClose instanceof Function) {
      beforeClose();
    } else {
      closeModal(false);
    }
  }, [beforeClose]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        closeModalOnClickOutside
      ) {
        dispatch(updateSelectedReviewer({} as IUserListing));
        handleCloseModal();
      }
    };

    if (modalVisibility) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalVisibility, closeModalOnClickOutside, handleCloseModal, dispatch]);

  return { modalRef, modalVisibility, closeModal };
};

export default useModal;
