import {
  SearchTypeSupport,
  SuggestionsResultsSupport
} from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { SDKClient } from "./sdkClient";
import { SearchSuggestions } from "./searchSuggestions";
import { toQueryString } from "./utils";

@injectable()
class SearchSuggestionsProd implements SearchSuggestions {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async search(
    textQuery: string,
    type?: SearchTypeSupport
  ): Promise<SuggestionsResultsSupport> {
    let url = "";
    const query = toQueryString({ q: textQuery });
    if (!!type && type !== SearchTypeSupport.ALL) {
      url = `/api/suggest/search/${type}/?${query}`;
    } else {
      url = `/api/suggest/search/?${query}`;
    }
    return this.client.fetch(url);
  }
}

export { SearchSuggestionsProd };
