import merge from "lodash/merge";
import React, { createContext, useContext, useMemo } from "react";
import { IFlagSet } from "./types";

// Create a new type for all keys, matching the exact value in LD.
export type FlagSet = {
  "sentiment-score"?: boolean;
  "edge-resources"?: boolean;
  "edge-bulk-reply"?: boolean;
  sharepoint?: boolean;
  "answergpt-model-settings-ui"?: boolean;
  "service-now-knowledge"?: boolean;
  "invite-user"?: boolean;
  "insights-support-app-kb-draft"?: boolean;
  "self-service"?: boolean;
  "semantic-search-hybrid-max-federated-hits"?: number;
  "css-analytics"?: boolean;
  "session-replay"?: boolean;
  "feedback-options"?: {
    "search-feedback-labels"?: string;
    "ticket-summary-feedback-labels"?: string;
    "kb-gen-feedback-labels"?: string;
    "answer-feedback-labels"?: string;
  };
  "insights-closed-ticket-statuses"?: string[];
  "css-config-page"?: boolean;
  "css-internal-config"?: boolean;
  "feedback-title"?: {
    "search-feedback-title"?: string;
    "ticket-summary-feedback-title"?: string;
    "kb-gen-feedback-title"?: string;
    "answer-feedback-title"?: string;
    "default-feedback-title"?: string;
  };
  "answergpt-ui-v2"?: boolean;
  "kb-gen-publish-process"?: boolean;
  "f1-app-in-web-app"?: boolean;
  "knowledge-creation-web-app"?: boolean;
  "f1-session-replay-preview"?: boolean;
  "ticket-fields-settings-ui"?: boolean;
};

const queryParams = new URLSearchParams(window.location.search);
const isServiceNow = !!queryParams.get("snowInstance");

const flagsStatusByEnvironment = (
  status: boolean,
  localEnvStatus = true
): boolean => {
  if (window.location.href.includes("localhost:")) return localEnvStatus;
  return status;
};

const flagsStatusByEnvironmentStr = (
  status: string,
  localEnvStatus?: string
): string => {
  if (localEnvStatus && window.location.href.includes("localhost:")) {
    return localEnvStatus ?? status;
  }
  return status;
};

const flagsStatusByEnvironmentStrArr = (
  status: string[],
  localEnvStatus?: string[]
): string[] => {
  if (localEnvStatus && window.location.href.includes("localhost:")) {
    return localEnvStatus ?? status;
  }
  return status;
};

/**
 * Function to ensure we always have a valid object with correct keys
 * in the UI just in case the schema changes on LD.com.
 *
 * New flags MUST be added here to be used in the app.
 */

function cleanseFlags(flags: FlagSet): IFlagSet {
  return {
    edgeBulkReply: flags["edge-bulk-reply"] ?? flagsStatusByEnvironment(false),
    serviceNowKnowledge: flags["service-now-knowledge"] ?? false,
    sharepoint: flags["sharepoint"] ?? flagsStatusByEnvironment(false),
    answergptModelSettingsUI:
      flags["answergpt-model-settings-ui"] ?? flagsStatusByEnvironment(false),
    searchOnlySupportApp: isServiceNow ? true : false,
    inviteUser: flags["invite-user"] ?? flagsStatusByEnvironment(false),
    sentimentScore: flags["sentiment-score"] ?? flagsStatusByEnvironment(false),

    insightsKBDraft:
      flags["insights-support-app-kb-draft"] ?? flagsStatusByEnvironment(false),
    selfService:
      flags["self-service"] ?? flagsStatusByEnvironment(false, false),
    cssConfigPage: flags["css-config-page"] ?? flagsStatusByEnvironment(false),
    federatedSearch:
      (flags["semantic-search-hybrid-max-federated-hits"] ?? 0) > 0,
    cssAnalytics: flags["css-analytics"] ?? flagsStatusByEnvironment(false),
    cssInternalConfig:
      flags["css-internal-config"] ?? flagsStatusByEnvironment(false),
    feedbackOptions: {
      searchFeedbackLabels:
        (flags["feedback-options"] &&
          flags["feedback-options"]["search-feedback-labels"]) ??
        flagsStatusByEnvironmentStr(
          "Factually incorrect,No relevant results,Result for wrong product,Style/tone,Too long to respond,Other"
        ),
      ticketSummaryFeedbackLabels:
        (flags["feedback-options"] &&
          flags["feedback-options"]["ticket-summary-feedback-labels"]) ??
        flagsStatusByEnvironmentStr(
          "Factually incorrect,No relevant results,Result for wrong product,Style/tone,Too long to respond,Other"
        ),
      kbGenFeedbackLabels:
        (flags["feedback-options"] &&
          flags["feedback-options"]["kb-gen-feedback-labels"]) ??
        flagsStatusByEnvironmentStr(
          "Factually incorrect,No relevant results,Result for wrong product,Style/tone,Too long to respond,Other"
        ),
      answerFeedbackLabels:
        (flags["feedback-options"] &&
          flags["feedback-options"]["answer-feedback-labels"]) ??
        flagsStatusByEnvironmentStr(
          "Factually incorrect,No relevant results,Result for wrong product,Style/tone,Too long to respond,Other"
        )
    },
    insightsClosedTicketStatuses:
      flags["insights-closed-ticket-statuses"] ??
      flagsStatusByEnvironmentStrArr(
        ["closed", "solved"],
        ["closed", "solved"]
      ),
    feedbackTitle: {
      searchFeedbackTitle:
        (flags["feedback-title"] &&
          flags["feedback-title"]["search-feedback-title"]) ??
        flagsStatusByEnvironmentStr("Is this result helpful?"),
      ticketSummaryFeedbackTitle:
        (flags["feedback-title"] &&
          flags["feedback-title"]["ticket-summary-feedback-title"]) ??
        flagsStatusByEnvironmentStr("Is this summary helpful?"),
      kbGenFeedbackTitle:
        (flags["feedback-title"] &&
          flags["feedback-title"]["kb-gen-feedback-title"]) ??
        flagsStatusByEnvironmentStr("Are these articles helpful?"),
      answerFeedbackTitle:
        (flags["feedback-title"] &&
          flags["feedback-title"]["answer-feedback-title"]) ??
        flagsStatusByEnvironmentStr("Is this result helpful?"),
      defaultFeedbackTitle:
        (flags["feedback-title"] &&
          flags["feedback-title"]["default-feedback-title"]) ??
        flagsStatusByEnvironmentStr("Are these results helpful?")
    },
    kbGenPublishProcess:
      flags["kb-gen-publish-process"] ?? flagsStatusByEnvironment(false, false),
    sessionReplay: flags["session-replay"] ?? flagsStatusByEnvironment(false),
    f1AppInWebApp:
      flags["f1-app-in-web-app"] ?? flagsStatusByEnvironment(false, false),
    knowledgeCreation:
      flags["knowledge-creation-web-app"] ??
      flagsStatusByEnvironment(false, false),
    f1SessionReplayPreview:
      flags["f1-session-replay-preview"] ?? flagsStatusByEnvironment(false),
    ticketFieldsSettingsUi:
      flags["ticket-fields-settings-ui"] ??
      flagsStatusByEnvironment(false, false)
  };
}

type IContext = {
  flags: IFlagSet;
  isLoading: boolean;
};

const initialState: IContext = {
  flags: cleanseFlags({}),
  isLoading: true
};

const FlagsContext = createContext<IContext>(initialState);

interface Props {
  flags?: FlagSet;
  isLoading: boolean;
}

const FlagsProvider: React.FC<Props> = (props) => {
  const { flags, isLoading, children } = props;

  const resolvedState = useMemo(
    () => ({
      flags: merge({}, cleanseFlags(flags ?? {})),
      isLoading
    }),
    [flags, isLoading]
  );

  return (
    <FlagsContext.Provider value={resolvedState}>
      {children}
    </FlagsContext.Provider>
  );
};

const useFlags = (): IContext => useContext(FlagsContext);

const MockFlagsProvider: React.FunctionComponent<{
  flags: FlagSet;
}> = ({ flags, children }) => {
  const mockState = {
    ...initialState,
    flags: cleanseFlags(flags),
    isLoading: false
  };

  return (
    <FlagsContext.Provider value={mockState}>{children}</FlagsContext.Provider>
  );
};

export { FlagsProvider, MockFlagsProvider, useFlags };
