import type { EdgeSupport } from "@aptedge/lib-ui/src/types/entities";
import { inject, injectable } from "inversify";
import { EdgeFetcher } from "./edgeFetcher";
import { SDKClient } from "./sdkClient";

@injectable()
class EdgeFetcherProd implements EdgeFetcher {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async fetch(ticketId: number): Promise<EdgeSupport[]> {
    const url = `/api/tickets/${ticketId}/edges/`;

    return this.client.fetch<EdgeSupport[]>(url);
  }
}

export { EdgeFetcherProd };
