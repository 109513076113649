import { logger } from "@aptedge/lib-ui/src/utils/logger";
import { injectable } from "inversify";
import { request } from "./request";
import { SDKClient } from "./sdkClient";
import { SDKClientMock } from "./sdkClientMock";

function isLocal(): boolean {
  return window.location.hostname === "localhost";
}

function getBackendUrl(instanceName: string): string {
  if (isLocal()) return "http://localhost:8080";
  return `https://${instanceName}.aptedge.io`;
}

@injectable()
class SDKClientDirect extends SDKClientMock {
  token = "";
  instanceName = "";
  constructor() {
    super();
    this.token = process.env.REACT_APP_CLIENT_TOKEN || "";
    window.addEventListener("message", (event) => {
      if (event.data.token) {
        this.token = event.data.token;
      }
      if (event.data.instanceName) {
        this.instanceName = event.data.instanceName;
      }
    });
  }

  async fetch<Data>(apiPath: string, init?: RequestInit): Promise<Data> {
    const url = `${getBackendUrl(this.instanceName)}/${apiPath.replace(
      /^\//g,
      ""
    )}`;
    const options: RequestInit = {
      ...init,
      referrerPolicy: "no-referrer",
      headers: {
        ...init?.headers,
        [SDKClient.AUTH_HEADER]: `Bearer ${this.token}`,
        [SDKClient.CLIENT_APP_TYPE_HEADER]: "direct",
        [SDKClient.USER_EMAIL_HEADER]: "self-service@aptedge.io"
      }
    };

    return request(url, options);
  }
  async rawFetch(apiPath: string, init?: RequestInit): Promise<Response> {
    const url = `${apiPath.replace(/^\//g, "")}`;
    const options: RequestInit = {
      ...init,
      referrerPolicy: "no-referrer",
      headers: {
        ...init?.headers,
        [SDKClient.CLIENT_APP_TYPE_HEADER]: "direct",
        [SDKClient.USER_EMAIL_HEADER]: "self-service@aptedge.io"
      }
    };

    return fetch(url, options);
  }

  async navigateTo(...params: Array<string | number>): Promise<void> {
    // no-op, just log.
    logger.log("would navigate to", ...params);
  }

  async resize(size: { height: string }): Promise<void> {
    // no-op, just log.
    logger.log("would resize to", size);
  }
}

export { SDKClientDirect };
