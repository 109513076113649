import { makeAnswerAPICallArgs } from "@aptedge/lib-ui/src/features/Search/hooks/useAiAnswerQuery";
import {
  GQADocSearchResult,
  ISearchFilter
} from "@aptedge/lib-ui/src/types/entities";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import { inject, injectable } from "inversify";
import { AnswerEngine } from "./answerEngine";
import { SDKClient } from "./sdkClient";

@injectable()
class AnswerEngineProd implements AnswerEngine {
  constructor(@inject(SDKClient) private client: SDKClient) {}

  async search(
    args: makeAnswerAPICallArgs
  ): Promise<TimedResponse<GQADocSearchResult>> {
    const { format, filter } = args;
    const prefixedFormat = `qna--${format}`;
    const newArgs = {
      additional_instructions: args.additionalInstructions,
      answer_id: args.answerId,
      insight: args.answerMode,
      answer_steps: args.answerSteps,
      first_request: args.firstRequest,
      format: prefixedFormat,
      group_type: filter !== ISearchFilter.ALL ? filter : undefined,
      limit: args.limit,
      page: 1,
      page_html: args.pageHtml,
      previous_page_html: args.previousPageHtml,
      next_step: args.nextStep,
      plan: args.plan,
      per_page: args.perPage,
      q: args.q,
      sort: args.sort,
      sources: args.filterSubType,
      previews: args.previews,
      ticket_search_window: "all",
      drop_external_ids: args.dropExternalId,
      v3_api: true
    };

    const operation = args.q ? "new" : "update";
    const url = `/api/search2/${operation}/`;

    return this.client.timedFetch(url, {
      body: JSON.stringify(newArgs),
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
}

export { AnswerEngineProd };
