/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FrameEventGetTicket,
  FrameEventGetUser,
  FrameEventIframeReady,
  FrameEventReady,
  FrameEventResize,
  FrameEventRouteTo
} from "../bus/events";
import { Bus, BusEvents } from "../bus/framebus";
import { PayloadType } from "../bus/types";
import {
  PlatformSettings,
  PlatformTicket,
  PlatformUser
} from "../types/platform";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const bus: Bus = require("framebus");

type PromiseRecord = {
  timeoutId: number;
  reject: (reason: Error) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolve: (data: any) => void;
};

// 60sec
const PROMISE_TIMEOUT_LONG = 60 * 1000;
const noop = (): void => {
  /** no-op */
};

class Deferred {
  public promise: Promise<void>;
  public reject: () => void = noop;
  public resolve: () => void = noop;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

class SalesforceSDK {
  private bus: Bus;
  private deferred: Deferred;
  private pendingPromises: Map<number, PromiseRecord> = new Map();
  private settings: PlatformSettings | undefined;

  constructor() {
    const { appId } = queryParameters(window.location.search);

    this.deferred = new Deferred();
    this.bus = bus.target({ channel: appId });

    this.bus.on<FrameEventReady>("platform.ready", (data) => {
      this.settings = data.settings;
      this.deferred.resolve();
    });

    this.bus.emit<FrameEventIframeReady>("iframe.ready", {}, (data) => {
      this.settings = data.settings;
      this.deferred.resolve();
    });
  }

  async metadata(): Promise<PlatformSettings> {
    if (this.settings) return Promise.resolve(this.settings);

    return this.dispatch<FrameEventIframeReady>("iframe.ready", {}).then(
      (res) => res.settings
    );
  }

  async getTicket(): Promise<PlatformTicket> {
    return this.dispatch<FrameEventGetTicket>("iframe.get.ticket", {}).then(
      (res) => res.ticket
    );
  }

  async getUser(): Promise<PlatformUser> {
    return this.dispatch<FrameEventGetUser>("iframe.get.user", {}).then(
      (res) => res.user
    );
  }

  async resize(size: { height: string }): Promise<{ ok: boolean }> {
    return this.dispatch<FrameEventResize>("iframe.invoke.resize", size);
  }

  async routeTo(...paths: Array<string | number>): Promise<{ ok: boolean }> {
    return this.dispatch<FrameEventRouteTo>("iframe.invoke.routeTo", {
      paths
    });
  }

  private async dispatch<E extends BusEvents>(
    event: E["eventName"],
    data: PayloadType<E>
  ): Promise<ReturnType<E["createReply"]>> {
    if (!this.bus) {
      console.error("No framebus found.");
      return Promise.reject("No framebus found.");
    }

    const id = nextIdFor("dispatch");
    let timeoutId: number;
    const MAX_RETRIES = 3;
    // Chain everything off the "onReady" iframe-salesforce handshake so we
    // don't send requests into the VOID.
    const promise = this.deferred.promise.then(
      () =>
        new Promise<ReturnType<E["createReply"]>>((resolve, reject) => {
          let attempts = 0;

          const retry = (): void => {
            if (attempts < MAX_RETRIES) {
              timeoutId = window.setTimeout(() => {
                console.log(
                  `Dispatch request timeout - ${event}. Retry attempt: ${
                    attempts + 1
                  }`
                );
                attempts++;
                retry();
              }, PROMISE_TIMEOUT_LONG);
            } else {
              console.log(
                `Dispatch request failed after ${MAX_RETRIES} attempts - ${event}`
              );
              reject(
                `Dispatch request failed after ${MAX_RETRIES} attempts - ${event}`
              );
              this.pendingPromises.delete(id);
            }
          };

          this.pendingPromises.set(id, { reject, resolve, timeoutId });

          this.bus.emit<E>(event, data, (replyMsg) => {
            resolve(replyMsg);
          });

          retry();
        })
    );

    return promise
      .then((val) => {
        this.cleanupPromise(id);
        return val;
      })
      .catch((err) => {
        this.cleanupPromise(id);
        console.log(err);
        return err;
      });
  }

  private cleanupPromise(id: number): void {
    const pending = this.pendingPromises.get(id);
    this.pendingPromises.delete(id);

    if (pending) {
      clearTimeout(pending.timeoutId);
    }
  }
}

const ids: { [key: string]: number } = {};

function nextIdFor(name: string): number {
  if (isNaN(ids[name])) {
    ids[name] = 0;
  }
  return ++ids[name];
}

function decode(s?: string): string {
  return decodeURIComponent((s || "").replace(/\+/g, " "));
}

function queryParameters(
  queryString?: string
): { [key: string]: string | undefined } {
  queryString = queryString || "";

  const result: { [key: string]: string | undefined } = {};
  let keyAndValue, key, value;

  if (queryString.search(/\?|#/) === 0) {
    queryString = queryString.slice(1);
  }

  if (queryString.length === 0) {
    return result;
  }

  const keyValuePairs = queryString.split("&");

  for (let i = 0; i < keyValuePairs.length; i++) {
    keyAndValue = keyValuePairs[i].split("=");
    key = decode(keyAndValue[0]);
    value = decode(keyAndValue[1]) || "";
    result[key] = value;
  }

  return result;
}

export { SalesforceSDK };
