import type { EdgeSupport } from "@aptedge/lib-ui/src/types/entities";
import { injectable } from "inversify";

@injectable()
abstract class EdgeTicketRemover {
  abstract remove(args: {
    edgeId: number;
    cookedTicketId: number;
  }): Promise<EdgeSupport>;
}

export { EdgeTicketRemover };
