import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { LightTooltip } from "@aptedge/lib-ui/src/components/Tooltip/LightTooltip";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import useModal from "@aptedge/lib-ui/src/hooks/useModal/useModal";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateSelectedReviewer } from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  ArticleStatus,
  GeneratedKnowledgeTicketId,
  ArticleStatusBtn,
  ArticleReviewer,
  ArticleReviewStatus,
  IUserListing
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import truncate from "lodash/truncate";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { WebCacheKey } from "../../clients/cache";
import { deleteArticle } from "../../clients/GeneratedKnowledge/deleteArticle";
import { editArticle } from "../../clients/GeneratedKnowledge/editArticle";
import { ARTICLE_STATUS_BUTTON } from "../../components/ArticleFilter/ArticleFilter";
import styles from "./ArticlesTableItem.module.scss";
import { EditArticleModal } from "./EditArticleModal";
import { ViewArticleModal } from "./ViewArticleModal";

interface ArticlesTableItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  article: any;
  idx: number;
}

interface SaveArticleArgs {
  title: string;
  body: string;
}

const triggerEvent = (
  eventName: string,
  ticketId: string | number,
  articleId: string,
  articleTitle: string,
  articleBody?: string
): void => {
  dataLayerPush({
    event: eventName,
    data: {
      article_id: articleId,
      ticket_id: ticketId,
      article_title: articleTitle,
      article_body: articleBody,
      article_status: ArticleStatus.PUBLISHED
    }
  });
};

const ArticlesTableItem: FC<ArticlesTableItemProps> = ({ article, idx }) => {
  const { flags } = useFlags();
  const { clearAllQueryParams } = useQueryParams();

  const [hasChanges, setHasChanges] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalType, setModalType] = useState<"edit" | "view" | null>(null);

  const dispatch = useAppDispatch();

  const handleBeforeClose = (): void => {
    if (hasChanges) {
      setShowConfirmationModal(true);
    } else {
      closeModal(false);
    }
  };

  const checkArticleContentChanged = (
    isArticleContentChanged: boolean
  ): void => {
    setHasChanges(isArticleContentChanged);
  };

  const handleUnsavedChanges = (): void => {
    setShowConfirmationModal(false);
  };

  const { modalVisibility, closeModal, modalRef } = useModal({
    beforeClose: handleBeforeClose
  });

  const isEven = idx % 2 === 0;
  const { ticket_id: ticketId, displayId } =
    article.ticketIds?.[0] || ({} as GeneratedKnowledgeTicketId);
  const articleStatusAndIcon =
    ARTICLE_STATUS_BUTTON.find(
      (btn) => btn.status.toLowerCase() === article.status.toLowerCase()
    ) || ({} as ArticleStatusBtn);

  const queryClient = useQueryClient();

  const editArticleMutation = useMutation(editArticle, {
    onSuccess: () => {
      closeModal(false);
      queryClient.invalidateQueries(WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED);
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE,
        article.id
      ]);
    }
  });

  const deleteArticleMutation = useMutation(deleteArticle, {
    onSuccess: () => {
      queryClient.invalidateQueries(WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED);
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE,
        article.id
      ]);
    }
  });

  const handleViewArticle = (isEntireRow: boolean): void => {
    if (!flags.kbGenPublishProcess && isEntireRow) return;
    triggerEvent(
      GTM_EVENTS.GTM_VIEW_KB_GEN_ARTICLE,
      ticketId,
      article.id,
      article?.title || ""
    );
    clearAllQueryParams();
    setModalType("view");
    closeModal(true);
  };

  const handleEditArticleOpen = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (flags.kbGenPublishProcess) {
      e.stopPropagation();
      return;
    }
    triggerEvent(
      GTM_EVENTS.GTM_EDIT_KB_GEN_ARTICLE,
      ticketId,
      article.id,
      article?.title || ""
    );
    setModalType("edit");
    closeModal(true);
  };

  const handleCloseModal = (): void => {
    clearAllQueryParams();
    setModalType(null);
    closeModal(false);
    setHasChanges(false);
    setShowConfirmationModal(false);
    dispatch(updateSelectedReviewer({} as IUserListing));
  };

  const handleSaveChanges = ({ title, body }: SaveArticleArgs): void => {
    triggerEvent(
      GTM_EVENTS.GTM_SAVE_KB_GEN_ARTICLE,
      ticketId,
      article.id,
      title,
      body
    );
    const isTitleChanged = title !== article.title && !isEmpty(title);
    const isBodyChanged = body !== article.body?.body && !isEmpty(body);
    editArticleMutation.mutate({
      articleId: article.id,
      title: isTitleChanged ? title : undefined,
      body: isBodyChanged ? body : undefined
    });
  };

  const handleDeleteArticle = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (flags.kbGenPublishProcess) {
      e.stopPropagation();
      return;
    }
    deleteArticleMutation.mutate({ articleId: article.id });
  };

  const getReviewers = (): string[] => {
    return article.reviewers
      .filter(
        (reviewer: ArticleReviewer) =>
          reviewer.status === ArticleReviewStatus.APPROVED
      )
      .map((reviewer: ArticleReviewer) => reviewer.email);
  };

  const handleLink = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    if (!Boolean(ticketId)) e.preventDefault();
  };

  const reviewerTooltipTitle = getReviewers().join("\n");
  const totalReviewers = getReviewers().length;

  return (
    <>
      <div
        className={classNames(
          styles.container,
          !flags.kbGenPublishProcess &&
            (isEven ? styles.containerEven : styles.containerOdd),
          flags.kbGenPublishProcess &&
            modalType === "view" &&
            modalVisibility &&
            styles.containerViewed,
          flags.kbGenPublishProcess && styles.containerV2
        )}
        onClick={() => handleViewArticle(true)}
        data-testid="article-list-item"
      >
        <LightTooltip
          title={article.title}
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                width: "200px"
              }
            }
          }}
        >
          <div>
            <div className={classNames(styles.tableCell, styles.articleItem)}>
              <button
                className={styles.articleButton}
                onClick={() => handleViewArticle(false)}
                data-testid="article-title-list-item"
              >
                {truncate(article.title, { length: 50 })}
              </button>
            </div>
          </div>
        </LightTooltip>
        {flags.kbGenPublishProcess && (
          <div className={classNames(styles.tableCell)}>
            <div
              className={classNames(styles.articleStatusButton, {
                [styles.draftArticle]: article.status === ArticleStatus.DRAFT,
                [styles.publishedArticle]:
                  article.status === ArticleStatus.PUBLISHED,
                [styles.inReviewArticle]:
                  article.status === ArticleStatus.IN_REVIEW,
                [styles.deprecatedArticle]:
                  article.status === ArticleStatus.DEPRECATED
              })}
            >
              {articleStatusAndIcon.status === ArticleStatus.PUBLISHED ||
              articleStatusAndIcon.status === ArticleStatus.IN_REVIEW ? (
                <span className={styles.articleStatusIcon}>
                  {articleStatusAndIcon.icon}
                </span>
              ) : (
                <>{articleStatusAndIcon.icon}</>
              )}
              <span>{articleStatusAndIcon.label}</span>
            </div>
          </div>
        )}
        <div
          className={classNames(styles.tableCell, styles.ticketItem, {
            [styles.disable]: !Boolean(ticketId || displayId)
          })}
        >
          <Link
            to={`/tickets/${ticketId}`}
            className={classNames({
              [styles.disableLink]: !Boolean(ticketId)
            })}
            onClick={handleLink}
          >
            {Boolean(displayId) ? `Ticket #${displayId}` : "-"}
          </Link>
        </div>
        <div className={classNames(styles.tableCell, styles.dateItem)}>
          {DateTime.fromSeconds(
            flags.kbGenPublishProcess ? article.updatedOn : article.createdOn
          ).toFormat("dd MMM yyyy")}
        </div>
        <LightTooltip
          title={article.authorEmail}
          placement="top-start"
          disableHoverListener={article.authorEmail.length <= 25}
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                width: "120px",
                overflow: "auto"
              }
            }
          }}
        >
          <div className={classNames(styles.tableCell, styles.authorItem)}>
            {truncate(article.authorEmail, { length: 25 })}
          </div>
        </LightTooltip>
        {flags.kbGenPublishProcess && (
          <LightTooltip
            title={reviewerTooltipTitle}
            placement="top-start"
            disableHoverListener={
              totalReviewers < 2 && getReviewers()[0]?.length <= 25
            }
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  width: "130px",
                  overflow: "auto"
                }
              }
            }}
          >
            <div className={classNames(styles.tableCell, styles.authorItem)}>
              {!Boolean(totalReviewers)
                ? "-"
                : truncate(getReviewers()[0], { length: 25 })}
              &nbsp;
              {totalReviewers > 1 && `+${totalReviewers - 1}`}
            </div>
          </LightTooltip>
        )}
        <div className={classNames(styles.tableCell, styles.actionsItem)}>
          {!flags.kbGenPublishProcess && (
            <>
              <LightTooltip title="Edit" placement="top" arrow>
                <button onClick={handleEditArticleOpen}>
                  <AeIcons.EditIcon className={styles.editIcon} />
                </button>
              </LightTooltip>
              <LightTooltip title="Delete" placement="top" arrow>
                <button onClick={handleDeleteArticle}>
                  <AeIcons.DeleteIcon className={styles.deleteIcon} />
                </button>
              </LightTooltip>
            </>
          )}
        </div>
      </div>
      {modalVisibility && modalType === "view" && (
        <ViewArticleModal
          ref={modalRef}
          articleId={article.id}
          handleClose={handleCloseModal}
          checkArticleContentChanged={checkArticleContentChanged}
          showConfirmationModal={showConfirmationModal}
          handleUnsavedChanges={handleUnsavedChanges}
        />
      )}
      {modalVisibility &&
        modalType === "edit" &&
        !flags.kbGenPublishProcess && (
          <EditArticleModal
            ref={modalRef}
            articleId={article.id}
            handleClose={handleCloseModal}
            handleSave={handleSaveChanges}
          />
        )}
    </>
  );
};

export { ArticlesTableItem };
