import { SalesforceSDK } from "@aptedge/lib-support-sdk";
import { ServiceNowSDK } from "@aptedge/lib-support-sdk/src/platforms/serviceNow";
import { Container } from "inversify";
import { AnswerEngine } from "../clients/answerEngine";
import { AnswerEngineProd } from "../clients/answerEngineProd";
import { Dashcam } from "../clients/dashcam/dashcam";
import { DashcamProd } from "../clients/dashcam/dashcamProd";
import { EdgeCreator } from "../clients/edgeCreator";
import { EdgeCreatorProd } from "../clients/edgeCreatorProd";
import { EdgeFetcher } from "../clients/edgeFetcher";
import { EdgeFetcherProd } from "../clients/edgeFetcherProd";
import { EdgeTicketAdder } from "../clients/edgeTicketAdder";
import { EdgeTicketAdderProd } from "../clients/edgeTicketAdderProd";
import { EdgeTicketRemover } from "../clients/edgeTicketRemover";
import { EdgeTicketRemoverProd } from "../clients/edgeTicketRemoverProd";
import {
  EdgeTicketsFetcher,
  EdgeTicketsFetcherProd
} from "../clients/edgeTicketsFetcher";
import { EmptyAgentDescriptionConfigFetcher } from "../clients/emptyAgentDescriptionConfigFetcher";
import { EmptyAgentDescriptionConfigFetcherProd } from "../clients/emptyAgentDescriptionConfigFetcherProd";
import { EventReporter } from "../clients/eventReporter";
import { EventReporterProd } from "../clients/eventReporterProd";
import {
  FeatureFlagsFetcher,
  FeatureFlagsFetcherProd
} from "../clients/featureFlagsFetcher";
import { DynamicFilters } from "../clients/fetchDynamicFilters";
import { DynamicFiltersProd } from "../clients/fetchDynamicFiltersProd";
import { SDKClient } from "../clients/sdkClient";
import { SDKClientDirect } from "../clients/sdkClientDirect";
import { SDKClientSalesforce } from "../clients/sdkClientSalesforce";
import { SDKClientServiceNow } from "../clients/sdkClientServiceNow";
import { SDKClientZendesk } from "../clients/sdkClientZendesk";
import { SearchSuggestions } from "../clients/searchSuggestions";
import { SearchSuggestionsProd } from "../clients/searchSuggestionsProd";
import { Config } from "../clients/self-service/config/config";
import { ConfigProd } from "../clients/self-service/config/configProd";
import { RateLimit } from "../clients/self-service/rate-limit/rateLimit";
import { RateLimitProd } from "../clients/self-service/rate-limit/rateLimitProd";
import { TemplateFetcher } from "../clients/templateFetcher";
import { TemplateFetcherProd } from "../clients/templateFetcherProd";
import { InsightAutoSearch } from "../clients/ticket-insight/AutoSearch/insightAutoSearch";
import { InsightAutoSearchProd } from "../clients/ticket-insight/AutoSearch/insightAutoSearchProd";
import { InsightCaseLog } from "../clients/ticket-insight/caseLog/insightCaseLog";
import { InsightCaseLogProd } from "../clients/ticket-insight/caseLog/insightCaseLogProd";
import { InsightNextSteps } from "../clients/ticket-insight/nextSteps/insightNextSteps";
import { InsightNextStepsProd } from "../clients/ticket-insight/nextSteps/insightTicketSummaryProd";
import { InsightSearchSuggestions } from "../clients/ticket-insight/searchSuggestion/insightSearchSuggestions";
import { InsightSearchSuggestionsProd } from "../clients/ticket-insight/searchSuggestion/insightSearchSuggestionsProd";
import { InsightTicketSummary } from "../clients/ticket-insight/ticketSummary/insightTicketSummary";
import { InsightTicketSummaryProd } from "../clients/ticket-insight/ticketSummary/insightTicketSummaryProd";
import { TicketDetails } from "../clients/ticket/ticketDetails/ticketDetails";
import { TicketDetailsProd } from "../clients/ticket/ticketDetails/ticketDetailsProd";
import { TicketArticles } from "../clients/ticketArticles";
import { TicketArticlesProd } from "../clients/ticketArticlesProd";
import { TicketCooker } from "../clients/ticketCooker";
import { TicketCookerProd } from "../clients/ticketCookerProd";
import { TicketKBArticle } from "../clients/ticketKBArticle";
import { TicketKBArticleProd } from "../clients/ticketKBArticleProd";
import { TicketSummary } from "../clients/ticketSummary";
import { TicketSummaryProd } from "../clients/ticketSummaryProd";
import { TicketUpdater } from "../clients/ticketUpdater";
import { TicketUpdaterProd } from "../clients/ticketUpdaterProd";
import { UIConfig } from "../clients/uiConfig";
import { UIConfigSalesforce } from "../clients/uiConfigSalesforce";
import { UIConfigZendesk } from "../clients/uiConfigZendesk";
import { UserIdentifier } from "../clients/userIdentifier";
import { UserIdentifierProd } from "../clients/userIdentifierProd";

enum SupportPlatform {
  ZENDESK = "zendesk",
  SALESFORCE = "salesforce",
  SERVICENOW = "servicenow",
  SELF_SERVICE = "self-service"
}
interface IEvent {
  origin: string;
  data: {
    type: string;
    payload: { authToken: string };
  };
}

export function receiveMessageFromSNow(event: IEvent): void {
  const params = new URLSearchParams(window.location.search);
  if (event.origin !== `https://${params.get("snowInstance")}.service-now.com`)
    return;
  const { type, payload } = event.data;
  if (type === "authToken") {
    localStorage.setItem("snow_token", payload.authToken);
  }
}

const createContainer = (platform: SupportPlatform): Container => {
  const prodContainer = new Container();
  prodContainer.bind(EdgeCreator).to(EdgeCreatorProd);
  prodContainer.bind(EdgeFetcher).to(EdgeFetcherProd);
  prodContainer.bind(EdgeTicketAdder).to(EdgeTicketAdderProd);
  prodContainer.bind(EdgeTicketRemover).to(EdgeTicketRemoverProd);
  prodContainer.bind(TicketCooker).to(TicketCookerProd);
  prodContainer.bind(TicketUpdater).to(TicketUpdaterProd);
  prodContainer.bind(TemplateFetcher).to(TemplateFetcherProd);
  prodContainer
    .bind(EmptyAgentDescriptionConfigFetcher)
    .to(EmptyAgentDescriptionConfigFetcherProd);
  prodContainer.bind(EdgeTicketsFetcher).to(EdgeTicketsFetcherProd);
  prodContainer.bind(UserIdentifier).to(UserIdentifierProd);
  prodContainer.bind(FeatureFlagsFetcher).to(FeatureFlagsFetcherProd);
  prodContainer.bind(SearchSuggestions).to(SearchSuggestionsProd);
  prodContainer.bind(AnswerEngine).to(AnswerEngineProd);
  prodContainer.bind(DynamicFilters).to(DynamicFiltersProd);
  prodContainer.bind(TicketSummary).to(TicketSummaryProd);
  prodContainer.bind(TicketArticles).to(TicketArticlesProd);
  prodContainer.bind(TicketKBArticle).to(TicketKBArticleProd);
  prodContainer.bind(EventReporter).to(EventReporterProd);
  prodContainer.bind(RateLimit).to(RateLimitProd);
  prodContainer.bind(Config).to(ConfigProd);
  prodContainer.bind(InsightSearchSuggestions).to(InsightSearchSuggestionsProd);
  prodContainer.bind(InsightAutoSearch).to(InsightAutoSearchProd);
  prodContainer.bind(InsightTicketSummary).to(InsightTicketSummaryProd);
  prodContainer.bind(InsightCaseLog).to(InsightCaseLogProd);
  prodContainer.bind(InsightNextSteps).to(InsightNextStepsProd);
  prodContainer.bind(TicketDetails).to(TicketDetailsProd);
  prodContainer.bind(Dashcam).to(DashcamProd);

  // Bind to the correct SDK client based on platform, unless we're running locally.
  if (process.env.NODE_ENV === "development") {
    prodContainer.bind(SDKClient).toConstantValue(new SDKClientDirect());
    prodContainer.bind(UIConfig).to(UIConfigZendesk);
  } else {
    switch (platform) {
      case SupportPlatform.SALESFORCE:
        const sdk = new SalesforceSDK();
        prodContainer
          .bind(SDKClient)
          .toConstantValue(new SDKClientSalesforce(sdk));
        prodContainer.bind(UIConfig).to(UIConfigSalesforce);
        break;
      case SupportPlatform.ZENDESK:
        // Must be bound as a singleton, otherwise new instances are created inside each
        // client that uses it.
        prodContainer.bind(SDKClient).to(SDKClientZendesk).inSingletonScope();
        prodContainer.bind(UIConfig).to(UIConfigZendesk);
        break;
      case SupportPlatform.SERVICENOW:
        // Must be bound as a singleton, otherwise new instances are created inside each
        // client that uses it.
        const sdkSNow = new ServiceNowSDK();
        window.addEventListener("message", receiveMessageFromSNow, false);

        prodContainer
          .bind(SDKClient)
          .toConstantValue(new SDKClientServiceNow(sdkSNow));
        prodContainer.bind(UIConfig).to(UIConfigZendesk);
        break;
      case SupportPlatform.SELF_SERVICE:
        // Must be bound as a singleton, otherwise new instances are created inside each
        // client that uses it.
        prodContainer.bind(SDKClient).toConstantValue(new SDKClientDirect());
        prodContainer.bind(UIConfig).to(UIConfigZendesk);
        break;
    }
  }

  return prodContainer;
};

export { createContainer, SupportPlatform };
